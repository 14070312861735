import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

export type FormMultiSelectItem = {
  text: string
  value: string | number
}

export interface FormMultiSelectItemProps {
  name: string
  control: Control<any> // eslint-disable-line
  label: string,
  size?: 'small' | 'medium',
  items: FormMultiSelectItem[],
}

export const FormMultiSelectField = ({ name, control, label, items, size='medium' }: FormMultiSelectItemProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControl fullWidth size={size}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          error={!!error}
          value={value}
          label={label}
          variant='outlined'
          fullWidth
          size={size}
          displayEmpty={true}
          onChange={onChange}
          renderValue={(selectedItems) =>
            selectedItems?.map((v: number) => items.find(i => i.value === v)?.text).join(', ')
          }
        >
          {
            items.map((item, index) =>
              <MenuItem key={index} value={item.value}>
                <Checkbox checked={value.indexOf(item.value) >= 0} />
                <ListItemText primary={item.text} />
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    )}
  />
)
