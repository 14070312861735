import {Chip, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import React from 'react'

import {Job} from "features/jobs/types";

import {useJobsService} from "../../../../hooks";
import {FeatureItem} from "../featureItem";


export type UpworkFeaturesProps = {
  job: Job | undefined
}

export const UpworkFeatures = ({job,}: UpworkFeaturesProps) => {
  const {openHistory} = useJobsService()
  return <>
    <Grid item xs={12} container paddingBottom={2}>
      {
        job?.skills.split(',').map((skill, index) =>
          <Chip sx={{marginRight: '3px', marginBottom: '3px'}} key={index} size={'small'} label={skill} variant="outlined" />
        )
      }
    </Grid>
    <Grid item xs={12} container>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Price'} value={`${job?.price}`}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Country'} value={`${job?.country}`}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid item container direction="row" spacing={0}>
          <Typography
            sx={{fontSize: '14px', fontWeight: 'bold', color: "#3b3b3b"}}
            variant={'caption'}
            gutterBottom
            component="div"
          >
            Payment:&nbsp;
          </Typography>
          <Typography sx={{fontSize: '14px',color: "#3b3b3b"}} gutterBottom component="div">
            {job?.payment_verified ?
              <Chip sx={{marginRight: '3px', marginBottom: '3px'}} color={'success'} size={'small'} label={'Verified'} variant="outlined"/> :
              <Chip sx={{marginRight: '3px', marginBottom: '3px'}} size={'small'} label={'Unverified'} variant="outlined"/>
            }
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} container direction={'row'} alignItems="center">
        <Grid>
          <FeatureItem label={'Review'} value={`${job?.review || 0}`}/>
        </Grid>
        <Grid>
          <Rating
            name="review-rating"
            value={job?.review}
            precision={0.5}
            max={5}
            readOnly
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Total Post'} value={`${job?.total_posted_job_count} posts`}/>
      </Grid>
      {
        job?.total_spent_txt && <Grid item xs={12} sm={4}>
          <FeatureItem label={'Total Spend'} value={`${job?.total_spent_txt || ""}`}/>
        </Grid>
      }

      {
        job?.hire_rate && <Grid item xs={12} sm={4}>
          <FeatureItem label={'Hire Rate'} value={`${job?.hire_rate}`}/>
        </Grid>
      }
      {
        job?.history_summary?.completed && <Grid item xs={12} sm={4}>
          <FeatureItem label={'Completed'} value={`${job?.history_summary?.completed} jobs`}/>
        </Grid>
      }
      {
        job?.avg_hourly_rate !== 0 &&
          <Grid item xs={12} sm={4}>
            <FeatureItem label={'avgRate'} value={`$${job?.avg_hourly_rate}/h`}/>
          </Grid>
      }
      {
        job?.history_summary?.max_rate && <Grid item xs={12} sm={4}>
          <FeatureItem label={'MaxRate'} value={`$${job?.history_summary?.max_rate}/hr`}/>
        </Grid>
      }
      {
        job?.history_summary?.min_rate && <Grid item xs={12} sm={4}>
          <FeatureItem label={'MinRate'} value={`$${job?.history_summary?.min_rate}/hr`}/>
        </Grid>
      }
      {
        (job?.history_summary?.progress !== undefined) &&
        <Grid item xs={12} sm={4}>
          <FeatureItem label={'On Progress'} value={`${job?.history_summary?.progress} jobs`}/>
        </Grid>
      }
      <Grid item xs={12} sm={4}>
        <FeatureItem
          label={'Bad FeedBack To Client'}
          value={job?.history_summary?.bad_fb_to_client ?
            `${job?.history_summary?.bad_fb_to_client} jobs in ${job?.history_summary?.completed} jobs` :
            `No bad`
          }/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FeatureItem
          label={'Bad FeedBack To Dev'}
          value={job?.history_summary?.bad_fb_to_dev ?
            `${job?.history_summary?.bad_fb_to_dev} jobs in ${job?.history_summary?.completed} jobs` :
            `No bad`
          }/>
      </Grid>
      {
        job?.history_summary?.best_billed && <Grid item xs={12} sm={4}>
          <FeatureItem
            label={'BestBilled'}
            value={`$${job?.history_summary?.best_billed?.billed}`}/>
        </Grid>
      }
      {
        job?.history_summary?.best_billed?.job_type === 'Fixed-price' && <Grid item xs={12} sm={8}>
          <FeatureItem
            label={'BestBilledJob'}
            value={`${job?.history_summary?.best_billed?.title}  ($${job?.history_summary?.best_billed?.price} ${job?.history_summary?.best_billed?.job_type})`}/>
        </Grid>
      }
      {
        job?.history_summary?.best_billed?.job_type === 'Hourly' && <Grid item xs={12} sm={8}>
          <FeatureItem
            label={'BestBilledJob'}
            value={`${job?.history_summary?.best_billed?.title} ($${job?.history_summary?.best_billed?.price}/hr ${job?.history_summary?.best_billed?.hours} hours ${job?.history_summary?.best_billed?.job_type})`}/>
        </Grid>
      }
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Since'} value={`${job?.member_since.replace("Member since", "")}`}/>
      </Grid>
      {
        job?.location &&
        <Grid item xs={12} sm={8}>
          <FeatureItem label={'Preferred Location'} value={`${job?.location}`}/>
        </Grid>
      }
    </Grid>
    <div
      onClick={() => openHistory(job?.id || 0, job)}
      style={{paddingLeft: '5px', fontSize: '14px', color: 'blue', cursor: 'pointer'}}>
      Show Working History
    </div>
  </>
}

