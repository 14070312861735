import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react'

import {EditAboutDialog} from "./editAboutDialog";


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export type AccountFieldProps = {
  label: string
  value?: string | number
  disableEdit?: boolean
}

export const AccountField = ({label, value, disableEdit=false}: AccountFieldProps) => {
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    console.log()
  }, [])

  return (
    <>
      <Paper
        sx={{
          px: 2,
          py: 1,
          width: '90%',
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        }}
      >
        <Grid item px={2} xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography sx={{fontWeight: "bold"}} gutterBottom variant="subtitle1" component="div">
                {label}
              </Typography>
              <Typography sx={{color: '#5e5e5e'}} variant="body2" gutterBottom>
                {value}
              </Typography>
            </Grid>
          </Grid>
          {
            !disableEdit &&
              <Grid item>
                <Button onClick={() => {setOpen(true)}} size="small" color={"secondary"}>Edit</Button>
              </Grid>
          }
        </Grid>
      </Paper>
      <EditAboutDialog openDialog={open} label={label} closeDialog={() => {setOpen(false)}}/>
    </>
  )
}
