import { useCallback, useMemo } from 'react'

import { authActions, AuthState, selectAuth } from 'features/auth/store'
import {
  ActivateInput,
  FCMInput,
  LoginInput,
  ResetPasswordConfirmInput,
  ResetPasswordInput,
  SignUpInput, UpdatePasswordInput, UpdateUserNameInput
} from 'features/auth/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type AuthServiceOperators = {
  auth: AuthState
  login: (data: LoginInput) => void
  activateAccount: (data: ActivateInput) => void
  updateAccount: (data: UpdateUserNameInput) => void
  updatePassword: (data: UpdatePasswordInput) => void
  restPassword: (data: ResetPasswordInput) => void
  restPasswordConfirm: (data: ResetPasswordConfirmInput) => void
  signup: (data: SignUpInput) => void
  logout: () => void
  getMe: () => void
  deleteMe: () => void
  pushFCMToken: (data: FCMInput) => void
}


export const useAuthService = (): Readonly<AuthServiceOperators> => {
  const dispatch = useAppDispatch()
  const auth = useAppSelector(selectAuth)

  return {
    auth,
    login: useCallback((data: LoginInput) => dispatch(authActions.login(data)), [dispatch]),
    activateAccount: useCallback((data: ActivateInput) => dispatch(authActions.activate(data)), [dispatch]),
    signup: useCallback((data: SignUpInput) => dispatch(authActions.signup(data)), [dispatch]),
    updateAccount: useCallback((data: UpdateUserNameInput) => dispatch(authActions.updateName(data)), [dispatch]),
    updatePassword: useCallback((data: UpdatePasswordInput) => dispatch(authActions.updatePassword(data)), [dispatch]),
    restPassword: useCallback((data: ResetPasswordInput) => dispatch(authActions.resetPassword(data)), [dispatch]),
    restPasswordConfirm: useCallback((data: ResetPasswordConfirmInput) => dispatch(authActions.resetPasswordConfirm(data)), [dispatch]),
    logout: useCallback(() => dispatch(authActions.logout()), [dispatch]),
    getMe: useCallback(() => dispatch(authActions.getMe()), [dispatch]),
    deleteMe: useCallback(() => dispatch(authActions.deleteMe()), [dispatch]),
    pushFCMToken: useCallback((data: FCMInput) => dispatch(authActions.pushFCMToken(data)), [dispatch]),
  }
}

export default useAuthService
