import { useCallback } from 'react'

import {gptActions, GPTState, selectGPT} from 'features/chatGPT/store'
import {useAppDispatch, useAppSelector} from 'store/hooks'


export type GPTServiceOperators = {
  gpt: GPTState,
  setGPTState: (state: boolean) => void
}

export const useGptService = (): Readonly<GPTServiceOperators> => {
  const dispatch = useAppDispatch()

  return {
    gpt: useAppSelector(selectGPT),
    setGPTState: useCallback((state: boolean) =>
      dispatch(gptActions.setGptState(state)), [dispatch]),
  }
}

export default useGptService
