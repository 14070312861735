import {
  Divider,
  Drawer,

} from '@mui/material'
import React  from 'react'

import { drawerWidth } from 'components/Layout'
import { useAuthService } from 'features/auth'

import {Navigation} from "./navigation";
import {SettingNav} from "./settings";

export type AppDrawerProps = {
  open: boolean
  handleDrawerClose: (state: boolean) => void
}

export const AppDrawer = ({ open, handleDrawerClose }: AppDrawerProps) => {
  const { auth: { me } } = useAuthService()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          marginTop: '64px',
          padding: '10px 20px',
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#860433'
        },
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <Navigation open={open} handleDrawerClose={handleDrawerClose}/>
      <Divider />
      <SettingNav open={open} handleDrawerClose={handleDrawerClose}/>
    </Drawer>
  )
}