import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import React, {useCallback, useEffect, useState} from 'react'


import {EditAboutForm} from "features/accounts/components/edit/aboutTab/editAboutForm";
import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {UpdateAccountInput} from "features/accounts/types";

export type EditAboutDialogProps = {
  openDialog: boolean
  label?: string
  closeDialog: (data: boolean) => void
}

export const EditAboutDialog = ({openDialog, label, closeDialog} : EditAboutDialogProps) => {
  const {updateAccount} = useAccountsService()

  const onCloseDialog = useCallback(() => {
    closeDialog(false)
  }, [closeDialog])

  const onSubmit = useCallback((data: UpdateAccountInput) => {
    updateAccount(data)
    closeDialog(false)
  }, [closeDialog, updateAccount])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={openDialog}
      onClose={onCloseDialog}>
      <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
        {`Update ${label}`}
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <EditAboutForm label={label} onSubmit={onSubmit}/>
      </DialogContent>
    </Dialog>
  )
}
