import { api } from 'features/api'

import {
  CreateAccountInput,
  Account,
  UpdateAccountInput,
  Country,
  FilterInput,
  Filter,
  PromptInput,
  Prompt, FeedResponse
} from "../types";

export const createAccount = (data: CreateAccountInput): Promise<Account> =>
  api.post('/api/accounts/', data);

export const updateAccount = (data: UpdateAccountInput): Promise<Account> =>
  api.patch(`/api/accounts/${data.id}/`, data);

export const deleteAccount = (id: number): Promise<Account> =>
  api.delete(`/api/accounts/${id}/`);


export const getAccounts = (): Promise<Account[]> =>
  api.get('/api/accounts/');

export const getAccount = (id: number): Promise<Account[]> =>
  api.get(`/api/accounts/${id}`);

export const getFilterJobCount = (id: number): Promise<Account[]> =>
  api.get(`/api/filters/${id}/analytics/`);

export const getFeeds = (id: number, lastCreated: string | null): Promise<FeedResponse> =>
  api.get(`/api/accounts/${id}/feeds/?lastCreated=${lastCreated}`);

export const getReport = (id: number): Promise<FeedResponse> =>
  api.get(`/api/accounts/${id}/report/`);

export const getCountries = (): Promise<Country[]> =>
  api.get('/api/countries/');


export const createFilter = (accountId: number, data: FilterInput): Promise<Filter> =>
  api.post(`/api/accounts/${accountId}/filter/`, data);

export const updateFilter = (accountId: number, data: FilterInput): Promise<Filter> =>
  api.patch(`/api/filters/${data.id}/`, data);

export const deleteFilter = (id: number): Promise<Account> =>
  api.delete(`/api/filters/${id}/`);

export const createPrompt = (data: PromptInput): Promise<Prompt> =>
  api.post(`/api/prompts/`, data);

export const updatePrompt = (data: PromptInput): Promise<Prompt> =>
  api.patch(`/api/prompts/${data.id}/`, data);
