import { useCallback } from 'react'

import {jobsActions} from 'features/jobs/store'
import {useAppDispatch, useAppSelector} from 'store/hooks'

import {JobsState, selectJobs} from "../store/jobs.slice";
import {FeedDeleteParam, FeedRequestParam, FeedUpdateParam, Job, ProposalInput} from "../types";



export type JobServiceOperators = {
  job: JobsState
  openHistory: (jobId: number, job: Job | undefined) => void
  getWorkingHistory: (jobId: number) => void
  moveFeedsToTrash: (accountId: number, type: string) => void
  emptyTrash: (accountId: number, type: string) => void
  getNewFeeds: (param: FeedRequestParam) => void
  getFeeds: (param: FeedRequestParam) => void
  updateFeed: (param: FeedUpdateParam) => void
  deleteFeed: (param: FeedDeleteParam) => void
  createProposal: (feedId: number, data: ProposalInput) => void
  editProposal: (feedId: number, id: number, data: ProposalInput) => void
  generateProposal: (feedId: number) => void
  formatFeed: () => void
  changePage: (page: number) => void
  changePageSize: (count: number) => void
  updateProposalState: (feedId: number) => void
  updateSummaryState: (feedId: number) => void
  updateGptProposal: (feedId: number, data: string) => void
  updateGptSummary: (feedId: number, data: string) => void
  updateGptQuestion: (feedId: number, index: number, data: string) => void
  updateGptFailed: (feedId: number, errorMsg: string) => void
}

export const useJobsService = (): Readonly<JobServiceOperators> => {
  const dispatch = useAppDispatch()

  return {
    job: useAppSelector(selectJobs),
    openHistory: useCallback((jobId: number, job: Job | undefined) =>
      dispatch(jobsActions.openHistory({jobId, job})), [dispatch]),
    getWorkingHistory: useCallback((jobId: number) =>
      dispatch(jobsActions.getWorkingHistory(jobId)), [dispatch]),
    moveFeedsToTrash: useCallback((accountId: number, type: string) =>
      dispatch(jobsActions.moveFeedsToTrash(accountId, type)), [dispatch]),
    emptyTrash: useCallback((accountId: number, type: string) =>
      dispatch(jobsActions.emptyTrash(accountId, type)), [dispatch]),
    changePage: useCallback((page: number) =>
      dispatch(jobsActions.changePage(page)), [dispatch]),
    formatFeed: useCallback(() =>
      dispatch(jobsActions.formatFeed()), [dispatch]),
    changePageSize: useCallback((count: number) =>
      dispatch(jobsActions.changePageSize(count)), [dispatch]),
    getNewFeeds: useCallback((param) =>
      dispatch(jobsActions.getNewFeeds(param)), [dispatch]),
    getFeeds: useCallback((param) =>
      dispatch(jobsActions.getFeeds(param)), [dispatch]),
    updateFeed: useCallback((param) =>
      dispatch(jobsActions.updateFeed(param)), [dispatch]),
    deleteFeed: useCallback((param) =>
      dispatch(jobsActions.deleteFeed(param)), [dispatch]),
    createProposal: useCallback((feedId, data) =>
      dispatch(jobsActions.createProposal(feedId, data)), [dispatch]),
    updateProposalState: useCallback((feedId) =>
      dispatch(jobsActions.updateProposalStart(feedId)), [dispatch]),
    updateSummaryState: useCallback((feedId) =>
      dispatch(jobsActions.updateSummaryStart(feedId)), [dispatch]),
    editProposal: useCallback((feedId, id, data) =>
      dispatch(jobsActions.updateProposal(feedId, id, data)), [dispatch]),
    generateProposal: useCallback((feedId) =>
      dispatch(jobsActions.generateProposal(feedId)), [dispatch]),
    updateGptProposal: useCallback((feedId, data) =>
      dispatch(jobsActions.updateGptProposal(feedId, data)), [dispatch]),
    updateGptSummary: useCallback((feedId, data) =>
      dispatch(jobsActions.updateGptSummary(feedId, data)), [dispatch]),
    updateGptQuestion: useCallback((feedId, index,  data) =>
      dispatch(jobsActions.updateGptQuestion(feedId, index, data)), [dispatch]),
    updateGptFailed: useCallback((feedId, errorMsg) =>
      dispatch(jobsActions.updateGptFailed(feedId, errorMsg)), [dispatch]),
  }
}

export default useJobsService
