// DUCKS pattern
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

import {ReportData, ReportResponse} from "../../jobs/types";
import {
  Account,
  AccountListItem,
  Country,
  CreateAccountInput, FilteredJobCount,
  FilterInput,
  PromptInput,
  UpdateAccountInput
} from "../types";

export interface AccountsState {
  openModal: boolean
  editModal: boolean
  changeNav: boolean
  setPrompt: boolean
  loading: boolean
  error: unknown | undefined
  accounts: AccountListItem[]
  filteredJobs: FilteredJobCount[]
  countries: Country[]
  reportData: ReportResponse
  account: Account | undefined
  promptPreview: string | null | undefined
}

const initialState: AccountsState = {
  openModal: false,
  editModal: false,
  setPrompt: false,
  changeNav: false,
  loading: false,
  error: undefined,
  accounts: [],
  filteredJobs: [],
  countries: [],
  reportData: {},
  account: undefined,
  promptPreview: null,
}

// slice
export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    // addAccount
    addAccount(state, action: PayloadAction<boolean>) {
      state.openModal = action.payload
    },
    addPromptAction(state, action: PayloadAction<boolean>) {
      state.setPrompt = action.payload
    },
    editAccount(state, action: PayloadAction<boolean>) {
      state.editModal = action.payload
    },
    clickNav(state) {
      state.changeNav = !state.changeNav
    },
    createAccountStart(state) {
      state.loading = true
      state.error = undefined
    },
    createAccountFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
    getAccountsStart(state) {
      state.loading = true
      state.error = undefined
    },
    getAccountsSuccess(state, action: PayloadAction<AccountListItem[]>) {
      state.accounts = action.payload
      state.loading = false
      state.error = undefined
    },
    getFilteredJobSuccess(state, action: PayloadAction<FilteredJobCount[]>) {
      state.filteredJobs = action.payload
      state.loading = false
      state.error = undefined
    },
    cleanFilteredJobSuccess(state) {
      state.filteredJobs = []
      state.loading = false
      state.error = undefined
    },
    getAccountReportStart(state) {
      state.reportData = {}
    },
    getAccountReportSuccess(state, action: PayloadAction<ReportResponse>) {
      state.reportData = action.payload
    },
    emptyFeedCount(state, action: PayloadAction<{accountId: number}>) {
      state.accounts = state.accounts.map(account => {
        if (account.id === action.payload.accountId) {
          account.feed_count = 0
        }
        return account
      })
    },
    updateFeedCount(state, action: PayloadAction<{accountId: number, increase: boolean}>) {
      state.accounts = state.accounts.map(account => {
        if (account.id === action.payload.accountId) {
          account.feed_count += action.payload.increase ? 1 : -1
        }
        return account
      })
    },
    getCountriesSuccess(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload
      state.loading = false
      state.error = undefined
    },
    getAccountsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
    getAccountStart(state) {
      state.loading = true
      state.error = undefined
    },
    getAccountSuccess(state, action: PayloadAction<Account>) {
      state.account = action.payload
      state.loading = false
      state.error = undefined
    },
    getAccountFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
    deleteAccountSuccess(state) {
      state.loading = false
      state.account = undefined
    },
  },
})

// Actions
export const accountsActions = {
  addAccount: accountsSlice.actions.addAccount,
  addPromptAction: accountsSlice.actions.addPromptAction,
  editAccount: accountsSlice.actions.editAccount,
  clickNav: accountsSlice.actions.clickNav,
  emptyFeedCount: accountsSlice.actions.emptyFeedCount,
  createAccountStart: accountsSlice.actions.createAccountStart,
  createAccountFailure: accountsSlice.actions.createAccountFailure,
  createAccount: createAction(`${accountsSlice.name}/createAccount`, (data: CreateAccountInput) => ({
    payload: data
  })),
  updateAccount: createAction(`${accountsSlice.name}/updateAccount`, (data: UpdateAccountInput) => ({
    payload: data
  })),
  deleteAccount: createAction(`${accountsSlice.name}/deleteAccount`, (id?: number) => ({
    payload: id
  })),
  createFilter: createAction(`${accountsSlice.name}/createFilter`, (accountId: number, data: FilterInput) => ({
    payload: {accountId, data}
  })),
  updateFilter: createAction(`${accountsSlice.name}/updateFilter`, (accountId: number, data: FilterInput) => ({
    payload: {accountId, data}
  })),
  deleteFilter: createAction(`${accountsSlice.name}/deleteFilter`, (accountId: number, id?: number) => ({
    payload: {accountId, id}
  })),
  createPrompt: createAction(`${accountsSlice.name}/createPrompt`, (accountId: number, data: PromptInput) => ({
    payload: {accountId, data}
  })),
  updatePrompt: createAction(`${accountsSlice.name}/updatePrompt`, (accountId: number, data: PromptInput) => ({
    payload: {accountId, data}
  })),
  getAccountsStart: accountsSlice.actions.getAccountsStart,
  getAccountsSuccess: accountsSlice.actions.getAccountsSuccess,
  getFilteredJobSuccess: accountsSlice.actions.getFilteredJobSuccess,
  cleanFilteredJobSuccess: accountsSlice.actions.cleanFilteredJobSuccess,
  getAccountReportStart: accountsSlice.actions.getAccountReportStart,
  getAccountReportSuccess: accountsSlice.actions.getAccountReportSuccess,
  updateFeedCount: accountsSlice.actions.updateFeedCount,
  getCountriesSuccess: accountsSlice.actions.getCountriesSuccess,
  getAccountsFailure: accountsSlice.actions.getAccountsFailure,
  getCountries: createAction(`${accountsSlice.name}/getCountries`),
  getAccounts: createAction(`${accountsSlice.name}/getAccounts`),
  getAccountStart: accountsSlice.actions.getAccountStart,
  getAccountSuccess: accountsSlice.actions.getAccountSuccess,
  getAccountFailure: accountsSlice.actions.getAccountFailure,
  getAccount: createAction(`${accountsSlice.name}/getAccount`, (id?: number) => ({
    payload: id
  })),
  getFilterJobCount: createAction(`${accountsSlice.name}/getFilterJobCount`, (id?: number) => ({
    payload: id
  })),
  getFeeds: createAction(`${accountsSlice.name}/getFeeds`, (id: number, lastCreated: string | null, type: string) => ({
    payload: {id, lastCreated, type}
  })),
  getAccountReport: createAction(`${accountsSlice.name}/getReport`, (id: number) => ({
    payload: {id}
  })),
  deleteAccountSuccess: accountsSlice.actions.deleteAccountSuccess,

}

// Selectors
export const selectAccounts = (state: RootState) => state.account

// Reducer
export default accountsSlice.reducer
