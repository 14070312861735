import { SagaIterator } from '@redux-saga/core'
import { push } from 'connected-react-router';
import { call, put, takeEvery } from 'redux-saga/effects'

import {
  activate, deleteMe,
  getMe,
  login,
  pushFCM,
  resetPasswordConfirmApi,
  resetPasswordSendEmail,
  signup, updateName, updatePassword
} from 'features/auth/api'
import { authActions } from 'features/auth/store/auth.slice'
import {
  ActivateInput,
  FCMInput,
  LoginInput,
  ResetPasswordConfirmInput,
  ResetPasswordInput,
  SignUpInput,
  Token, UpdatePasswordInput, UpdateUserNameInput, User
} from 'features/auth/types'
import { storage } from 'libs/core/storage'

import {commonActions} from "../../common/store";
import {ApiError} from "../../common/types";
// Worker Sagas
function* onLogin({
  payload,
}: {
  type: typeof authActions.login
  payload: LoginInput
}): SagaIterator {
  yield put(authActions.loginStart())
  try {
    const token: Token = yield call(login, payload)
    yield put(authActions.loginSuccess(token))
    storage.setAccessToken(token.access)
    storage.setRefreshToken(token.refresh)
    yield put(authActions.getMe())
  } catch (e: unknown) {
    yield put(authActions.loginFailure(e))
    storage.clear()
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

function* onActivate({
  payload,
}: {
  type: typeof authActions.activate
  payload: ActivateInput
}): SagaIterator {
  try {
    yield call(activate, payload)
    yield put(authActions.updateActivateStatus(1))
  } catch (e: unknown) {
    yield put(authActions.updateActivateStatus(2))
  }}


// Worker Sagas
function* onSignUp({
  payload,
}: {
  type: typeof authActions.signup
  payload: SignUpInput
}): SagaIterator {
  yield put(authActions.loginStart())
  try {
    yield call(signup, payload)
    yield put(push('/confirm-email'));
    yield put(commonActions.setError(undefined))
    yield put(commonActions.showMessage(false))
  } catch (e: unknown) {
    yield put(authActions.loginFailure(e))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

function* onLogout(): SagaIterator {
  storage.clear()
  yield put(authActions.logoutSuccess())
}

function* onGetMe(): SagaIterator {
  yield put(authActions.getMeStart())
  try {
    const user: User = yield call(getMe)
    yield put(authActions.getMeSuccess(user))
  } catch(e) {
    yield put(authActions.getMeFailure(e))
    yield put(authActions.loginFailure(e))
    storage.clear()
  }
}

function* onDeleteMe(): SagaIterator {
  yield call(deleteMe)
  storage.clear()
}

function* onUpdateName({
  payload,
}: {
  type: typeof authActions.signup
  payload: UpdateUserNameInput
}): SagaIterator {
  const me = yield call(updateName, payload)
  yield put(authActions.getMeSuccess(me))
}

function* onUpdatePassword({
  payload,
}: {
  type: typeof authActions.signup
  payload: UpdatePasswordInput
}): SagaIterator {
  try {
    yield call(updatePassword, payload)
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}



// Worker Sagas
function* onPushFCMToken({
  payload,
}: {
  type: typeof authActions.pushFCMToken
  payload: FCMInput
}): SagaIterator {
  yield put(authActions.loginStart())
  try {
    yield call(pushFCM, payload)
    yield put(authActions.setFCMDeviceId(payload.fcm_token))
  } catch (e: unknown) {
    console.log(e)
  }
}

function* onResetPassword({
  payload,
}: {
  type: typeof authActions.resetPassword
  payload: ResetPasswordInput
}): SagaIterator {
  try {
    yield call(resetPasswordSendEmail, payload)
  } catch (e: unknown) {
    console.log(e)
  }
}

function* resetPasswordConfirm({
  payload,
}: {
  type: typeof authActions.resetPasswordConfirm
  payload: ResetPasswordConfirmInput
}): SagaIterator {
  try {
    yield call(resetPasswordConfirmApi, payload)
  } catch (e: unknown) {
    console.log(e)
  }}

// Watcher Saga
export function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.resetPassword.type, onResetPassword)
  yield takeEvery(authActions.resetPasswordConfirm.type, resetPasswordConfirm)
  yield takeEvery(authActions.login.type, onLogin)
  yield takeEvery(authActions.activate.type, onActivate)
  yield takeEvery(authActions.signup.type, onSignUp)
  yield takeEvery(authActions.logout.type, onLogout)
  yield takeEvery(authActions.getMe.type, onGetMe)
  yield takeEvery(authActions.updateName.type, onUpdateName)
  yield takeEvery(authActions.updatePassword.type, onUpdatePassword)
  yield takeEvery(authActions.deleteMe.type, onDeleteMe)
  yield takeEvery(authActions.pushFCMToken.type, onPushFCMToken)
}

export default authWatcherSaga
