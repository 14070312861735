import {Grid} from "@mui/material";
import React from 'react'

import {AccountField} from "features/accounts/components/edit/aboutTab/accountField";
import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {Spacer} from "libs/ui/components/Spacer";

import {IncreaseDurationModes, IncreasePercentageModes, PriceModes} from "../../../types";


export const PriceTabContainer = () => {
  const {accounts: {account}} = useAccountsService()

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid container item>
          <AccountField label={'Profile Hourly Rate (USD/hr)'} value={account?.hourly_rate}/>
        </Grid>
        <Grid container item>
          <AccountField label={'Price Mode'} value={PriceModes[account?.price_mode || 'as_it']}/>
        </Grid>
        <Grid container item>
          <AccountField label={'Increase Duration'} value={IncreaseDurationModes[account?.increase_duration || 0]}/>
        </Grid>
        {
          account?.increase_duration !== 0 && <Grid container item>
            <AccountField label={'Increase Percentage'} value={IncreasePercentageModes[account?.increase_percentage || 0]}/>
          </Grid>
        }

      </Grid>
      <Spacer height={10} />
    </>
  )
}
