import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

export type FormSelectItem = {
  text: string
  value: string | number
}

export interface FormSelectProps {
  name: string
  control: Control<any> // eslint-disable-line
  label: string,
  items: FormSelectItem[],
  addNull?: boolean
}

export const FormSelectField = ({ name, control, label, items, addNull=false }: FormSelectProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          error={!!error}
          value={value}
          label={label}
          variant='outlined'
          fullWidth
          onChange={onChange}
        >
          {
            addNull && <MenuItem value={''}>{'Un-select'}</MenuItem>
          }
          {
            items.map((item, index) =>
              <MenuItem key={index} value={item.value}>{item.text}</MenuItem>)
          }
        </Select>
      </FormControl>
    )}
  />
)
