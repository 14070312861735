import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { dashboardActions } from 'features/dashboard/store/dashboard.slice'

import {commonActions} from "../../common/store";
import {ApiError} from "../../common/types";
import {getFeedChart} from "../api";
import {FeedChartData, FeedChartResponse} from "../types";



function* onGetFeedChart(): SagaIterator {
  try {
    const data: FeedChartResponse = yield call(getFeedChart)
    yield put(dashboardActions.getFeedChartSuccess(data))
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Watcher Saga
export function* dashboardWatcherSaga(): SagaIterator {
  yield takeEvery(dashboardActions.getFeedChart.type, onGetFeedChart)
}

export default dashboardWatcherSaga
