import {Button, Grid} from "@mui/material";
import React, {useCallback, useEffect, useState} from 'react'

import {AddFeedDialog} from "features/accounts/components/edit/jobFeedTab/addFeed/addFeedDialog";
import {FilterPanel} from "features/accounts/components/edit/jobFeedTab/filterPanel";
import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {Filter} from "features/accounts/types";


export const JobFeedTabContainer = () => {
  const {accounts: {account}, cleanFilteredJobSuccess} = useAccountsService()
  const [open, setOpen] = useState<boolean>(false)
  const [filter, setFilter] = useState<Filter | undefined>(undefined)
  const onSubmit = () => {
    setFilter(undefined)
    setOpen(true)
  }
  const openFilterDialog = useCallback((data: Filter) => {
    setOpen(true)
    setFilter(data)
  }, [])
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid container item justifyContent="flex-end">
        <Button onClick={onSubmit} variant={'contained'} size={'small'}>{'Add new feed'}</Button>
      </Grid>
      {
        account?.filters.map((row, index) =>
          <Grid container item key={index}>
            <FilterPanel filter={row} openFilterDialog={openFilterDialog}/>
          </Grid>)
      }
      <AddFeedDialog openDialog={open} filter={filter} closeDialog={() => {setOpen(false); cleanFilteredJobSuccess()}}/>
    </Grid>
  )
}
