import styled from "@emotion/styled";
import {Button, Grid, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import TitleImg from "../../../assets/images/logo.png";

const Container = styled.div(() => ({
  width: '100vw',
  height: '100vh',
  background: '#155bd5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const InfoCard = styled.div(() => ({
  width: '706px',
  height: 200,
  minWidth: '706px',
  background: '#ffffff',
  borderRadius: 20,
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 900px)': {
    width: '90vw',
    minWidth: '90vw',
    padding: '20px',
  },
}))

export const UnsubscribeContainer = () => {
  const [email, setEmail] = useState('')
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');
    setEmail(emailParam || '')
  }, [location.search])

  return (
    <Container>
      <InfoCard>
        <Grid 
          sx={{
            flexDirection: {
              xs: 'row',
              md: 'column',
            },
          }} display={'flex'} justifyContent={'center'} container>
          <Grid item xs={12} md={8} display={'flex'} flexDirection={'column'} justifyContent="center" alignItems="start">
            <Typography variant="h5" component="h5" sx={{fontWeight: '700'}}>
            Unsubscribe
            </Typography>
            <Typography component="div">
              {'Your email address'} <label style={{fontWeight: 'bold'}}>{email}</label>
            </Typography>
            <Typography component="div">
              {'You will not receive any more emails from support@lagfast.com'}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <Button sx={{background: '#155bd5'}} onClick={() => {}} variant={'contained'}>{'Unsubscribe'}</Button>
          </Grid>
        </Grid>
        <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'} container>
          <Grid item>
            <Typography sx={{fontSize: '14px'}} component="div">
            Need help? support@lagfast.com
            </Typography>
          </Grid>
          <Grid item>
            <img className="icon-size" height={'20px'} src={TitleImg} alt=""/>
          </Grid>
        </Grid>

      </InfoCard>
    </Container>
  )
}