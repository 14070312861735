import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { botActions } from 'features/bot/store/bot.slice'

import {commonActions} from "../../common/store";
import {ApiError} from "../../common/types";
import {deleteBot, getBots, createBot, updateBot, getMessages, checkMessages} from "../api";
import {Bot, CreateBotInput, Message} from "../types";


// Worker Sagas
function* onCreateBot({
  payload,
}: {
  type: typeof botActions.createBot
  payload: CreateBotInput
},
): SagaIterator {
  try {
    const bot: Bot = yield call(createBot, payload)
    yield put(botActions.createBotSuccess(bot))
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

function* onUpdateJob({
  payload,
}: {
  type: typeof botActions.updateBot
  payload: {
    botId: string,
    data: CreateBotInput
  }
},
): SagaIterator {
  try {
    const bot: Bot = yield call(updateBot, payload.botId, payload.data)
    yield put(botActions.updateBotSuccess(bot))

  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}


function* onGetBots(): SagaIterator {
  try {
    const bots: Bot[] = yield call(getBots)
    yield put(botActions.getBotsSuccess(bots))
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

function* onGetMessages(): SagaIterator {
  try {
    const messages: Message[] = yield call(getMessages)
    yield put(botActions.getMessagesSuccess(messages))

    if (messages.length > 0 && !messages[0].checked) {
      yield call(checkMessages)
    }
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}



function* onDeleteBot({payload}: {
  type: typeof botActions.deleteBot.type
  payload: string
},
): SagaIterator {
  try {
    yield call(deleteBot, payload)
    yield put(botActions.deleteBotsSuccess(payload))
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Watcher Saga
export function* botWatcherSaga(): SagaIterator {
  yield takeEvery(botActions.createBot.type, onCreateBot)
  yield takeEvery(botActions.updateBot.type, onUpdateJob)
  yield takeEvery(botActions.deleteBot.type, onDeleteBot)
  yield takeEvery(botActions.getBots.type, onGetBots)
  yield takeEvery(botActions.getMessages.type, onGetMessages)
}

export default botWatcherSaga
