import {Box, styled} from '@mui/material'
import React from 'react'

import {PaymentContainer} from "../features/payment/components/paymentContainer";



const PaymentPageContainer = styled(Box)({
  height: 'calc(100vh - 125px)', // Using calc in CSS
  width: '100%',
  padding: '30px',
})

const PaymentPage = () => (
  <PaymentPageContainer>
    <PaymentContainer/>
  </PaymentPageContainer>
)

export default PaymentPage
