import {Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useState} from 'react'

export type FeatureItemProps = {
  label: string | undefined
  value: string | undefined
}

export const FeatureItem = ({label, value}: FeatureItemProps) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Grid item container direction="row" spacing={0}>
      <Typography
        sx={{fontSize: '14px', fontWeight: 'bold', color: "#3b3b3b"}}
        variant={'caption'}
        gutterBottom
        component="div"
      >
        {label ? `${label}:   ` : ''}
      </Typography>
      <Tooltip title={`${label}: ${value}`} arrow>
        <Typography sx={{fontSize: '14px',color: "#3b3b3b"}} gutterBottom component="div">
          {value}
        </Typography>
      </Tooltip>
    </Grid>
  )
}
