import {Grid} from "@mui/material";
import React, {useEffect} from 'react'

import usePaymentService from "../hooks/usePaymentService";

import {CostCard} from "./CostCard";
import {PayModal} from "./payModal";

const payments = [
  {
    name: '1 USD',
    description: 'Charge 1 usd + 0.36 usd (Paypal Fee)',
    token: 'GEZJNQA54AJQ4'
  },
  {
    name: '10 USD',
    description: 'Charge 10 usd + 0.78 usd (Paypal Fee)',
    token: 'ZPTP8BPC4JPY8'
  }
]
export const PaymentContainer = () => {
  const { getPlans, payment: {plans} } = usePaymentService()

  useEffect(() => {
    getPlans()
  }, [getPlans])

  return <Grid container direction="row">
    {plans.map((item, index) =>
      <Grid item key={index} xs={12} md={4} lg={3} p={2}>
        <CostCard item={item}/>
      </Grid>
    )}
    <PayModal/>
  </Grid>
}
