// DUCKS pattern
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {Bot, CreateBotInput, Message} from "features/bot/types";
import type { RootState } from 'store/store'

import {AccountListItem} from "../../accounts/types";
import {NarratorFeed} from "../../jobs/types";


export interface BotState {
  bots: Bot[]
  feeds: NarratorFeed[]
  messages: Message[]
  messageCount: number
  loading: boolean
  error: unknown | undefined
}

const initialState: BotState = {
  bots: [],
  feeds: [],
  messages: [],
  messageCount: 0,
  loading: false,
  error: undefined,
}

// slice
export const botSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    getBotsSuccess(state, action: PayloadAction<Bot[]>) {
      state.bots = action.payload
      state.loading = false
      state.error = undefined
    },
    getMessagesSuccess(state, action: PayloadAction<Message[]>) {
      state.messages = action.payload
      state.loading = false
      state.error = undefined
    },
    setMessageCount(state, action: PayloadAction<number>) {
      state.messageCount = action.payload
    },
    setFeeds(state, action: PayloadAction<NarratorFeed[]>) {
      state.feeds = action.payload
    },
    createBotSuccess(state, action: PayloadAction<Bot>) {
      state.bots.push(action.payload)
      state.loading = false
      state.error = undefined
    },
    updateBotSuccess(state, action: PayloadAction<Bot>) {
      state.bots = state.bots.map((bot) => {
        if (bot.id === action.payload.id) {
          return action.payload
        }
        return bot
      });
      state.loading = false
      state.error = undefined
    },
    deleteBotsSuccess(state, action: PayloadAction<string>) {
      state.bots = state.bots.filter(bot => bot.id !== action.payload)
      state.loading = false
      state.error = undefined
    },
  },
})

// Actions
export const botActions = {
  getBotsSuccess: botSlice.actions.getBotsSuccess,
  getMessagesSuccess: botSlice.actions.getMessagesSuccess,
  setMessageCount: botSlice.actions.setMessageCount,
  setFeeds: botSlice.actions.setFeeds,
  createBotSuccess: botSlice.actions.createBotSuccess,
  updateBotSuccess: botSlice.actions.updateBotSuccess,
  deleteBotsSuccess: botSlice.actions.deleteBotsSuccess,
  deleteBot: createAction(`${botSlice.name}/deleteBot`, (id?: string) => ({
    payload: id
  })),
  createBot: createAction(`${botSlice.name}/createBot`, (data: CreateBotInput) => ({
    payload: data
  })),
  updateBot: createAction(`${botSlice.name}/updateBot`, (botId: string, data: CreateBotInput) => ({
    payload: {botId, data}
  })),
  getBots: createAction(`${botSlice.name}/getBots`, () => ({
    payload: {}
  })),
  getMessages: createAction(`${botSlice.name}/getMessages`, () => ({
    payload: {}
  })),
}

// Selectors
export const selectBot = (state: RootState) => state.bot

// Reducer
export default botSlice.reducer
