import {Grid, Typography} from "@mui/material";
import React from 'react'

import {useAccountsService} from "../../../hooks";


export type PromptPreviewPanelProps = {
  prompt: string | undefined
  promptReplacement: string | undefined
}

export const PromptPreviewPanel = ({ prompt, promptReplacement }: PromptPreviewPanelProps) => {
  const {accounts: {promptPreview}} = useAccountsService()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            fontSize: '14px',
            border: '1px solid #c7c7c7',
            padding: '10px',
            cursor: 'pointer',
            borderRadius: '8px',
            background: '#f7f7f7'}}
          variant={'body1'} component="div">
          {(prompt || '').split('\n').map((line, index) => (
            <div key={index}>
              {index !== 0 && <div style={{maxHeight: '7px', minHeight: '7px'}}>&nbsp;</div>}
              <div dangerouslySetInnerHTML={{__html: `${line}.`}}/>
            </div>
          ))}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            fontSize: '14px',
            border: '1px solid #c7c7c7',
            padding: '10px',
            cursor: 'pointer',
            borderRadius: '8px',
            background: '#f7f7f7'}}
          variant={'body1'} component="div">
          {(promptReplacement || "").split('\n').map((line, index) => (
            <div key={index}>
              <div dangerouslySetInnerHTML={{__html: `${line}`}}/>
            </div>
          ))}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>

      </Grid>
    </Grid>
  )
}
