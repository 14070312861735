import React, { useEffect, useState } from 'react'

export interface TypingAnimationProps {
  fullText: string
  scroll: boolean
  stopAnimation?: boolean
  user?: boolean
  animationCompleted?: () => void
  scrollToBottom?: () => void
}

const TypingAnimation: React.FC<TypingAnimationProps> = ({user, stopAnimation, scroll, fullText, animationCompleted, scrollToBottom}) => {
  const [text, setText] = useState<string>('')

  useEffect(() => {
    let index = 0
    const delay = 20
    setText(scroll ? '' : fullText)
    const typingInterval = setInterval(() => {
      if (stopAnimation || user) {
        setText(fullText)
        return
      }
      if (index < fullText?.length) {
        setText((prevText) => prevText + fullText.charAt(index))
        index += 1
      } else {
        clearInterval(typingInterval)
        if (animationCompleted) animationCompleted()
      }
    }, delay);

    return () => {
      clearInterval(typingInterval)
    };
  }, [fullText])

  useEffect(() => {
    if (scrollToBottom) scrollToBottom()
  }, [text]);


  return (
    <div style={{marginBottom: '10px', padding: '10px 20px', borderRadius: '10px', maxWidth: '90%',
      background: '#4b0000', color: 'white', width: "fit-content"}}>
      {text?.split('\n').map((line, index) => (
        <div style={{width: "fit-content", fontSize: '18px'}} key={index}>
          {index !== 0 && <div style={{maxHeight: '7px', minHeight: '7px'}}>&nbsp;</div>}
          <div style={{
            width: "fit-content",
          }} dangerouslySetInnerHTML={{__html: `${line}`}}/>
        </div>
      ))}
    </div>
  );
};

export default TypingAnimation