class Storage {
  ACCESS_TOKEN = 'backend.access'

  GPT_TOKEN = 'gpt.access'

  AD_TIME = 'ad.time'

  REFRESH_TOKEN = 'backend.refresh'

  FCM_DEVICE = 'fcm.device'

  getAccessToken(): string | undefined {
    return localStorage.getItem(this.ACCESS_TOKEN) || undefined
  }

  getRefreshToken(): string | undefined {
    return localStorage.getItem(this.REFRESH_TOKEN) || undefined
  }

  setAccessToken(token: string | undefined) {
    if (!token) return
    localStorage.setItem(this.ACCESS_TOKEN, token)
  }

  setGPTToken(token: string | undefined) {
    if (!token) return
    localStorage.setItem(this.GPT_TOKEN, token)
  }

  clearGPTToken() {
    localStorage.removeItem(this.GPT_TOKEN)
  }

  setAdTime() {
    localStorage.setItem(this.AD_TIME, new Date().getTime().toString())
  }

  getAdTime() {
    const prevAdTime = parseInt(localStorage.getItem(this.AD_TIME) || '0', 10)
    const timeNow = new Date().getTime()
    const timeDiff = timeNow - prevAdTime
    if (timeDiff > 1000 * 60 * 60) {
      localStorage.setItem(this.AD_TIME, new Date().getTime().toString())
      return true
    }
    return false
  }



  getGPTToken(): string | undefined {
    return localStorage.getItem(this.GPT_TOKEN) || undefined
  }

  setRefreshToken(token: string | undefined) {
    if (!token) return
    localStorage.setItem(this.REFRESH_TOKEN, token)
  }

  getFCMDeviceId(): string | undefined {
    return localStorage.getItem(this.FCM_DEVICE) || undefined
  }

  setFCMDeviceId(token: string | undefined) {
    if (!token) return
    localStorage.setItem(this.FCM_DEVICE, token)
  }

  // eslint-disable-next-line class-methods-use-this
  clear() {
    localStorage.clear()
  }
}

export const storage = new Storage()