import { SagaIterator } from '@redux-saga/core'
import {call, put, takeEvery} from "redux-saga/effects";

import {
  createProposal,
  deleteFeed, emptyFeeds, emptyTrash,
  generateProposal,
  getFeeds,
  getHistory,
  updateFeed,
  updateProposal, updateQuestion
} from "features/jobs/api";
import {jobsActions} from "features/jobs/store/jobs.slice";

import {accountsActions} from "../../accounts/store";
import {gptActions} from "../../chatGPT/store";
import {commonActions} from "../../common/store";
import {ApiError} from "../../common/types";
import {pubActions} from "../../public/store";
import {
  Feed,
  FeedDeleteParam,
  FeedInput,
  FeedRequestParam,
  FeedUpdateParam,
  HistoryResponse,
  Proposal,
  ProposalInput
} from "../types";


function* onGetNewFeeds({payload}: {
  type: typeof jobsActions.getNewFeeds.type
  payload: {param: FeedRequestParam}
},
): SagaIterator {
  try {
    const feedResponse: Feed[] = yield call(getFeeds, payload.param, 'created')
    if (feedResponse) {
      yield put(jobsActions.getFeedsSuccess({feedResponse, type: payload.param.type}))
    }
  } catch (e) {
    console.log(e)
  }
}

function* onGetFeeds({payload}: {
  type: typeof jobsActions.getFeeds.type
  payload: {param: FeedRequestParam}
},
): SagaIterator {
  yield put(jobsActions.getFeedsStart())
  try {
    const orderBy: string = payload.param.type === 'feed' ? '-created' : '-updated'
    const feedResponse: Feed[] = yield call(getFeeds, payload.param, orderBy)
    if (feedResponse) {
      yield put(jobsActions.getFeedsSuccess({feedResponse, type: payload.param.type}))
    }
  } catch (e) {
    yield put(jobsActions.getFeedsFailure(e))
  }
}


function* getWorkingHistory({payload}: {
  type: typeof jobsActions.getWorkingHistory.type
  payload: {jobId: number}
},
): SagaIterator {
  yield put(jobsActions.getHistoryStart())
  try {
    const history: HistoryResponse = yield call(getHistory, payload.jobId)
    yield put(jobsActions.getHistorySuccess({history}))
  } catch (e) {
    yield put(jobsActions.getFeedsFailure(e))
  }
}


// Worker Sagas
function* onUpdateFeed({payload}: {
  type: typeof jobsActions.updateFeed.type
  payload: {param: FeedUpdateParam}
},
): SagaIterator {
  yield put(jobsActions.getStart())
  try {
    const data: FeedInput = {id: payload.param.id, status: payload.param.status}
    if (payload.param.status === 'submit') {
      data.submit_status= 'pending'
    }
    yield call(updateFeed, payload.param.id, data)
    yield put(jobsActions.deleteFeedSuccess({feedId: payload.param.id}))
    if (payload.param.type === 'feed') {
      yield put(accountsActions.updateFeedCount({accountId: payload.param.accountId, increase: false}))
    } else if (payload.param.status === 'feed') {
      yield put(accountsActions.updateFeedCount({accountId: payload.param.accountId, increase: true}))
    }
  } catch (e) {
    yield put(jobsActions.getFailure(e))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Worker Sagas
function* onDeleteFeed({payload}: {
  type: typeof jobsActions.updateFeed.type
  payload: {param: FeedDeleteParam}
},
): SagaIterator {
  yield put(jobsActions.getStart())
  try {
    yield call(deleteFeed, payload.param.id)
    yield put(jobsActions.deleteFeedSuccess({feedId: payload.param.id}))
    if (payload.param.type === 'feed') {
      yield put(accountsActions.updateFeedCount({accountId: payload.param.accountId, increase: false}))
    }
  } catch (e) {
    yield put(jobsActions.getFailure(e))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Worker Sagas
function* onUpdateProposal({payload}: {
  type: typeof jobsActions.updateProposal.type
  payload: {feedId: number, id: number, data: ProposalInput}
},
): SagaIterator {
  yield put(jobsActions.updateProposalStart(payload.feedId))
  try {
    const proposal: Proposal = yield call(updateProposal, payload.id, payload.data)
    yield put(jobsActions.updateProposalSuccess({feedId: payload.feedId, proposal}))
    yield put(pubActions.updateFeedProposal(proposal))
  } catch (e) {
    yield put(jobsActions.updateProposalFailure(payload.feedId))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Worker Sagas
function* onCreateProposal({payload}: {
  type: typeof jobsActions.updateProposal.type
  payload: {feedId: number, data: ProposalInput}
},
): SagaIterator {
  yield put(jobsActions.updateProposalStart(payload.feedId))
  try {
    const feed: Feed = yield call(createProposal, payload.feedId, payload.data)
    yield put(jobsActions.createProposalSuccess({feedId: payload.feedId, feed}))
  } catch (e) {
    yield put(jobsActions.updateProposalFailure(payload.feedId))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Worker Sagas
function* onGenerateProposal({payload}: {
  type: typeof jobsActions.updateProposal.type
  payload: {feedId: number}
},
): SagaIterator {
  yield put(jobsActions.updateProposalStart(payload.feedId))
  try {
    const proposal: Proposal = yield call(generateProposal, payload.feedId)
    yield put(jobsActions.updateProposalSuccess({feedId: payload.feedId, proposal}))
    yield put(pubActions.updateFeedProposal(proposal))
  } catch (e) {
    yield put(jobsActions.updateProposalFailure(payload.feedId))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Worker Sagas
function* onGenerateGptProposal({payload}: {
  type: typeof jobsActions.updateProposal.type
  payload: {feedId: number, data: string}
},
): SagaIterator {
  try {
    yield put(jobsActions.updateGPTProposalSuccess({feedId: payload.feedId, proposal: payload.data}))
    const data = {
      cover: payload.data
    }
    yield call(updateProposal, payload.feedId, data as ProposalInput)
  } catch (e) {
    console.log(e)
  }
}

// Worker Sagas
function* onGenerateGptSummary({payload}: {
  type: typeof jobsActions.updateProposal.type
  payload: {feedId: number, data: string}
},
): SagaIterator {
  try {
    yield put(jobsActions.updateGPTSummarySuccess({feedId: payload.feedId, text: payload.data}))
  } catch (e) {
    console.log(e)
  }
}

function* onUpdateGptQuestion({payload}: {
  type: typeof jobsActions.updateProposal.type
  payload: {feedId: number, index: number, data: string}
},
): SagaIterator {
  try {
    yield put(jobsActions.updateGPTQuestionSuccess({feedId: payload.feedId, index: payload.index, proposal: payload.data}))
    yield call(updateQuestion, payload.feedId, {data: payload.data, index: payload.index})
  } catch (e) {
    console.log(e)
  }
}

// Worker Sagas
function* onGenerateGptFailed({payload}: {
  type: typeof jobsActions.updateProposal.type
  payload: {feedId: number, errorMsg: string}
},
): SagaIterator {
  yield put(jobsActions.updateGPTFailed({feedId: payload.feedId}))
  const err = {
    status: 0,
    statusText: 'ChatGPT Error',
    data: '',
    message: `ChatGPT Error: ${payload.errorMsg}`
  }
  if (payload.errorMsg === 'UNAUTHORIZED') {
    yield put(gptActions.setGptState(false))
  }
  yield put(commonActions.setError(err))
  yield put(commonActions.showMessage(true))
}

function* onMoveFeedsToTrash({payload}: {
  type: typeof jobsActions.moveFeedsToTrash.type
  payload: {accountId: number, type: string}
},
): SagaIterator {
  yield put(commonActions.showLoading(true))
  try {
    yield call(emptyFeeds, payload.accountId)
    if (payload.type === 'feed') {
      yield put(jobsActions.emptyFeeds())
      yield put(accountsActions.emptyFeedCount({accountId: payload.accountId}))
    }
    yield put(commonActions.showLoading(false))
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
    yield put(commonActions.showLoading(false))
  }
}

function* onEmptyTrash({payload}: {
  type: typeof jobsActions.emptyTrash.type
  payload: {accountId: number, type: string}
},
): SagaIterator {
  yield put(commonActions.showLoading(true))
  try {
    yield call(emptyTrash, payload.accountId)
    if (payload.type === 'trash') {
      yield put(jobsActions.emptyFeeds())
    }
    yield put(commonActions.showLoading(false))
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
    yield put(commonActions.showLoading(false))
  }
}



// Watcher Saga
export function* jobsWatcherSaga(): SagaIterator {
  yield takeEvery(jobsActions.getNewFeeds.type, onGetNewFeeds)
  yield takeEvery(jobsActions.getFeeds.type, onGetFeeds)
  yield takeEvery(jobsActions.getWorkingHistory.type, getWorkingHistory)
  yield takeEvery(jobsActions.deleteFeed.type, onDeleteFeed)
  yield takeEvery(jobsActions.updateFeed.type, onUpdateFeed)
  yield takeEvery(jobsActions.moveFeedsToTrash.type, onMoveFeedsToTrash)
  yield takeEvery(jobsActions.emptyTrash.type, onEmptyTrash)
  yield takeEvery(jobsActions.updateProposal.type, onUpdateProposal)
  yield takeEvery(jobsActions.createProposal.type, onCreateProposal)
  yield takeEvery(jobsActions.generateProposal.type, onGenerateProposal)
  yield takeEvery(jobsActions.updateGptProposal.type, onGenerateGptProposal)
  yield takeEvery(jobsActions.updateGptSummary.type, onGenerateGptSummary)
  yield takeEvery(jobsActions.updateGptQuestion.type, onUpdateGptQuestion)
  yield takeEvery(jobsActions.updateGptFailed.type, onGenerateGptFailed)
}

export default jobsWatcherSaga
