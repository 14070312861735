import { TextField } from '@mui/material'
import { InputProps } from '@mui/material/Input/Input'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

export interface FormInputProps {
  name: string
  control: Control<any> // eslint-disable-line
  label: string,
  type?: React.InputHTMLAttributes<unknown>['type']
  inputProps?: Partial<InputProps>
  size?: 'small' | 'medium'
  rows?: number | undefined
  placeholder?: string | undefined
  disabled?: boolean
}

export const FormTextField = (
  { name, control, label, inputProps, type="text", size='medium', rows, placeholder='', disabled=false}: FormInputProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <TextField
        helperText={error ? error.message : null}
        error={!!error}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        fullWidth
        label={label}
        variant="outlined"
        type={type}
        InputProps={inputProps}
        size={size}
        disabled={disabled}
        multiline={rows === undefined ? true : rows > 1}
        rows={rows}
      />
    )}
  />
)

export default FormTextField
