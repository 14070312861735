import CheckIcon from '@mui/icons-material/Check';
import {Button} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import * as React from 'react';

import {useAuthService} from "../../auth/hooks/useAuthService";
import {setFreePlan} from "../api";
import usePaymentService from "../hooks/usePaymentService";
import {Plan} from "../types";

export type CostCardProps = {
  item: Plan
}


export const CostCard = ({item}: CostCardProps) => {
  const { auth: {me}, getMe } = useAuthService()
  const { setPlan } = usePaymentService()

  const onSubscribe = async (plan: Plan) => {
    if (parseFloat(plan.price) > 0) {
      setPlan(plan)
    } else {
      await setFreePlan(plan.id)
      getMe()
    }
  }

  return (<Paper sx={{ maxWidth: 345, padding: '20px' }}>
    <Typography color='primary' gutterBottom variant="h5" component="div" style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
      {item.name}
    </Typography>
    <Typography variant="body2" color="text.secondary" style={{paddingBottom: '10px'}}>
      <span style={{fontSize: '20px', fontWeight: 'bold', color:'black'}}>${item.price}</span> / month
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {item.features.split('\n').map((line, index) => (
        <div key={index} style={{display: 'flex'}}>
          <CheckIcon color={'primary'} style={{fontSize: '15px', marginTop: '3px', marginRight: '3px'}}/>
          <div dangerouslySetInnerHTML={{__html: `${line}`}}/>
        </div>
      ))}
    </Typography>
    {
      me?.subscription?.plan.id === item.id ?
        <div style={{fontWeight: 'bold', fontSize: '20px', padding: '10px'}}>Current Plan</div> :
        <Button
          sx={{
            marginTop: '20px',
            height: '30px',
            textTransform: 'capitalize'
          }}
          onClick={() => onSubscribe(item)}
          variant={'contained'}
          size={'small'}>
          {'Subscribe'}
        </Button>
    }


  </Paper>
  )}