import { api } from 'features/api'

import {FeedRequestParam, FeedResponse} from "../../jobs/types";
import {Bot, CreateBotInput} from "../types";

export const getBots = () =>
  api.get(`/api/bots/`);

export const getMessages = () =>
  api.get(`/api/messages/`);

export const checkMessages = () =>
  api.post(`/api/messages/check_messages/`);

export const deleteBot = (id: string) =>
  api.delete(`/api/bots/${id}/`);

export const createBot = (data: CreateBotInput): Promise<Bot> =>
  api.post(`/api/bots/`, data);

export const updateBot = (id: string, data: CreateBotInput): Promise<Bot> =>
  api.patch(`/api/bots/${id}/`, data);