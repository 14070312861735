import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { paymentActions } from 'features/payment/store/payment.slice'

import {commonActions} from "../../common/store";
import {ApiError} from "../../common/types";
import {getPlans} from "../api";
import {Plan} from "../types";


function* onGetPlans(): SagaIterator {
  try {
    const plans: Plan[] = yield call(getPlans)
    yield put(paymentActions.getPlansSuccess(plans))
  } catch (e) {
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Watcher Saga
export function* paymentWatcherSaga(): SagaIterator {
  yield takeEvery(paymentActions.getPlans.type, onGetPlans)
}

export default paymentWatcherSaga
