import {Checkbox, FormControlLabel, Tooltip} from '@mui/material'
import React from 'react'
import {Control, Controller} from 'react-hook-form'


export interface FormCheckboxFieldProps {
  name: string
  control: Control<any> // eslint-disable-line
  label: string,
  size?: 'small' | 'medium',
  disabled?: boolean,
  description?: string,
}

export const FormCheckboxField = ({
  name,
  control,
  label,
  description,
  size = 'medium',
  disabled = false
}: FormCheckboxFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({field: {onChange, value}, fieldState: {error}}) => (
      <Tooltip title={description || ''} placement="right" arrow>
        <FormControlLabel disabled={disabled} control={<Checkbox checked={value} onChange={onChange}/>} label={label}/>
      </Tooltip>
    )}
  />
)
