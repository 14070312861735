import {Box, styled} from '@mui/material'
import React from 'react'

import {ResetPasswordContainer} from "../features/auth/components/ResetPasswordContainer";


const PageContainer = styled(Box)({
  height: 'calc(100vh - 125px)', // Using calc in CSS
  width: '100%',
})

const ResetPasswordPage = () => (
  <PageContainer>
    <ResetPasswordContainer/>
  </PageContainer>
)

export default ResetPasswordPage
