import styled from '@emotion/styled'
import {Grid} from '@mui/material'
import Link from '@mui/material/Link'
import React from 'react'
import {Navigate} from 'react-router-dom'

import TitleImg from 'assets/images/logo-with-title-blue.png'
import {LoginForm} from 'features/auth/components/LoginForm'
import {useAuthService} from 'features/auth/hooks/useAuthService'

const Container = styled.div(() => ({
  width: '100vw',
  height: '100vh',
  background: '#155bd5',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const LoginCard = styled.div(() => ({
  width: '30%',
  height: 350,
  minWidth: 300,
  background: '#ffffff',
  borderRadius: 20,
  padding: 30,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

const Logo = styled.img(() => ({
  height: 40,
}))


export const LoginContainer = () => {
  const {login, auth: {token, me}} = useAuthService()

  if (token?.access && me?.subscription === null) {
    return <Navigate to='/membership' replace/>
  }
  if (token?.access && me?.subscription !== null) {
    return <Navigate to='/help' replace/>
  }

  return (
    <Container>
      <LoginCard>
        <Grid display={'flex'} justifyContent={'center'}>
          <img className="icon-size" height={'50px'} src={TitleImg} alt=""/>
        </Grid>
        <LoginForm onSubmit={login}/>
        <Grid display={'flex'} justifyContent={'flex-end'}>
          <Link href='/signup' underline='none'>{'Create Account'}</Link>
        </Grid>
      </LoginCard>
    </Container>
  )
}
