import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'features/narrator/store/store.slice';

import {ApiError} from "../../common/types";
import {deleteBotMessage, getBotMessage} from "../api";
import {ChatBotMessageResponse} from "../types";



function* onGetChatMessage({payload}: {
  type: typeof actions.getChatMessage.type
  payload: {message: string, timezone: string}
},
): SagaIterator {
  try {
    yield put(actions.getChatMessageStart(payload.message))
    const data: ChatBotMessageResponse = yield call(getBotMessage, payload.message, payload.timezone)
    yield put(actions.getChatMessageSuccess(data))
    yield put(actions.getChatMessageEnd())
  } catch (e) {
    yield put(actions.getChatMessageEnd())
    const err = e as ApiError
  }
}

function* onDeleteMessage(): SagaIterator {
  try {
    yield call(deleteBotMessage)
    yield put(actions.clearChatMessageSuccess())
  } catch (e) {
    const err = e as ApiError
  }
}

// Watcher Saga
export function* narratorWatcherSaga(): SagaIterator {
  yield takeEvery(actions.getChatMessage.type, onGetChatMessage)
  yield takeEvery(actions.deleteMessage.type, onDeleteMessage)
}

export default narratorWatcherSaga
