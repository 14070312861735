import { api } from 'features/api'
import {
  ActivateInput,
  FCMInput,
  LoginInput,
  ResetPasswordConfirmInput,
  ResetPasswordInput,
  SignUpInput,
  Token, UpdatePasswordInput, UpdateUserNameInput, User
} from 'features/auth/types'

export const login = (data: LoginInput): Promise<Token> =>
  api.post('/api/auth/jwt/create/', data)

export const signup = (data: SignUpInput)=>
  api.post('/api/auth/users/', data)

export const activate = (data: ActivateInput): Promise<Token> =>
  api.post('/api/auth/users/activation/', data)

export const getMe = (): Promise<User> =>
  api.get('/api/auth/users/me/')

export const pushFCM = (data: FCMInput) =>
  api.put('/api/auth/fcm/push_fcm/', data)

export const updatePassword = (data: UpdatePasswordInput) =>
  api.post('/api/auth/users/set_password/', data)


export const resetPasswordSendEmail = (data: ResetPasswordInput) =>
  api.post('/api/auth/users/reset_password/', data)

export const resetPasswordConfirmApi = (data: ResetPasswordConfirmInput) =>
  api.post('/api/auth/users/reset_password_confirm/', data)

export const deleteMe = (): Promise<User> =>
  api.delete('/api/auth/users/me/')

export const updateName = (data: UpdateUserNameInput): Promise<User> =>
  api.patch('/api/auth/users/me/', data)