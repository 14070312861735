import createSagaMiddleware from '@redux-saga/core'
import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import logger from 'redux-logger'

import { Env } from 'config/Env'
import accountReducer from 'features/accounts/store/accounts.slice'
import authReducer from 'features/auth/store/auth.slice'
import botReducer from 'features/bot/store/bot.slice'
import gptReducer from 'features/chatGPT/store/gpt.slice'
import commonReducer from 'features/common/store/common.slice'
import dashboardReducer from 'features/dashboard/store/dashboard.slice'
import jobReducer from 'features/jobs/store/jobs.slice'
import narratorReducer from 'features/narrator/store/store.slice'
import paymentReducer from 'features/payment/store/payment.slice'
import pubReducer from 'features/public/store/pub.slice'
import { rootSaga } from 'store/rootSaga'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: Env.isDev(),
  savePreviousLocations: 1,
})

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware, routerMiddleware];
  if (Env.isDev()) {
    middleware.push(logger);
  }

  const store = configureStore({
    reducer: {
      auth: authReducer,
      account: accountReducer,
      bot: botReducer,
      pub: pubReducer,
      job: jobReducer,
      common: commonReducer,
      router: routerReducer,
      dashboard: dashboardReducer,
      gpt: gptReducer,
      narrator: narratorReducer,
      payment: paymentReducer,
    },
    devTools: Env.isDev(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: false })
        .concat(middleware)
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export const store = makeStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const history = createReduxHistory(store)
