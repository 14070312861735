import PublishIcon from '@mui/icons-material/Publish';
import {
  Box, Chip, CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput, Paper,
  styled, Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import React, {useEffect, useMemo, useRef, useState} from "react";

import {JobPanel} from "../../jobs/components/jobComponents/feedItem/jobPanel";
import {Job} from "../../jobs/types";
import {useService as narratorService} from "../hooks/useService";
import {Message} from "../types";

import TypingAnimation from "./TypingAnimation";

const ChatPanel = styled(Box)({
  background: 'rgba(255,255,255,0.64)',
  borderRadius: '5px',
  height: '100%',
  overflow: 'auto',
  padding: '20px',
  border: '2px solid #bf5555',
  margin: '20px',
  fontSize: '15px',
  color: '#4b0000',
  scrollbarWidth: 'none', // Hide scrollbar in Firefox
  '::-webkit-scrollbar': {
    width: 0, // Hide scrollbar in webkit-based browsers (e.g., Chrome, Safari)
  },
  "@media (max-width: 768px)": {
    width: '90%',
    left: 0,
    right: 0,
  }
})
export const CharacterPlayer = () => {
  const chatPanelRef = useRef<HTMLDivElement>(null);
  const {state: {jobs, messages, loading}, getBotMessage} = narratorService()
  const [value, setValue] = useState('');
  const [scroll, setScroll] = useState(false);
  const [stopAnimation, setStopAnimation] = useState(true);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }
  useEffect(() => () => {
    setScroll(false)
  }, []); 
  const scrollToBottom = () => {
    if (chatPanelRef.current) {
      chatPanelRef.current.scrollTop = chatPanelRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatPanelRef.current?.scrollTop]);

  const getTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && value.length > 1) {
      const timezone = getTimezone()
      getBotMessage(value, timezone)
      setValue('')
      setStopAnimation(false)
      setScroll(true)
    }
  };
  return (
    <Grid sx={{height: '100%', width: '100%'}}>
      <Grid container sx={{height: 'calc(100% - 70px)', width: '100%'}}>
        <Grid item md={4} sx={{
          height: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
        }}>
          <ChatPanel ref={chatPanelRef}>
            {messages.map((message: Message, index: number) => (
              <div key={index} style={{display: 'flex', justifyContent: message?.user ? 'end' : 'start'}}>
                <TypingAnimation
                  fullText={message.content}
                  scroll={scroll}
                  stopAnimation={stopAnimation}
                  user={message?.user}
                  animationCompleted={() => {
                  }}
                  scrollToBottom={() => {
                    scrollToBottom();
                  }}
                />
              </div>
            ))}
          </ChatPanel>
        </Grid>
        <Grid item md={8} sx={{height: '100%', overflow: 'auto'}}>
          <Box sx={{width: '100%', height: 'calc(100% - 100px)'}}>
            {jobs?.map((job: Job, index: number) => (
              <Grid sx={{margin: '10px 20px', padding: '20px', background: 'white', borderRadius: '5px'}} key={index}>
                <Grid item xs={12} container>
                  {job?.dev_location &&
                      <Grid item xs={12} sm={12} lg={9} container direction={'row'} alignItems={'center'}>
                        <Alert severity="warning">{job?.dev_location}</Alert>
                      </Grid>
                  }
                  <Grid item xs={12} sm={12} lg={9} container direction={'row'} alignItems={'center'}>
                    <Typography sx={{fontWeight: "bold", fontSize: '20px', color: "#860433"}} gutterBottom
                      variant="subtitle1">
                      {job?.title}

                      {
                        (job?.connection !== undefined && job?.connection > 0) &&
                          <Chip variant="outlined" sx={{marginLeft: '5px'}} size={'small'} color={'primary'}
                            label={`${job?.connection} ${(job?.connection || 1) > 1 ? 'connects' : 'connect'}`}/>
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <JobPanel job={job}/>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
      <FormControl sx={{width: 'calc(100% - 20px)', height: '50px', margin: '0 10px'}} variant="outlined">
        <OutlinedInput
          type={'text'}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder={'Search jobs ...'}
          endAdornment={
            <InputAdornment position="end">
              {
                loading ?
                  <CircularProgress color="success"/>
                  :
                  <IconButton
                    onClick={() => {
                    }}
                    onMouseDown={() => {
                    }}
                    edge="end"
                  >
                    <PublishIcon/>
                  </IconButton>
              }

            </InputAdornment>
          }
        />
      </FormControl>
    </Grid>

  )
}