import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import React, {useCallback, useEffect, useState} from 'react'

import {useJobsService} from "features/jobs/hooks";

import {PromptPreviewPanel} from "./promptPreviewPanel";


export type PromptPreviewContainerProps = {
  prompt: string | undefined,
  promptReplacement: string | undefined,
  open: boolean,
  closeDialog: () => void
}

export const PromptPreviewContainer = ({ prompt, promptReplacement, open, closeDialog }: PromptPreviewContainerProps) => {
  const {job, getWorkingHistory} = useJobsService()
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={closeDialog}>
      <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
        {'Prompt Preview'}
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{minHeight: '40vh', position: 'relative'}}>
        <PromptPreviewPanel prompt={prompt} promptReplacement={promptReplacement}/>
      </DialogContent>
    </Dialog>
  )
}
