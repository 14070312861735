import { useCallback } from 'react'

import { commonActions, CommonState, selectCommon } from 'features/common/store'
import { ApiError } from 'features/common/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type CommonServiceOperators = {
  common: CommonState
  showMessage: (show: boolean) => void
  setError: (error: undefined | ApiError) => void
  showLoading: (loading: boolean) => void
  setMobileView: (isMobile: boolean) => void
}


export const useCommonService = (): Readonly<CommonServiceOperators> => {
  const dispatch = useAppDispatch()
  const common = useAppSelector(selectCommon)

  return {
    common,
    showMessage: useCallback((show: boolean) => dispatch(commonActions.showMessage(show)), [dispatch]),
    setError: useCallback((err: ApiError | undefined) => dispatch(commonActions.setError(err)), [dispatch]),
    showLoading: useCallback((loading: boolean) => dispatch(commonActions.showLoading(loading)), [dispatch]),
    setMobileView: useCallback((isMobile: boolean) => dispatch(commonActions.setMobileView(isMobile)), [dispatch]),
  }
}

export default useCommonService
