// DUCKS pattern
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {Bot, CreateBotInput, Message} from "features/bot/types";
import type { RootState } from 'store/store'

import {ApiError} from "../../common/types";
import {Feed, Job, NarratorFeed, Proposal} from "../../jobs/types";


export interface PubState {
  feed: Feed | undefined
  loading: boolean
  error: unknown | undefined
}


const initialState: PubState = {
  feed: undefined,
  loading: false,
  error: undefined,
}

// slice
export const pubSlice = createSlice({
  name: 'pub',
  initialState,
  reducers: {
    getJobStart(state) {
      state.loading = true
      state.error = undefined
    },
    getJobSuccess(state, action: PayloadAction<Feed>) {
      state.feed = action.payload
      state.loading = false
      state.error = undefined
    },
    updateFeedProposal(state, action: PayloadAction<Proposal>) {
      if(state.feed)
        state.feed.proposal = action.payload
    },
    getJobError(state, action: PayloadAction<ApiError | undefined>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

// Actions
export const pubActions = {
  getJobStart: pubSlice.actions.getJobStart,
  getJobSuccess: pubSlice.actions.getJobSuccess,
  updateFeedProposal: pubSlice.actions.updateFeedProposal,
  getJobError: pubSlice.actions.getJobError,
  getJob: createAction(`${pubSlice.name}/getJob`, (id?: string) => ({
    payload: id
  })),
}

// Selectors
export const selectPub = (state: RootState) => state.pub

// Reducer
export default pubSlice.reducer
