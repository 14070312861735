import { yupResolver } from '@hookform/resolvers/yup'
import {Button, FormHelperText, Grid, Link} from '@mui/material'
import Stack from '@mui/material/Stack'
import React, {useEffect} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { LoginInput } from 'features/auth/types'
import { FormPasswordField } from "libs/ui/components/FormPasswordField";
import { FormTextField } from 'libs/ui/components/FormTextField'
import { Spacer } from 'libs/ui/components/Spacer'

import {useCommonService} from "../../common/hooks/useCommonService";

export type LoginFormProps = {
  onSubmit: (data: LoginInput) => void
}

export const LoginForm = (props: LoginFormProps) => {
  const { t } = useTranslation()
  const { onSubmit } = props
  const {common: {alert, error}} = useCommonService()


  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('auth.login.form.required')),
    password: Yup.string().required(t('auth.login.form.required')),
  })

  const methods = useForm<LoginInput>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, control, watch, setValue } = methods
  const email = watch("email");
  useEffect(() => {
    setValue('email', email.toLowerCase())
  }, [email]);
  return (
    <Stack sx={{ pt: 0 }} direction='column' spacing={1} justifyContent='center'>
      <FormTextField name='email' label={'Email Address'} control={control} rows={1} />
      <Grid display={'flex'} justifyContent={'flex-end'}>
        <Link href='/reset-password' underline='none'>{'Forgot password?'}</Link>
      </Grid>
      <FormPasswordField name='password' label={'Password'} control={control} />
      <Spacer height={8} />
      {!!error && (
        <FormHelperText sx={{color: 'red'}}>{error.message}</FormHelperText>
      )}
      <Button sx={{background: '#155bd5'}} onClick={handleSubmit(onSubmit)} variant={'contained'}>{'Log In'}</Button>
    </Stack>
  )
}
