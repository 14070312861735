import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import {
  createAccount, createFilter, createPrompt,
  deleteAccount, deleteFilter,
  getAccount,
  getAccounts,
  getCountries, getFeeds, getFilterJobCount, getReport,
  updateAccount, updateFilter, updatePrompt
} from "features/accounts/api";
import { accountsActions } from 'features/accounts/store/accounts.slice'
import {jobsActions} from "features/jobs/store/jobs.slice";

import {botActions} from "../../bot/store";
import {commonActions} from "../../common/store";
import {ApiError} from "../../common/types";
import {
  Account,
  AccountListItem, AccountReportResponse,
  Country,
  CreateAccountInput, FeedResponse, FilteredJobCount,
  FilterInput,
  PromptInput,
  UpdateAccountInput
} from "../types";

// Worker Sagas
function* onCreateAccount({payload}: {
  type: typeof accountsActions.createAccount.type
  payload: CreateAccountInput
},
): SagaIterator {
  yield put(accountsActions.createAccountStart())
  try {
    const account: Account = yield call(createAccount, payload)
    yield put(accountsActions.getAccountSuccess(account))
    yield put(accountsActions.getAccounts())
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Worker Sagas
function* onUpdateAccount({payload}: {
  type: typeof accountsActions.updateAccount.type
  payload: UpdateAccountInput
},
): SagaIterator {
  yield put(accountsActions.createAccountStart())
  try {
    yield call(updateAccount, payload)
    yield put(accountsActions.getAccount(payload.id))
    if (payload.name) {
      yield put(accountsActions.getAccounts())
    }
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
    yield put(commonActions.showLoading(false))
  }
}


function* onDeleteAccount({payload}: {
  type: typeof accountsActions.deleteAccount.type
  payload: number
},
): SagaIterator {
  try {
    yield call(deleteAccount, payload)
    yield put(accountsActions.getAccounts())
    yield put(accountsActions.deleteAccountSuccess())
    yield put(accountsActions.editAccount(false))
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
  }
}

function* onGetCountries(): SagaIterator {
  try {
    const countries: Country[] = yield call(getCountries)
    yield put(accountsActions.getCountriesSuccess(countries))
  } catch (e) {
    yield put(accountsActions.getAccountsFailure(e))
  }
}

function* onGetAccounts(): SagaIterator {
  yield put(accountsActions.getAccountsStart())
  try {
    const accounts: AccountListItem[] = yield call(getAccounts)
    yield put(accountsActions.getAccountsSuccess(accounts))
  } catch (e) {
    yield put(accountsActions.getAccountsFailure(e))
  }
}



// Worker Sagas
function* onGetFilterJobCount({payload}: {
  type: typeof accountsActions.getFilterJobCount.type
  payload: number
},
): SagaIterator {
  yield put(accountsActions.createAccountStart())
  try {
    const jobCounts: FilteredJobCount[] = yield call(getFilterJobCount, payload)
    yield put(accountsActions.getFilteredJobSuccess(jobCounts))
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
  }
}


function* onGetAccount({
  payload
}: {
  type: typeof accountsActions.getAccount.type
  payload: number
}): SagaIterator {
  yield put(accountsActions.getAccountStart())
  try {
    const account: Account = yield call(getAccount, payload)
    yield put(accountsActions.getAccountSuccess(account))
  } catch (e) {
    yield put(accountsActions.getAccountFailure(e))
  }
}

function* onGetFeeds({
  payload
}: {
  type: typeof accountsActions.getFeeds.type
  payload: { id: number, lastCreated: string | null, type: string }
}): SagaIterator {
  try {
    const feedResponse: FeedResponse = yield call(getFeeds, payload.id, payload.lastCreated)
    yield put(accountsActions.getAccountsSuccess(feedResponse.accounts))
    yield put(botActions.setFeeds(feedResponse.feeds))
    yield put(botActions.setMessageCount(feedResponse.messages))
  } catch (e) {
    console.log()
  }
}

function* onGetReport({
  payload
}: {
  type: typeof accountsActions.getFeeds.type
  payload: { id: number }
}): SagaIterator {
  try {
    yield put(accountsActions.getAccountReportStart())
    const feedResponse: AccountReportResponse = yield call(getReport, payload.id)
    yield put(accountsActions.getAccountReportSuccess(feedResponse.data))
  } catch (e) {
    console.log()
  }
}

// Worker Sagas
function* onCreateFilter({payload}: {
  type: typeof accountsActions.createFilter.type
  payload: {accountId: number, data: FilterInput}
},
): SagaIterator {
  yield put(accountsActions.createAccountStart())
  try {
    yield call(createFilter, payload.accountId, payload.data)
    yield put(accountsActions.getAccount(payload.accountId))
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
    const err = e as ApiError
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Worker Sagas
function* onUpdateFilter({payload}: {
  type: typeof accountsActions.updateFilter.type
  payload: {accountId: number, data: FilterInput}
},
): SagaIterator {
  yield put(accountsActions.createAccountStart())
  try {
    yield call(updateFilter, payload.accountId, payload.data)
    yield put(accountsActions.getAccount(payload.accountId))
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
  }
}

function* onDeleteFilter({payload}: {
  type: typeof accountsActions.deleteFilter.type
  payload: {accountId: number, id: number}
},
): SagaIterator {
  try {
    yield call(deleteFilter, payload.id)
    yield put(accountsActions.getAccount(payload.accountId))
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
  }
}

// Worker Sagas
function* onCreatePrompt({payload}: {
  type: typeof accountsActions.createPrompt.type
  payload: {accountId: number, data: PromptInput}
},
): SagaIterator {
  yield put(accountsActions.createAccountStart())
  try {
    yield call(createPrompt, payload.data)
    yield put(accountsActions.getAccount(payload.accountId))
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
  }
}

// Worker Sagas
function* onUpdatePrompt({payload}: {
  type: typeof accountsActions.updatePrompt.type
  payload: {accountId: number, data: PromptInput}
},
): SagaIterator {
  yield put(accountsActions.createAccountStart())
  try {
    yield call(updatePrompt, payload.data)
    yield put(accountsActions.getAccount(payload.accountId))
  } catch (e) {
    yield put(accountsActions.createAccountFailure(e))
  }
}


// Watcher Saga
export function* accountsWatcherSaga(): SagaIterator {
  yield takeEvery(accountsActions.getCountries.type, onGetCountries)
  yield takeEvery(accountsActions.getAccount.type, onGetAccount)
  yield takeEvery(accountsActions.getFeeds.type, onGetFeeds)
  yield takeEvery(accountsActions.getAccounts.type, onGetAccounts)
  yield takeEvery(accountsActions.createAccount.type, onCreateAccount)
  yield takeEvery(accountsActions.updateAccount.type, onUpdateAccount)
  yield takeEvery(accountsActions.deleteAccount.type, onDeleteAccount)
  yield takeEvery(accountsActions.createFilter.type, onCreateFilter)
  yield takeEvery(accountsActions.updateFilter.type, onUpdateFilter)
  yield takeEvery(accountsActions.deleteFilter.type, onDeleteFilter)
  yield takeEvery(accountsActions.createPrompt.type, onCreatePrompt)
  yield takeEvery(accountsActions.updatePrompt.type, onUpdatePrompt)
  yield takeEvery(accountsActions.getFilterJobCount.type, onGetFilterJobCount)
  yield takeEvery(accountsActions.getAccountReport.type, onGetReport)

}

export default accountsWatcherSaga
