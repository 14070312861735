import Highcharts, {Options, SeriesColumnOptions} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, {useEffect, useRef} from 'react';

import {FilteredJobCount} from "../../../types";


export type FilterChartProps = {
  data: FilteredJobCount[]
}


export const FilterChart = ({data}: FilterChartProps) => {
  const chartRef = useRef<Highcharts.Chart | null>(null);
  const series: SeriesColumnOptions[] = [
    {
      type: 'column',
      name: 'Count Of Jobs',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: data.map((item) => ({
        name: item.date,
        x: new Date(item.date).getTime(),
        y: item.count,
        color: '#0efc3e',
      })),
    },
  ];


  const options: Options = {
    chart: {
      type: 'column',
      events: {
        load() {
          if (!chartRef.current) {
            chartRef.current = this;
          }
        },
      },
    },
    title: {
      text: `Estimate Job Count Per Day`,
    },
    xAxis: {
      title: {
        text: 'Date',
      },
      labels: {
        format: '{value:%Y-%m-%d}', // Format x-axis labels
      },
    },
    yAxis: {
      title: {
        text: 'Count',
      },
    },
    series,
  };
  console.log(series)

  return (
    <div style={{width: '100%'}}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default FilterChart;
