import {Grid} from "@mui/material";
import React from 'react'
import ReactPlayer from "react-player";

import {useAccountsService} from "features/accounts/hooks/useAccountsService";

import {useAuthService} from "../../../../auth";

import {AccountField} from "./accountField";

export const NotificationTabContainer = () => {
  const {accounts: {account}} = useAccountsService()
  const {auth: {me}} = useAuthService()

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <Grid container item>
          <AccountField label={'Slack Webhook Url'} value={account?.slack_webhook_url || ''}/>
        </Grid>
        <Grid item>
          <ReactPlayer url={`https://www.youtube.com/watch?v=jqqCRYhzBxQ`} />
        </Grid>
      </Grid>
    </>
  )
}
