import { Visibility, VisibilityOff } from "@mui/icons-material";
import {InputAdornment, OutlinedInput, InputLabel, FormControl, FormHelperText} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { InputProps } from '@mui/material/Input/Input'
import React, {useState} from 'react'
import { Control, Controller } from 'react-hook-form'

export interface FormInputProps {
  name: string
  control: Control<any> // eslint-disable-line
  label: string,
  type?: React.InputHTMLAttributes<unknown>['type']
  inputProps?: Partial<InputProps>
}

export const FormPasswordField = ({ name, control, label}: FormInputProps) => {

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          error={!!error}
          onChange={onChange}
          value={value}
          label={label}
          color={'primary'}
          fullWidth
          type={passwordShown ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passwordShown ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {!!error && (
          <FormHelperText sx={{color: 'red'}}>{error.message}</FormHelperText>
        )}
      </FormControl>
    )}
  />
}

export default FormPasswordField
