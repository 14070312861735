import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from 'react'

import {Job} from "features/jobs/types";


export type DescriptionPanelProps = {
  job: Job | undefined
}

export const DescriptionPanel = ({job,}: DescriptionPanelProps) => {
  const [expand, setExpand] = useState(false)
  useEffect(() => {
    setExpand(false)
  }, [job?.id])

  const toggleShowMore = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (expand) {
      const parent: HTMLElement | null = document.getElementById(`feed${job?.id}`)
      if (parent) {
        parent.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    setExpand(!expand)
  }
  return (
    <Grid item xs={12}>
      {
        expand ? <Typography
          sx={{
            fontSize: '14px',
            whiteSpace: 'pre-line',
            padding: '3px',
            borderRadius: '5px'}} variant={'body1'} component="div" >
          <div dangerouslySetInnerHTML={{__html: `${job?.description}`}}/>
        </Typography>
          :
          <Typography
            sx={{
              overflow: 'hidden',
              display:  '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              fontSize: '14px',
              padding: '3px',
              cursor: 'pointer',
              borderRadius: '8px'}}
            variant={'body1'} component="div">
            {job?.description.split('.\n').map((line, index) => (
              <div key={index}>
                {index !== 0 && <div style={{maxHeight: '7px', minHeight: '7px'}}>&nbsp;</div>}
                <div dangerouslySetInnerHTML={{__html: `${line}.`}}/>
              </div>
            ))}
          </Typography>
      }

      <Typography onClick={toggleShowMore} sx={{fontSize: '14px', color: 'blue', cursor: 'pointer'}} variant={'body1'}>
        {expand ? 'show less' : 'more'}
      </Typography>
    </Grid>)
}
