import {Box, styled} from '@mui/material'
import React from 'react'

import {AfterSIgnUpContainer} from "../features/auth/components/AfterSIgnUpContainer";


const AfterSignUpPageContainer = styled(Box)({
  height: 'calc(100vh - 125px)', // Using calc in CSS
  width: '100%',
})

const AfterSignUpPage = () => (
  <AfterSignUpPageContainer>
    <AfterSIgnUpContainer/>
  </AfterSignUpPageContainer>
)

export default AfterSignUpPage
