import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import React, {useCallback, useEffect, useState} from 'react'


import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {CreateAccountInput, Filter, FilterInput} from "features/accounts/types";

import FilterChart from "../filterChart";

import {AddFeedForm} from "./addFeedForm";

export type AddFeedDialogProps = {
  openDialog: boolean,
  closeDialog: () => void,
  filter?: Filter
}

export const AddFeedDialog = ({openDialog, closeDialog, filter}: AddFeedDialogProps) => {
  const {accounts: {account, filteredJobs}, createFilter, updateFilter, getFilterJobCount} = useAccountsService()
  useEffect(() => {
    if (filter)
      getFilterJobCount(filter.id)
  }, [filter])
  const onSubmit = useCallback((data: FilterInput) => {
    if (!account?.id) return
    if (data?.id) {
      updateFilter(account.id, data)
      setTimeout(() => {
        if (data?.id)
          getFilterJobCount(data?.id)
      }, 1000)
    } else {
      createFilter(account.id, data)
      closeDialog()
    }
  }, [closeDialog])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={openDialog}
      onClose={closeDialog}>
      <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
        {filter?.id ? 'Update a feed' : 'Add a feed'}
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AddFeedForm onSubmit={onSubmit} data={filter}/>
        {filteredJobs?.length > 0 && <FilterChart data={filteredJobs}/>}
      </DialogContent>
    </Dialog>
  )
}
