import { Alert, Snackbar } from '@mui/material'
import React, { useCallback, useEffect } from 'react'

export type AlertSnackbarProps = {
  open: boolean
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
  callback?: () => void
  vertical?: 'top' | 'bottom'
  horizontal?: 'left' | 'right' | 'center'
  duration?: number
}

export const AlertSnackbar: React.FC<AlertSnackbarProps> = ({
  open: pOpen,
  message,
  type = 'success',
  callback,
  vertical= 'top',
  horizontal = 'right',
  duration = 3000
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = useCallback(() => {
    setOpen(false)
    if (callback) callback()
  }, [callback])

  useEffect(() => {
    if(pOpen) setOpen(true)
  }, [pOpen])

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
      <Alert variant="filled" onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}