import CloseIcon from "@mui/icons-material/Close";
import {Button, Checkbox, Paper, Stack} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from '@mui/material/Typography';
import React, {useCallback, useState} from 'react'
import {useNavigate} from "react-router-dom";

import {Spacer} from "../../../../../libs/ui/components/Spacer";
import {useAccountsService} from "../../../hooks/useAccountsService";

export const DeleteButtonField = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const {deleteAccount, accounts: {account}} = useAccountsService()
  const clickDeleteBtn = () => {
    setOpen(true)
  }

  const onSubmit = () => {
    deleteAccount(account?.id)
    setOpen(false)
    navigate('accounts/')
  }

  const closeDialog = useCallback(() => {
    setOpen(false)
  }, [open])

  return (
    <Paper
      sx={{
        px: 2,
        py: 1,
        mt: 2,
        width: '90%',
        flexGrow: 1,
        cursor: 'pointer',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Typography
        onClick={clickDeleteBtn}
        color={'red'}>Delete Account
      </Typography>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={closeDialog}>
        <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
          {`Delete Feed`}
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>Are you sure to delete feed? You can not recover after delete.</Typography>
          <Spacer height={50} />
          <Stack direction='row' spacing={2} alignItems='center' justifyContent={'end'}>
            <Button color={'error'} onClick={closeDialog} variant={'outlined'}>{'Cancel'}</Button>
            <Button color={'error'} onClick={onSubmit} variant={'contained'}>{'Delete Feed'}</Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Paper>
  )
}
