import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Divider, Grid, Paper, Typography, Link} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import * as Yup from 'yup';

import useAccountsService from "features/accounts/hooks/useAccountsService";
import {Prompt, PromptInput, WordLength} from "features/accounts/types";
import { FormTextField } from 'libs/ui/components/FormTextField';

import {FormSelectField} from "../../../../../libs/ui/components/FormSelectField";

import {PromptPreviewContainer} from "./promptPreviewContainer";


export type PromptFormProps = {
  onSubmit: (data: PromptInput) => void
  data?: Prompt
}

function compareObjects(obj1: Record<string, unknown>, obj2: Record<string, unknown>): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

const defaultPrompt = "I want you act as senior developer.\n" +
  "My Name: @full_name,\n" +
  "Job title: @job_title,\n" +
  "Job Skills: @job_skill,\n" +
  "Job description: @job_description,\n" +
  "Give me only cover letter of job in English with below condition.\n" +
  "Start cover letter with \"@start_cover\",\n" +
  "Cover letter should not exceed @word_length words,\n" +
  "@additional_instruction,\n" +
  "End cover letter with \"@end_cover\""


const defaultQAPrompt = "I want you act as senior developer.\n" +
  "My Name: @full_name,\n" +
  "Job title: @job_title,\n" +
  "Job Skills: @job_skill,\n" +
  "Job description: @job_description,\n" +
  "Give me only answer for question of job in English with below condition.\n"


const defaultSummaryPrompt = "Job Description: @job_description\n Give me only summary in English as 3~5 bullets."

export const PromptForm = ({ onSubmit, data }: PromptFormProps) => {
  const [openPreview, setOpenPreview] = useState(false)
  const [changed, setChanged] = useState(false)
  const [promptReplacement, setPromptReplacement] = useState("")
  const defaultValues = useMemo(() => ({
    id: data?.id,
    full_name: data?.full_name || '',
    start_cover: data?.start_cover || '',
    end_cover: data?.end_cover || '',
    word_length: data?.word_length || 100,
    instruction: data?.instruction || '',
    account: data?.account,
    prompt: data?.prompt || defaultPrompt,
    qa_prompt: data?.qa_prompt || defaultQAPrompt
  }), [data?.account, data?.end_cover, data?.full_name, data?.id, data?.instruction, data?.prompt, data?.start_cover, data?.word_length])

  const updatePreviewModal = (state: boolean) => {
    setOpenPreview(state)
  }

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required('This field is required'),
    start_cover: Yup.string().required('This field is required'),
    end_cover: Yup.string().required('This field is required'),
    word_length: Yup.number().required('This field is required'),
    instruction: Yup.string().required('This field is required'),
    prompt: Yup.string().required('This field is required'),
    qa_prompt: Yup.string().required('This field is required'),
  })

  const methods = useForm<PromptInput>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, control, watch, setValue} = methods
  const reset = useCallback(() => {
    setValue('prompt', defaultPrompt)
  }, [setValue])

  const resetQA = useCallback(() => {
    setValue('qa_prompt', defaultQAPrompt)
  }, [setValue])

  useEffect(() => {
    const compareVal: boolean = compareObjects(watch(), data || {})
    setChanged(compareVal)
  }, [data, watch()])

  const prompt = watch('prompt')
  const { accounts: {countries} } = useAccountsService()
  const previewPrompt = (promptInput: PromptInput) => {
    let promptData = promptInput.prompt

    const jobDescription = "We are looking for an experienced WordPress developer to assist with fixing an error message on our website. The error says \"There has been a critical error on this website.\"\n\n" +
    "We need someone who can work efficiently and diligently to complete the task urgently within the advertised budget. The main objective of this project is to resolve the error message that is appearing on our website and ensure that the website is running smoothly."
    const jobTitle = 'WordPress Error Message Fix'
    const jobSkill = 'WordPress,Web Development,PHP'
    promptData = promptData?.replaceAll('@full_name', (promptInput.full_name || ''))
      .replaceAll('@full_name', (promptInput.full_name || ''))
      .replaceAll('@job_title', jobTitle)
      .replaceAll('@job_skill', jobSkill)
      .replaceAll('@job_description', jobDescription)
      .replaceAll('@start_cover', (promptInput.start_cover || ''))
      .replaceAll('@word_length', (promptInput.word_length || 50).toString())
      .replaceAll('@end_cover', (promptInput.end_cover || ''))
    updatePreviewModal(true)
    setPromptReplacement(promptData || "")
  }
  const previewQAPrompt = (promptInput: PromptInput) => {
    let promptData = promptInput.qa_prompt
    const jobDescription = "We are looking for an experienced WordPress developer to assist with fixing an error message on our website. The error says \"There has been a critical error on this website.\"\n\n" +
    "We need someone who can work efficiently and diligently to complete the task urgently within the advertised budget. The main objective of this project is to resolve the error message that is appearing on our website and ensure that the website is running smoothly."
    const jobTitle = 'WordPress Error Message Fix'
    const jobSkill = 'WordPress,Web Development,PHP'
    promptData = promptData?.replaceAll('@full_name', (promptInput.full_name || ''))
      .replaceAll('@full_name', (promptInput.full_name || ''))
      .replaceAll('@job_title', jobTitle)
      .replaceAll('@job_skill', jobSkill)
      .replaceAll('@job_description', jobDescription)
      .replaceAll('@start_cover', (promptInput.start_cover || ''))
      .replaceAll('@word_length', (promptInput.word_length || 50).toString())
      .replaceAll('@end_cover', (promptInput.end_cover || ''))
    updatePreviewModal(true)
    setPromptReplacement(promptData || "")
  }
  return (
    <>
      <Paper
        sx={{
          px: 2,
          py: 1,
          mb: 1,
          width: '100%',
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        }}
      >
        <Grid container spacing={2} px={2} py={1}>
          <Grid item xs={12}>
            <Typography sx={{fontWeight: "bold"}} gutterBottom variant="subtitle1">Main proposal setting</Typography>
            <Divider/>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                name='full_name'
                label={'Account Full Name'}
                size={'small'}
                control={control}
                type={'text'}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name='start_cover'
                label={'How to start proposal'}
                size={'small'}
                control={control}
                type={'text'}
                rows={2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name='end_cover'
                label={'How to end proposal'}
                control={control}
                size={'small'}
                type={'text'}
                rows={2}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormSelectField
                name='word_length'
                label={'Proposal words length'}
                control={control}
                items={Object.entries(WordLength).map((item) => ({ text:item[1], value: parseInt(item[0], 10) }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name='instruction'
                label={'Additional Instruction'}
                control={control}
                size={'small'}
                type={'text'}
                rows={2}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'right'}}>
            <FormTextField
              name='prompt'
              label={'ChatGPT Prompt For Cover letter'}
              control={control}
              size={'small'}
              type={'text'}
              rows={9}
            />
            <Link sx={{marginTop: '5px', fontSize: '10px', cursor: 'pointer', paddingRight: '10px'}}
              onClick={handleSubmit(previewPrompt)}>{'Preview'}</Link>
            <Link sx={{marginTop: '5px', fontSize: '10px', cursor: 'pointer'}} onClick={reset}>{'Reset Prompt'}</Link>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'right'}}>
            <FormTextField
              name='qa_prompt'
              label={'ChatGPT Prompt For Question And Answer'}
              control={control}
              size={'small'}
              type={'text'}
              rows={5}
            />
            <Link sx={{marginTop: '5px', fontSize: '10px', cursor: 'pointer', paddingRight: '10px'}}
              onClick={handleSubmit(previewQAPrompt)}>{'Preview'}</Link>
            <Link sx={{marginTop: '5px', fontSize: '10px', cursor: 'pointer'}} onClick={resetQA}>{'Reset Prompt'}</Link>
          </Grid>
        </Grid>
      </Paper>
      <Grid container item xs={12} justifyContent="flex-end">
        <Button disabled={changed} onClick={handleSubmit(onSubmit)} variant={'contained'}>{data?.id ? 'Update' : 'Save'}</Button>
      </Grid>
      <PromptPreviewContainer
        open={openPreview}
        closeDialog={() => updatePreviewModal(false)}
        prompt={prompt}
        promptReplacement={promptReplacement}
      />
    </>
  )
}
