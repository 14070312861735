import CloseIcon from '@mui/icons-material/Close';
import {Box, Tab, Tabs, Typography} from '@mui/material'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import React, {useCallback, useEffect, useState} from 'react'

import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {CreateAccountInput} from "features/accounts/types";

import {AboutTabContainer} from "./aboutTab/aboutTabContainer";
import {NotificationTabContainer} from "./aboutTab/notificationTabContainer";
import {BotSettingTabContainer} from "./botSettingTab/botSettingTabContainer";
import {JobFeedTabContainer} from "./jobFeedTab/jobFeedTabContainer";
import {PriceTabContainer} from "./priceTab/priceTabContainer";
import {ProposalSettingTabContainer} from "./proposalSettingTab/proposalSettingTabContainer";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 1, px:3, minHeight: '70vh', maxHeight: '70vh', background: '#f3f2f2', overflow: 'auto' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const AccountEditContainer = () => {
  const {accounts: {editModal, account}, editAccountAction} = useAccountsService()
  const [open, setOpen] = useState<boolean>(false)
  const [tab, setTabs] = React.useState(0);
  useEffect(() => {
    setOpen(editModal)
  }, [editModal])

  const closeDialog = useCallback(() => {
    setOpen(false)
    editAccountAction(false)
  }, [editAccountAction])

  const onSubmit = useCallback((data: CreateAccountInput) => {
    setOpen(false)
    editAccountAction(false)
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabs(newValue);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={closeDialog}>
      <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
        {account?.name}
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            sx={{minHeight: '25px', fontSize: '12px'}}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab sx={{minHeight: '25px', height: '25px', textTransform: 'capitalize'}} label="About" {...a11yProps(0)} />
            <Tab sx={{minHeight: '25px', height: '25px', textTransform: 'capitalize'}} label="Filter" {...a11yProps(1)} />
            <Tab sx={{minHeight: '25px', height: '25px', textTransform: 'capitalize'}} label="Proposal Setting" {...a11yProps(2)} />
            <Tab sx={{minHeight: '25px', height: '25px', textTransform: 'capitalize'}} label="Price Setting" {...a11yProps(3)} />
            <Tab sx={{minHeight: '25px', height: '25px', textTransform: 'capitalize'}} label="Bot Setting" {...a11yProps(4)} />
            <Tab sx={{minHeight: '25px', height: '25px', textTransform: 'capitalize'}} label="Notification" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <AboutTabContainer/>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <JobFeedTabContainer/>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ProposalSettingTabContainer/>
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <PriceTabContainer/>
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <BotSettingTabContainer/>
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <NotificationTabContainer/>
        </TabPanel>
      </Box>
    </Dialog>
  )
}
