import {Divider} from "@mui/material";
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import {useEffect} from "react";

import {useAccountsService} from "../../features/accounts/hooks/useAccountsService";

import {Navigation} from "./navigation";
import {SettingNav} from "./settings";

type Anchor = 'top';
export type AppDrawerProps = {
  open: boolean
  handleDrawerClose: (state: boolean) => void
}

export const MobileDrawer = ({ open, handleDrawerClose }: AppDrawerProps) => {
  const [state, setState] = React.useState(open);
  const { clickNav } = useAccountsService()
  const toggleDrawer = (anchor: Anchor, openState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    handleDrawerClose(openState)
    setState(openState);
    clickNav();
  };

  useEffect(() => {
    setState(open)
  }, [open])


  return (
    <Drawer
      sx={{
        marginTop: '64px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          marginTop: '64px',
          padding: '10px 20px',
          backgroundColor: '#860433'
        },
      }}
      anchor={'top'}
      open={state}
      onClose={toggleDrawer('top', false)}
    >
      <Navigation open={open} handleDrawerClose={handleDrawerClose}/>
      <Divider />
      <SettingNav open={open} handleDrawerClose={handleDrawerClose}/>
    </Drawer>
  );
}