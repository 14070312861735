import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Avatar, Box, styled} from "@mui/material";
import React, {useEffect, useState} from 'react'

import NarratorImg from 'assets/images/narrator.png'

import {CharacterPlayer} from "../../features/narrator/components/CharacterPlayer";
import {useService as narratorService} from "../../features/narrator/hooks";

const NarratorPanel = styled(Box)({
  height: '90vh',
  width: '95vw',
  borderRadius: '5px',
  position: 'fixed',
  bottom: '20px',
  left: '20px',
  zIndex: '999999',
  margin: ' 5px',
  cursor: 'grab',
  background: 'rgba(238,237,237,0.95)',
  // backdropFilter: 'blur(200px)',
  border: '2px solid #bf5555',
  "@media (max-width: 768px)": {
    width: '90%',
    left: 0,
    right: 0,
  }
})

const NarratorOpenIcon = styled(Box)({
  position: 'fixed',
  bottom: '20px',
  left: '20px',
  zIndex: '999999',
  margin: ' 5px',
  cursor: 'pointer',
})

export const Narrator = () => {
  const {deleteMessage} = narratorService()

  const [open, setOpen] = useState(false)
  const openChat = () => {
    deleteMessage()
    setOpen(true)
  }
  return (
    <>
      {
        open ?
          <NarratorPanel>
            <HighlightOffIcon
              sx={{position: 'absolute',
                fontSize: '40px',
                right: '0px',
                margin: '10px',
                color: '#4b0000', cursor: 'pointer'}}
              onClick={() => {
                setOpen(false)
              }}></HighlightOffIcon>
            <CharacterPlayer/>
          </NarratorPanel>
          :
          <NarratorOpenIcon>
            <Avatar
              sx={{ bgcolor: "white", width: 60, height: 60, border: '2px solid grey'}}
              alt="Raja"
              onClick={() => {openChat()}}
              src={NarratorImg} />
          </NarratorOpenIcon>
      }
    </>
  )
}