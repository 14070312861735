import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Grid} from '@mui/material';
import React, {useMemo, useEffect} from "react";
import {useForm} from "react-hook-form";
import * as Yup from 'yup';

import useAccountsService from "features/accounts/hooks/useAccountsService";
import {Filter, FilterInput, TotalSpend} from "features/accounts/types";
import {FormCheckboxField} from "libs/ui/components/FormCheckField";
import FormChipInputField from "libs/ui/components/FormChipInputField";
import {FormMultiSelectField} from "libs/ui/components/FormMultiSelectField";
import {FormSelectField} from "libs/ui/components/FormSelectField";
import { FormTextField } from 'libs/ui/components/FormTextField';


export type AddFeedFormProps = {
  onSubmit: (data: FilterInput) => void
  data?: Filter
}

export const AddFeedForm = ({ onSubmit, data }: AddFeedFormProps) => {

  const defaultValues = useMemo(() => ({
    id: data?.id,
    search_key: data?.search_key || '',
    exclude_key: data?.exclude_key || '',
    fixed_min: data?.fixed_min?.toString() || '',
    fixed_max: data?.fixed_max?.toString() || '',
    hour_min: data?.hour_min?.toString() || '',
    hour_max: data?.hour_max?.toString() || '',
    h_fixed_min: data?.h_fixed_min?.toString() || '',
    h_fixed_max: data?.h_fixed_max?.toString() || '',
    h_hour_min: data?.h_hour_min?.toString() || '',
    h_hour_max: data?.h_hour_max?.toString() || '',
    ave_rate_min: data?.ave_rate_min?.toString() || '',
    ave_rate_max: data?.ave_rate_max?.toString() || '',
    hire_rate_min: data?.hire_rate_min?.toString() || '',
    hire_rate_max: data?.hire_rate_max?.toString() || '',
    payment_verified: data?.payment_verified  || false,
    total_spend: data?.total_spend?.toString() || '0',
    fixed_job_filter: data?.fixed_job_filter || false,
    hourly_job_filter: data?.hourly_job_filter || false,
    h_fixed_job_filter: data?.h_fixed_job_filter || false,
    h_hourly_job_filter: data?.h_hourly_job_filter || false,
    ave_rate_filter: data?.ave_rate_filter || false,
    hire_rate_filter: data?.hire_rate_filter || false,
    exclude_countries: data ? data.exclude_countries.map(item => item.id) : []
  }), [data])
  const validationSchema = Yup.object().shape({
    search_key: Yup.string(),
    exclude_key: Yup.string(),
    fixed_min: Yup.string(),
    fixed_max: Yup.string(),
    hour_min: Yup.string(),
    hour_max: Yup.string(),
    h_fixed_min: Yup.string(),
    h_fixed_max: Yup.string(),
    h_hour_min: Yup.string(),
    h_hour_max: Yup.string(),
    ave_rate_min: Yup.string(),
    ave_rate_max: Yup.string(),
    hire_rate_min: Yup.string(),
    hire_rate_max: Yup.string(),
    total_spend: Yup.string(),
    payment_verified: Yup.boolean(),
    fixed_job_filter: Yup.boolean(),
    hourly_job_filter: Yup.boolean(),
    h_fixed_job_filter: Yup.boolean(),
    h_hourly_job_filter: Yup.boolean(),
    ave_rate_filter: Yup.boolean(),
    hire_rate_filter: Yup.boolean(),
    exclude_countries: Yup.array(),
  })

  const methods = useForm<FilterInput>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, control, watch, setValue } = methods

  const fixedMin = watch("fixed_min");
  const fixedMax = watch("fixed_max");
  useEffect(() => {
    if (Number.isNaN(Number(fixedMin))) {
      setValue("fixed_min", '');
    }
    if (Number.isNaN(Number(fixedMax))) {
      setValue("fixed_max", '');
    }
    if (fixedMin || fixedMax) {
      setValue("fixed_job_filter", true);
    }
  }, [fixedMin, fixedMax, setValue]);

  const fixedJobFilter = watch("fixed_job_filter");

  useEffect(() => {
    if (fixedJobFilter===false) {
      setValue("fixed_min", '');
      setValue("fixed_max", '');
    }
  }, [fixedJobFilter, setValue]);

  const hourMin = watch("hour_min");
  const hourMax = watch("hour_max");
  useEffect(() => {
    if (Number.isNaN(Number(hourMin))) {
      setValue("hour_min", '');
    }
    if (Number.isNaN(Number(hourMax))) {
      setValue("hour_max", '');
    }
    if (hourMin || hourMax) {
      setValue("hourly_job_filter", true);
    }
  }, [hourMin, hourMax, setValue]);

  const hourJobFilter = watch("hourly_job_filter");

  useEffect(() => {
    if (hourJobFilter===false) {
      setValue("hour_min", '');
      setValue("hour_max", '');
    }
  }, [hourJobFilter, setValue]);

  const aveRateMin = watch("ave_rate_min");
  const aveRateMax = watch("ave_rate_max");
  useEffect(() => {
    if (Number.isNaN(Number(aveRateMin))) {
      setValue("ave_rate_min", '');
    }
    if (Number.isNaN(Number(aveRateMax))) {
      setValue("ave_rate_max", '');
    }
    if (aveRateMin || aveRateMax) {
      setValue("ave_rate_filter", true);
    }
  }, [aveRateMin, aveRateMax, setValue]);

  const aveRateFilter = watch("ave_rate_filter");

  useEffect(() => {
    if (aveRateFilter===false) {
      setValue("ave_rate_min", '');
      setValue("ave_rate_max", '');
    }
  }, [aveRateFilter, setValue]);


  const hireRateMin = watch("hire_rate_min");
  const hireRateMax = watch("hire_rate_max");
  useEffect(() => {
    if (Number.isNaN(Number(hireRateMin))) {
      setValue("hire_rate_min", '');
    }
    if (Number.isNaN(Number(hireRateMax))) {
      setValue("hire_rate_max", '');
    }
    if (hireRateMin || hireRateMax) {
      setValue("hire_rate_filter", true);
    }
  }, [hireRateMin, hireRateMax, setValue]);

  const hireRateFilter = watch("hire_rate_filter");

  useEffect(() => {
    if (hireRateFilter===false) {
      setValue("hire_rate_min", '');
      setValue("hire_rate_max", '');
    }
  }, [hireRateFilter, setValue]);


  const hFixedMin = watch("h_fixed_min");
  const fFixedMax = watch("h_fixed_max");
  useEffect(() => {
    if (Number.isNaN(Number(hFixedMin))) {
      setValue("h_fixed_min", '');
    }
    if (Number.isNaN(Number(fFixedMax))) {
      setValue("h_fixed_max", '');
    }
    if (hFixedMin || fFixedMax) {
      console.log("~~~~~~~~~~~~~")
      setValue("h_fixed_job_filter", true);
    }
  }, [hFixedMin, fFixedMax, setValue]);

  const fFixedJobFilter = watch("h_fixed_job_filter");

  useEffect(() => {
    if (fFixedJobFilter===false) {
      setValue("h_fixed_min", '');
      setValue("h_fixed_max", '');
    }
  }, [fFixedJobFilter, setValue]);

  const hHourMin = watch("h_hour_min");
  const hHourMax = watch("h_hour_max");
  useEffect(() => {
    if (Number.isNaN(Number(hHourMin))) {
      setValue("h_hour_min", '');
    }
    if (Number.isNaN(Number(hHourMax))) {
      setValue("h_hour_max", '');
    }
    if (hHourMin || hHourMax) {
      setValue("h_hourly_job_filter", true);
    }
  }, [hHourMin, hHourMax, setValue]);

  const hHourJobFilter = watch("h_hourly_job_filter");

  useEffect(() => {
    if (hHourJobFilter===false) {
      setValue("h_hour_min", '');
      setValue("h_hour_max", '');
    }
  }, [hHourJobFilter, setValue]);


  const { accounts: {countries} } = useAccountsService()
  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <FormChipInputField name={'search_key'} control={control} label={'Search Key (ex: "php&react" equals php and react)'}/>
      </Grid>
      <Grid item xs={12}>
        <FormChipInputField name={'exclude_key'} control={control} label={'Exclude Key'}/>
      </Grid>
      <Grid item container >
        <Grid item xs={12}>
          <FormCheckboxField
            name={'fixed_job_filter'}
            label={'Fixed job filter'}
            control={control}/>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name='fixed_min'
            label={'Min'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={4}>
          <FormTextField
            name='fixed_max'
            label={'Max'}
            control={control}
            size={'small'}
            type={'text'}
          />
        </Grid>
      </Grid>

      <Grid item container >
        <Grid item xs={12}>
          <FormCheckboxField
            name={'hourly_job_filter'}
            label={'Hourly job filter'}
            control={control}/>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name='hour_min'
            label={'Min'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={4}>
          <FormTextField
            name='hour_max'
            label={'Max'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={12}>
          <FormCheckboxField
            name={'ave_rate_filter'}
            label={'AveRate Filter'}
            control={control}/>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name='ave_rate_min'
            label={'Min'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={4}>
          <FormTextField
            name='ave_rate_max'
            label={'Max'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={12}>
          <FormCheckboxField
            name={'hire_rate_filter'}
            label={'Hire Rate Filter (%)'}
            control={control}/>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name='hire_rate_min'
            label={'Min'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={4}>
          <FormTextField
            name='hire_rate_max'
            label={'Max'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} mt={2}>
        <FormMultiSelectField
          name='exclude_countries'
          label={'Exclude countries'}
          control={control}
          size={'small'}
          items={countries?.map(item => ({ text: item.name, value: item.id }))}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSelectField
          name='total_spend'
          label={'Total Spend'}
          control={control}
          items={Object.entries(TotalSpend).map((item) => ({ text:item[1], value: item[0] }))}
        />
      </Grid>
      <Grid item xs={12}>
        <FormCheckboxField
          name={'payment_verified'}
          label={'Payment Verified'}
          control={control}/>
      </Grid>

      <Grid item container >
        <Grid item xs={12}>
          <FormCheckboxField
            description={'Check each job work history for any hourly jobs that match a specific range of hourly rates'}
            name={'h_hourly_job_filter'}
            label={'Hourly Job Search in History'}
            control={control}/>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name='h_hour_min'
            label={'Min'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={4}>
          <FormTextField
            name='h_hour_max'
            label={'Max'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
      </Grid>

      <Grid item container >
        <Grid item xs={12}>
          <FormCheckboxField
            description={'Check each job work history for any fixed jobs that match a specific range of fixed rates'}
            name={'h_fixed_job_filter'}
            label={'Fixed Job Search in History'}
            control={control}/>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name='h_fixed_min'
            label={'Min'}
            size={'small'}
            control={control}
            type={'text'}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={4}>
          <FormTextField
            name='h_fixed_max'
            label={'Max'}
            control={control}
            size={'small'}
            type={'text'}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="flex-end">
        <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>{data?.id ? 'Update' : 'Create'}</Button>
      </Grid>
    </Grid>
  )
}
