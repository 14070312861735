// DUCKS pattern
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

import {
  Feed,
  FeedDeleteParam,
  FeedRequestParam,
  FeedUpdateParam,
  Proposal,
  History,
  ProposalInput, HistoryResponse, Plot, Job
} from "../types";

export interface JobsState {
  feeds: Feed[]
  histories: History[]
  plot: Plot[]
  totalHours: number
  loading: boolean
  historyLoading: boolean
  updatingProposal: number[]
  updatingSummary: number[]
  error: unknown | undefined
  page: number
  openHistoryJobId: number
  selectedJob: Job | undefined
  lastItem: string,
  furtherMore: boolean,
  pageSize: number
}

const initialState: JobsState = {
  feeds: [],
  histories: [],
  plot: [],
  totalHours: 1,
  loading: false,
  historyLoading: false,
  updatingProposal: [],
  updatingSummary: [],
  error: undefined,
  furtherMore: false,
  openHistoryJobId: 0,
  selectedJob: undefined,
  page: 0,
  lastItem: '0',
  pageSize: 15,
}

// slice
export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    // getFeed
    getFeedsStart(state) {
      state.loading = true
      state.error = undefined
    },
    getStart(state) {
      state.loading = true
      state.error = undefined
    },
    getFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
    getFeedsSuccess(state, action: PayloadAction<{feedResponse: Feed[], type: string | undefined}>) {
      const {feedResponse} = action.payload
      state.feeds = state.feeds.concat(feedResponse)
      state.loading = false
      state.error = undefined
      state.furtherMore = feedResponse.length > 0
      if (!state.furtherMore)
        return
      if (action.payload.type === 'feed') {
        state.lastItem = feedResponse[feedResponse.length - 1].created
      } else {
        state.lastItem = feedResponse[feedResponse.length - 1].updated
      }
    },
    getNewFeedsSuccess(state, action: PayloadAction<{feedResponse: Feed[]}>) {
      const {feedResponse} = action.payload
      if (state.feeds.length === 0 && feedResponse.length > 0)
        state.lastItem = feedResponse[feedResponse.length - 1].created
      state.feeds = feedResponse.concat(state.feeds)
    },
    getFeedsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.historyLoading = false
      state.error = action.payload
    },
    getHistoryStart(state) {
      state.historyLoading = true
    },
    updateProposalStart(state, action: PayloadAction<number>) {
      state.updatingProposal.push(action.payload)
      state.error = undefined
    },
    updateSummaryStart(state, action: PayloadAction<number>) {
      state.updatingSummary.push(action.payload)
      state.error = undefined
    },
    updateFeedSuccess(state, action: PayloadAction<{feedId: number, feed: Feed}>) {
      state.feeds = state.feeds.map((feed) => {
        if (feed.id === action.payload.feedId) {
          return action.payload.feed
        }
        return feed
      });
      state.loading = false
      state.error = undefined
    },
    deleteFeedSuccess(state, action: PayloadAction<{feedId: number}>) {
      state.feeds  = state.feeds.filter((item) => item.id !== action.payload.feedId)
      state.loading = false
      state.error = undefined
    },
    getHistorySuccess(state, action: PayloadAction<{history: HistoryResponse}>) {
      state.histories  = action.payload.history.data
      state.plot  = action.payload.history.plot
      state.totalHours  = action.payload.history.total_hours
      state.historyLoading = false
    },
    updateProposalSuccess(state, action: PayloadAction<{feedId: number, proposal: Proposal}>) {
      state.feeds = state.feeds.map((feed) => {
        if (feed.id === action.payload.feedId) {
          feed.proposal = action.payload.proposal
        }
        return feed
      });
      state.updatingProposal = state.updatingProposal.filter((item) => item !==action.payload.feedId)
    },
    updateGPTProposalSuccess(state, action: PayloadAction<{feedId: number, proposal: string}>) {
      state.feeds = state.feeds.map((feed) => {
        if (feed.id === action.payload.feedId) {
          feed.proposal.cover = action.payload.proposal
        }
        return feed
      });
      state.updatingProposal = state.updatingProposal.filter((item) => item !==action.payload.feedId)
    },
    updateGPTQuestionSuccess(state, action: PayloadAction<{feedId: number, index: number, proposal: string}>) {
      state.feeds = state.feeds.map((feed) => {
        if (feed.id === action.payload.feedId) {
          if (!feed.proposal.answers) {
            feed.proposal.answers = {}
          }
          feed.proposal.answers[`${action.payload.index}`] = action.payload.proposal
        }
        return feed
      });
      state.updatingProposal = state.updatingProposal.filter((item) => item !==action.payload.feedId)
    },
    updateGPTSummarySuccess(state, action: PayloadAction<{feedId: number, text: string}>) {
      state.feeds = state.feeds.map((feed) => {
        if (feed.id === action.payload.feedId) {
          feed.job.summary = action.payload.text
        }
        return feed
      });
      state.updatingSummary = state.updatingSummary.filter((item) => item !==action.payload.feedId)
    },
    updateGPTFailed(state, action: PayloadAction<{feedId: number}>) {
      state.updatingProposal = state.updatingProposal.filter((item) => item !==action.payload.feedId)
      state.updatingSummary = state.updatingSummary.filter((item) => item !==action.payload.feedId)
    },
    createProposalSuccess(state, action: PayloadAction<{feedId: number, feed: Feed}>) {
      state.feeds = state.feeds.map((feed) => {
        if (feed.id === action.payload.feedId) {
          feed = action.payload.feed
        }
        return feed
      });
      state.updatingProposal = state.updatingProposal.filter((item) => item !==action.payload.feedId)
      state.error = undefined
    },
    updateProposalFailure(state, action: PayloadAction<number>) {
      state.loading = false
      state.updatingProposal = state.updatingProposal.filter(p => p !== action.payload)
      state.error = action.payload
    },
    emptyFeeds(state) {
      state.feeds = []
    },
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    openHistory(state, action: PayloadAction<{jobId: number, job: Job | undefined}>) {
      state.openHistoryJobId = action.payload.jobId
      state.selectedJob = action.payload.job
      if (action.payload.jobId === 0) {
        state.histories = []
      }
    },
    formatFeed(state) {
      state.feeds = []
      state.page = 1
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload
    },
  },
})

// Actions
export const jobsActions = {
  getStart: jobsSlice.actions.getStart,
  getFailure: jobsSlice.actions.getFailure,
  getFeedsStart: jobsSlice.actions.getFeedsStart,
  getFeedsSuccess: jobsSlice.actions.getFeedsSuccess,
  getHistorySuccess: jobsSlice.actions.getHistorySuccess,
  getNewFeedsSuccess: jobsSlice.actions.getNewFeedsSuccess,
  getFeedsFailure: jobsSlice.actions.getFeedsFailure,
  getHistoryStart: jobsSlice.actions.getHistoryStart,
  updateProposalStart: jobsSlice.actions.updateProposalStart,
  updateSummaryStart: jobsSlice.actions.updateSummaryStart,
  getFeeds: createAction(`${jobsSlice.name}/getFeeds`, (param: FeedRequestParam) => ({
    payload: {param}
  })),
  getNewFeeds: createAction(`${jobsSlice.name}/getNewFeeds`, (param: FeedRequestParam) => ({
    payload: {param}
  })),
  updateFeed: createAction(`${jobsSlice.name}/updateFeed`, (param: FeedUpdateParam) => ({
    payload: {param}
  })),
  deleteFeed: createAction(`${jobsSlice.name}/deleteFeed`, (param: FeedDeleteParam) => ({
    payload: {param}
  })),
  updateFeedSuccess: jobsSlice.actions.updateFeedSuccess,
  updateProposalState: jobsSlice.actions.updateProposalStart,
  deleteFeedSuccess: jobsSlice.actions.deleteFeedSuccess,
  createProposal: createAction(`${jobsSlice.name}/createProposal`, (feedId: number, data: ProposalInput) => ({
    payload: {feedId, data}
  })),
  updateProposal: createAction(`${jobsSlice.name}/updateProposal`, (feedId: number, id: number, data: ProposalInput) => ({
    payload: {feedId, id, data}
  })),
  generateProposal: createAction(`${jobsSlice.name}/generateProposal`, (feedId: number) => ({
    payload: {feedId}
  })),
  updateGptProposal: createAction(`${jobsSlice.name}/updateGptProposal`, (feedId: number, data: string) => ({
    payload: {feedId, data}
  })),
  updateGptSummary: createAction(`${jobsSlice.name}/updateGptSummary`, (feedId: number, data: string) => ({
    payload: {feedId, data}
  })),
  updateGptQuestion: createAction(`${jobsSlice.name}/updateGptQuestion`, (feedId: number, index: number,  data: string) => ({
    payload: {feedId, index, data}
  })),
  updateGptFailed: createAction(`${jobsSlice.name}/updateGptFailed`, (feedId: number, errorMsg: string) => ({
    payload: {feedId, errorMsg}
  })),
  updateProposalSuccess: jobsSlice.actions.updateProposalSuccess,
  updateGPTProposalSuccess: jobsSlice.actions.updateGPTProposalSuccess,
  updateGPTQuestionSuccess: jobsSlice.actions.updateGPTQuestionSuccess,
  updateGPTSummarySuccess: jobsSlice.actions.updateGPTSummarySuccess,
  updateGPTFailed: jobsSlice.actions.updateGPTFailed,
  createProposalSuccess: jobsSlice.actions.createProposalSuccess,
  updateProposalFailure: jobsSlice.actions.updateProposalFailure,
  emptyFeeds: jobsSlice.actions.emptyFeeds,
  changePage: jobsSlice.actions.changePage,
  getWorkingHistory: createAction(`${jobsSlice.name}/getWorkingHistory`, (jobId: number) => ({
    payload: {jobId}
  })),
  moveFeedsToTrash: createAction(`${jobsSlice.name}/moveFeedsToTrash`, (accountId: number, type: string) => ({
    payload: {accountId, type}
  })),
  emptyTrash: createAction(`${jobsSlice.name}/emptyTrash`, (accountId: number, type: string) => ({
    payload: {accountId, type}
  })),
  openHistory: jobsSlice.actions.openHistory,
  formatFeed: jobsSlice.actions.formatFeed,
  changePageSize: jobsSlice.actions.changePageSize,
}

// Selectors
export const selectJobs = (state: RootState) => state.job

// Reducer
export default jobsSlice.reducer
