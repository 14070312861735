// DUCKS pattern
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

import {Job} from "../../jobs/types";
import {ChatBotMessageResponse, Message} from "../types";


export interface State {
  jobs: Job[]
  messages: Message[],
  loading: boolean
}

const initialState: State = {
  jobs: [],
  messages: [{content: 'Hi, I can help you to find job', user: false}],
  loading: false
}

// slice
export const slice = createSlice({
  name: 'narrator',
  initialState,
  reducers: {
    getChatMessageStart(state, action: PayloadAction<string>) {
      state.messages.push({content: action.payload, user: true});
      state.loading = true
    },
    getChatMessageEnd(state) {
      state.loading = false
    },
    getChatMessageSuccess(state, action: PayloadAction<ChatBotMessageResponse>) {
      state.messages.push({content: action.payload.message, user: false});
      state.jobs = action.payload.jobs;
    },
    clearChatMessage(state) {
      state.messages = [{content: 'Hi, I can help you to find job', user: false}]
      state.jobs = []
    },
  },
})

// Actions
export const actions = {
  getChatMessageStart: slice.actions.getChatMessageStart,
  getChatMessageEnd: slice.actions.getChatMessageEnd,
  getChatMessageSuccess: slice.actions.getChatMessageSuccess,
  clearChatMessageSuccess: slice.actions.clearChatMessage,
  getChatMessage: createAction(`${slice.name}/getChatMessage`, (message: string, timezone: string) => ({
    payload: {message, timezone}
  })),
  deleteMessage: createAction(`${slice.name}/deleteMessage`, () => ({
    payload: {}
  })),
}

// Selectors
export const selectState = (state: RootState) => state.narrator

// Reducer
export default slice.reducer
