import {Chip} from "@mui/material";
import Grid from "@mui/material/Grid";
import React from 'react'

import {Job} from "features/jobs/types";

import {FeatureItem} from "../featureItem";


export type PphFeaturesProps = {
  job: Job | undefined
}

export const PphFeatures = ({job,}: PphFeaturesProps) => <>
  <Grid item xs={12} container paddingBottom={2}>
    {
      job?.skills.split(',').map((skill, index) =>
        <Chip sx={{marginRight: '3px', marginBottom: '3px'}} key={index} size={'small'} label={skill} variant="outlined" />
      )
    }
  </Grid>
  <Grid item xs={12} container>
    <Grid item xs={12}>
      <FeatureItem label={'Price'} value={`${job?.price}`}/>
    </Grid>
    <Grid item xs={12} sm={4}>
      <FeatureItem label={'Country'} value={`${job?.country}`}/>
    </Grid>
    <Grid item xs={12} sm={4}>
      <FeatureItem label={'Total Post'} value={`${job?.total_posted_job_count} posts`}/>
    </Grid>
    {
      job?.hire_rate && <Grid item xs={12} sm={4}>
        <FeatureItem label={'Hire Rate'} value={`${job?.hire_rate}`}/>
      </Grid>
    }
  </Grid>
</>

