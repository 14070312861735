import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react'

import {Bot} from "features/bot/types";

import {EditAboutDialog} from "../aboutTab/editAboutDialog";


export type BotFieldProps = {
  value?: Bot
  disableEdit?: boolean
}

export const BotField = ({value, disableEdit=false}: BotFieldProps) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Paper
        sx={{
          px: 2,
          py: 1,
          width: '90%',
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        }}
      >
        <Grid item px={2} xs={12} sm container>
          {
            value ? <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                <Typography sx={{fontWeight: "bold"}} gutterBottom variant="subtitle1" component="div">
                  Bot Name
                </Typography>
                <Typography sx={{color: '#5e5e5e'}} variant="body2" gutterBottom>
                  {value?.name}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography sx={{fontWeight: "bold"}} gutterBottom variant="subtitle1" component="div">
                  Bot Key
                </Typography>
                <Typography sx={{color: '#5e5e5e'}} variant="body2" gutterBottom>
                  {value?.id}
                </Typography>
              </Grid>
            </Grid>
              :
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  No Bot Selected
                </Typography>
              </Grid>
          }

          {
            !disableEdit &&
              <Grid item>
                <Button onClick={() => {setOpen(true)}} size="small" color={"secondary"}>Edit</Button>
              </Grid>
          }
        </Grid>
      </Paper>
      <EditAboutDialog openDialog={open} label={'Bot'} closeDialog={() => {setOpen(false)}}/>
    </>
  )
}
