import {Checkbox} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useConfirm } from 'material-ui-confirm'
import React from 'react'

import {useAccountsService} from "../../../hooks/useAccountsService";


export type AccountFieldProps = {
  label: string
  description?: string | number
  checked?: boolean
  keyValue: 'id' | 'name' | 'description' | 'add_proposal' | 'add_summary' | 'add_history' | 'auto_bid' | 'send_notification'
  disableEdit?: boolean
}

export const AccountCheckBoxField = ({label, description, checked, keyValue, disableEdit=false}: AccountFieldProps) => {
  const {updateAccount, accounts: {account}} = useAccountsService()
  const confirm = useConfirm()
  const onSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (keyValue === 'auto_bid' && (!account?.prompt || !account?.prompt.prompt)) {
      confirm({ title: `Please complete prompt setting before auto bid` }).then()
      return
    }
    updateAccount({id: account?.id, [keyValue]: event.target.checked})
  }

  return (
    <Paper
      sx={{
        py: 1,
        px: 2,
        width: '90%',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid item px={2} xs={12} sm container>
        <Grid item xs container direction="column">
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  name={keyValue}
                  disabled={disableEdit}
                  checked={checked || false}
                  onChange={onSubmit}
                  color={'secondary'}/>
              }
              label={label} />
            <Typography sx={{color: '#5e5e5e'}} variant="body2" gutterBottom>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
