import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import React, {useCallback, useEffect, useState} from 'react'


import {AccountForm} from "features/accounts/components/accountForm";
import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {CreateAccountInput, FilterInput} from "features/accounts/types";

import {AddFeedForm} from "./edit/jobFeedTab/addFeed/addFeedForm";

export const AccountContainer = () => {
  const {accounts: {openModal, account}, addAccountAction, createAccount, createFilter} = useAccountsService()

  const [open, setOpen] = useState<boolean>(false)
  const [step, setStep] = useState<string>('account')
  useEffect(() => {
    setOpen(openModal)
  }, [openModal])

  const closeDialog = useCallback(() => {
    setOpen(false)
    addAccountAction(false)
    setStep('account')
  }, [addAccountAction])

  const onSubmit = useCallback((data: CreateAccountInput |  null) => {
    if (data === null) {
      setOpen(false)
      addAccountAction(false)
      return;
    }
    createAccount(data)
    setStep('feed')
  }, [createAccount])

  const onSubmitFeed = useCallback((data: FilterInput) => {

    if (!account?.id) return
    createFilter(account.id, data)
    setStep('proposal')
    setOpen(false)
    addAccountAction(false)
    setStep('account')
  }, [account?.id, createFilter])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={closeDialog}>
      <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
        {'Create a new feed'}
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        { step === 'account' && <AccountForm onSubmit={onSubmit}/> }
        { step === 'feed' && <AddFeedForm onSubmit={onSubmitFeed}/> }
      </DialogContent>
    </Dialog>
  )
}
