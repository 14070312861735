import {yupResolver} from "@hookform/resolvers/yup";
import { Button, Stack } from '@mui/material'
import React, {useMemo} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import * as Yup from 'yup'

import { FormSelectField } from 'libs/ui/components/FormSelectField'
import { FormTextField } from 'libs/ui/components/FormTextField'
import { Spacer } from 'libs/ui/components/Spacer'

import {useAuthService} from "../../auth";
import {Account, CreateAccountInput, Platform, WordLength} from "../types";

export type AccountFormProps = {
  onSubmit: (data: CreateAccountInput | null) => void
}

export const AccountForm = ({ onSubmit }: AccountFormProps) => {
  const {auth: {me}} = useAuthService()
  const navigate = useNavigate()

  const defaultValues = useMemo(() => ({
    name: "",
    description: "",
    hourly_rate: 35,
    platform: 'upwork',
  }), [])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    description: Yup.string(),
    hourly_rate: Yup.number().required('This field is required'),
    platform: Yup.string().required('This field is required'),
  })

  const methods = useForm<CreateAccountInput>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const goSubscription = () => {
    navigate('/membership')
    onSubmit(null)
  }

  const { handleSubmit, control } = methods
  return (
    <>
      {
        me?.subscription ?
          <Stack sx={{ pt: 1 }} direction='column' spacing={1} justifyContent='center'>
            <Spacer height={8} />
            <FormTextField
              name='name'
              label={'Name'}
              control={control}
              placeholder={'e.g: Alexandra'}
            />
            <Spacer height={10} />
            <FormSelectField
              name='platform'
              label={'platform'}
              control={control}
              items={Object.entries(Platform).map((item) => ({ text:item[1], value: item[0] }))}
            />
            <Spacer height={15} />
            <Stack direction='row' spacing={2} alignItems='center' justifyContent={'end'}>
              <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>{'Create'}</Button>
            </Stack>
          </Stack> :
          <Stack sx={{ pt: 1 }} direction='column' spacing={1} justifyContent='center'>
            <Spacer height={8} />
              Before creating a new account, please subscribe to a membership. You can start with our Free Membership, which will allow you to proceed with the account creation. Click here to subscribe and then continue.
            <Spacer height={15} />
            <Stack direction='row' spacing={2} alignItems='center' justifyContent={'end'}>
              <Button onClick={goSubscription} variant={'contained'}>{'Go Subscription'}</Button>
            </Stack>
          </Stack>
      }
    </>
  )
}
