import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useConfirm} from "material-ui-confirm";
import React, {useCallback, useState} from 'react'

import {FilterField} from "features/accounts/components/edit/jobFeedTab/filterField";
import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {Filter} from "features/accounts/types";


export type FilterPanelProps = {
  filter: Filter
  openFilterDialog: (filter: Filter) => void
}

export const FilterPanel = ({filter, openFilterDialog}: FilterPanelProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const confirm = useConfirm()
  const { accounts: {account}, deleteFilter } = useAccountsService()

  const onDeleteFilter = useCallback((filterData: Filter) => {
    if (!account?.id) return
    confirm({ title: `Are you sure to delete filter ${filterData.search_key}?` })
      .then(() => deleteFilter(account.id, filterData.id))
  }, [account?.id, confirm, deleteFilter])

  return (
    <Paper
      sx={{
        px: 2,
        py: 1,
        width: '90%',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid px={2} container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs container direction="column" spacing={1}>
          <Grid item xs>
            <Typography sx={{fontWeight: "bold", width: {xs: '133px', sm: '100%'}, overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom variant="subtitle1" component="div">
              {filter.search_key ? filter.search_key : 'all'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs container
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Button sx={{maxWidth: '27px', minWidth: '27px'}} onClick={() => {openFilterDialog(filter)}} size="small" variant={'outlined'} color={"secondary"}>
            <ModeEditIcon sx={{maxWidth: '27px', fontSize: '15px'}}/>
          </Button>&nbsp;
          <Button sx={{maxWidth: '27px', minWidth: '27px'}} onClick={() => {onDeleteFilter(filter)}} size="small" variant={'outlined'} color={"secondary"}>
            <DeleteIcon sx={{maxWidth: '27px', fontSize: '15px'}}/>
          </Button>
        </Grid>
      </Grid>
      <Grid px={2} item sm container>
        {
          filter.exclude_key && (
            <Grid item xs={12}>
              <FilterField label={'Exclude Key'} value={filter.exclude_key}/>
            </Grid>
          )
        }
        {
          filter.total_spend !== 0 && (
            <Grid item xs={6}>
              <FilterField label={'Total Spend'} value={filter.total_spend ? `$${filter.total_spend}` : ''}/>
            </Grid>
          )
        }
        <Grid item xs={6}>
          <FilterField label={'Payment verified'} value={filter.payment_verified ? 'Yes' : 'Unset'}/>
        </Grid>
        {
          filter.fixed_job_filter && (
            <Grid item xs={6}>
              <FilterField
                label={'Fixed job'}
                value={`${filter.fixed_min ? `$${filter.fixed_min}` : ''} ~ ${filter.fixed_max ? `$${filter.fixed_max}` : ''}`}/>
            </Grid>)
        }
        {
          filter.hourly_job_filter && (
            <Grid item xs={6}>
              <FilterField
                label={'Hourly job'}
                value={`${filter.hour_min ? `$${filter.hour_min}` : ''} ~ ${filter.hour_max ? `$${filter.hour_max}` : ''}`}/>
            </Grid>)
        }
        {
          filter.ave_rate_filter && (
            <Grid item xs={6}>
              <FilterField
                label={'Client AreRating'}
                value={`${filter.ave_rate_min ? `$${filter.ave_rate_min}` : ''} ~ ${filter.ave_rate_max ? `$${filter.ave_rate_max}` : ''}`}/>
            </Grid>)
        }
        {
          filter.exclude_countries.length > 0 && (
            <Grid item xs={12}>
              <FilterField label={'Exclude Countries'} value={
                filter.exclude_countries.map(item => item.name).join(',')
              }/>
            </Grid>
          )
        }
      </Grid>
      <Grid px={2} item sm container>

      </Grid>
    </Paper>
  )
}
