import {Button, Grid, IconButton, styled} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React from 'react'
import {useNavigate} from "react-router-dom";

import { drawerWidth } from 'components/Layout'

import {useAuthService} from "../../features/auth/hooks/useAuthService";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))


const LandingHeader = () => {
  const { auth } = useAuthService()
  const navigate = useNavigate()
  return <AppBar
    position="fixed"
    color='transparent'
    elevation={0}
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      height: '64px',
      background: 'white',
      borderBottom: '1px #e5e5e5 solid'
    }}
  >
    <Toolbar sx={{flexWrap: 'wrap', height: '64px'}}>
      <Grid sx={{width: '70%', margin: 'auto'}} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Grid>
          <Typography
            onClick={() => {window.location.replace('https://lagfast.com')}}
            variant='h6' color='inherit' noWrap sx={{flexGrow: 1, cursor:'pointer'}}>
            <label style={{fontWeight: 'bold', fontSize: '20px', color: '#860433'}}>LagFast</label>
          </Typography>
        </Grid>
        <Grid>
          {!auth.token?.access && <Button onClick={() => {
            navigate('/login')
          }}>Login</Button>}
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
}

export default LandingHeader
