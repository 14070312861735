import { useCallback } from 'react'

import {botActions, BotState, selectBot} from 'features/bot/store'
import {useAppDispatch, useAppSelector} from 'store/hooks'

import {CreateBotInput} from "../types";


export type BotServiceOperators = {
  bot: BotState,
  deleteBot: (id: string) => void
  getBots: () => void
  getMessages: () => void
  createBot: (data: CreateBotInput) => void
  updateBot: (botId: string, data: CreateBotInput) => void
}

export const useBotService = (): Readonly<BotServiceOperators> => {
  const dispatch = useAppDispatch()

  return {
    bot: useAppSelector(selectBot),
    getBots: useCallback(() =>
      dispatch(botActions.getBots()), [dispatch]),
    getMessages: useCallback(() =>
      dispatch(botActions.getMessages()), [dispatch]),
    deleteBot: useCallback((id?: string) =>
      dispatch(botActions.deleteBot(id)), [dispatch]),
    createBot: useCallback((data: CreateBotInput) =>
      dispatch(botActions.createBot(data)), [dispatch]),
    updateBot: useCallback((botId: string, data: CreateBotInput) =>
      dispatch(botActions.updateBot(botId, data)), [dispatch]),
  }
}

export default useBotService
