// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  ActivateInput,
  FCMInput,
  LoginInput,
  ResetPasswordConfirmInput,
  ResetPasswordInput,
  SignUpInput,
  Token, UpdatePasswordInput, UpdateUserNameInput, User
} from 'features/auth/types'
import { storage } from 'libs/core/storage'
import type { RootState } from 'store/store'

export interface AuthState {
  loading: boolean
  error: unknown | undefined
  token: Token | undefined
  fcmToken: string | null
  me: User | undefined
  activate: number
}

const initialState: AuthState = {
  loading: false,
  token: {
    access: storage.getAccessToken(),
    refresh: storage.getRefreshToken(),
  },
  fcmToken: null,
  me: undefined,
  activate: 0,
  error: undefined
}

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // login
    loginStart(state) {
      state.loading = true
      state.token = undefined
      state.error = undefined
    },
    loginSuccess(state, action: PayloadAction<Token>) {
      state.token = action.payload
      state.loading = false
      state.error = undefined
    },
    updateActivateStatus(state, action: PayloadAction<number>) {
      state.activate = action.payload
    },
    loginFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.token = undefined
    },
    // logout
    logoutSuccess(state) {
      state.token = undefined
    },
    // getMe
    getMeStart(state) {
      state.loading = true
      state.me = undefined
      state.error = undefined
    },
    getMeSuccess(state, action: PayloadAction<User>) {
      state.me = action.payload
      state.loading = false
      state.error = undefined
    },
    getMeFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.me = undefined
    },
    setFCMDeviceId(state, action: PayloadAction<string>) {
      state.fcmToken = action.payload
    },
  },
})

// Actions
export const authActions = {
  login: createAction(`${authSlice.name}/login`, (data: LoginInput) => ({
    payload: data,
  })),
  activate: createAction(`${authSlice.name}/activate`, (data: ActivateInput) => ({
    payload: data,
  })),
  signup: createAction(`${authSlice.name}/signup`, (data: SignUpInput) => ({
    payload: data,
  })),
  resetPassword: createAction(`${authSlice.name}/resetPassword`, (data: ResetPasswordInput) => ({
    payload: data,
  })),
  updateName: createAction(`${authSlice.name}/updateName`, (data: UpdateUserNameInput) => ({
    payload: data,
  })),
  updatePassword: createAction(`${authSlice.name}/updatePassword`, (data: UpdatePasswordInput) => ({
    payload: data,
  })),
  resetPasswordConfirm: createAction(`${authSlice.name}/resetPasswordConfirm`, (data: ResetPasswordConfirmInput) => ({
    payload: data,
  })),
  loginStart: authSlice.actions.loginStart,
  loginSuccess: authSlice.actions.loginSuccess,
  updateActivateStatus: authSlice.actions.updateActivateStatus,
  loginFailure: authSlice.actions.loginFailure,

  logout: createAction(`${authSlice.name}/logout`),
  logoutSuccess: authSlice.actions.logoutSuccess,

  getMe: createAction(`${authSlice.name}/getMe`),
  deleteMe: createAction(`${authSlice.name}/deleteMe`),
  getMeStart: authSlice.actions.getMeStart,
  getMeSuccess: authSlice.actions.getMeSuccess,
  getMeFailure: authSlice.actions.getMeFailure,
  setFCMDeviceId: authSlice.actions.setFCMDeviceId,
  pushFCMToken: createAction(`${authSlice.name}/pushFCMToken`, (data: FCMInput) => ({
    payload: data,
  })),

}

// Selectors
export const selectAuth = (state: RootState) => state.auth

// Reducer
export default authSlice.reducer
