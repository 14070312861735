import {Grid, Link} from "@mui/material";
import React from 'react'
import ReactPlayer from "react-player";

import {useAccountsService} from "features/accounts/hooks/useAccountsService";

import {BotField} from "./botField";

export const BotSettingTabContainer = () => {
  const {accounts: {account}} = useAccountsService()

  return (
    <Grid
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={6}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="left"
        spacing={1}
        item
      >
        <Grid container item>
          <BotField value={account?.bot}/>
        </Grid>
        <Grid item>
          <Link
            sx={{fontSize: '14px'}}
            href="https://lagfast.com/attachment/bot.exe"
            target="_blank"
          >
            Download bot app
          </Link>
        </Grid>
      </Grid>
      <Grid item display={'flex'} justifyContent={'center'} mt={4}>
        <ReactPlayer url={`https://www.youtube.com/watch?v=o6WDS-S5Boo`} />
      </Grid>
    </Grid>
  )
}
