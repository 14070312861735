import {Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from 'react'

export type FilterFieldProps = {
  label: string
  value: string
}

export const FilterField = ({label, value}: FilterFieldProps) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Grid item container direction="row" spacing={0}>
      <Typography
        sx={{fontSize: '12px', fontWeight: 'bold', color: "#3b3b3b"}}
        variant={'caption'}
        gutterBottom
        component="div"
      >
        {label}:&nbsp;
      </Typography>
      <Tooltip title={`${label}: ${value}`} arrow>
        <Typography sx={{fontSize: '12px',color: "#3b3b3b"}} gutterBottom component="div">
          {value}
        </Typography>
      </Tooltip>
    </Grid>
  )
}
