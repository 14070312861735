import {Chip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import React from 'react'

import {Job} from "features/jobs/types";

import {useJobsService} from "../../../../hooks";
import {FeatureItem} from "../featureItem";


export type GuruFeaturesProps = {
  job: Job | undefined
}

export const GuruFeatures = ({job,}: GuruFeaturesProps) => {
  const {openHistory} = useJobsService()
  return <>
    <Grid item xs={12} container>
      {
        job?.skills.split(',').map((skill, index) =>
          <Chip sx={{marginRight: '3px', marginBottom: '3px'}} key={index} size={'small'} label={skill} variant="outlined" />
        )
      }
    </Grid>
    <Grid item xs={12} container>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Price'} value={`${job?.price}`}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Country'} value={`${job?.country}`}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Total Post'} value={`${job?.total_posted_job_count} posts`}/>
      </Grid>
      {
        job?.hire_rate && <Grid item xs={12} sm={4}>
          <FeatureItem label={'Hire Rate'} value={`${job?.hire_rate}`}/>
        </Grid>
      }
      {
        job?.total_spent_txt && <Grid item xs={12} sm={4}>
          <FeatureItem label={'Total Spend'} value={`${job?.total_spent_txt || ""}`}/>
        </Grid>
      }
      <Grid item xs={12} sm={4}>
        <Grid item container direction="row" spacing={0}>
          <Typography
            sx={{fontSize: '14px', fontWeight: 'bold', color: "#3b3b3b"}}
            variant={'caption'}
            gutterBottom
            component="div"
          >
            Payment:&nbsp;
          </Typography>
          <Typography sx={{fontSize: '14px',color: "#3b3b3b"}} gutterBottom component="div">
            {job?.payment_verified ?
              <Chip sx={{marginRight: '3px', marginBottom: '3px'}} color={'success'} size={'small'} label={'Verified'} variant="outlined"/> :
              <Chip sx={{marginRight: '3px', marginBottom: '3px'}} size={'small'} label={'Unverified'} variant="outlined"/>
            }
          </Typography>
        </Grid>
      </Grid>

    </Grid>
  </>
}

