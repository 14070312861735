import styled from "@emotion/styled";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";

import FormPasswordField from "../../../libs/ui/components/FormPasswordField";
import {Spacer} from "../../../libs/ui/components/Spacer";
import {useAuthService} from "../hooks/useAuthService";
import {ResetPasswordConfirmInput} from "../types";

const Container = styled.div(() => ({
  width: '100vw',
  height: '100vh',
  background: '#155bd5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const InfoCard = styled.div(() => ({
  width: '400px',
  height: 200,
  background: '#ffffff',
  borderRadius: 20,
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 900px)': {
    width: '90vw',
    minWidth: '90vw',
    padding: '20px',
  },
}))
export const ResetPasswordConfirmContainer = () => {
  const navigate = useNavigate()
  const {uid, token} = useParams();
  const [sent, setSent] = useState(false)
  const {restPasswordConfirm} = useAuthService()

  const validationSchema = Yup.object().shape({
    new_password: Yup.string().required("Password is required!"),
    confirmPwd: Yup.string().oneOf([Yup.ref('new_password'), null], "Password is not matching").required("Confirm Password is required"),
  })

  const methods = useForm<ResetPasswordConfirmInput>({
    defaultValues: { new_password: '', confirmPwd: '', uid, token },
    resolver: yupResolver(validationSchema),
  })
  const onSubmit = async (data: ResetPasswordConfirmInput) => {
    await restPasswordConfirm(data)
    navigate('/login')
  }
  const { handleSubmit, control } = methods
  return (
    <Container>
      <InfoCard>
        <Stack sx={{ pt: 0 }} direction='column' spacing={1} justifyContent='center'>
          <FormPasswordField name='new_password' label={'Password'} control={control} />
          <FormPasswordField name='confirmPwd' label={'Confirm Password'} control={control} />
          <Spacer height={8} />
          <Button sx={{background: '#155bd5'}} onClick={handleSubmit(onSubmit)} variant={'contained'}>{'Reset Password'}</Button>
        </Stack>
      </InfoCard>
    </Container>
  );
}
