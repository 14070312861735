import {CssBaseline, Grid, styled, ThemeProvider} from '@mui/material'
import {createTheme} from '@mui/material/styles'
import {ConfirmProvider} from 'material-ui-confirm'
import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'

import {AppDrawer} from 'components/AppDrawer/desktop'
import {MobileDrawer} from "components/AppDrawer/mobile";
import Header from 'components/Header/Header'
import {AccountContainer} from "features/accounts/components/accountContainer";
import {useCommonService} from 'features/common/hooks'
import {AlertSnackbar} from 'libs/ui/components/AlertSnackbar'

import {AccountEditContainer} from "../../features/accounts/components/edit/accountEditContainer";
import {PrompContainer} from "../../features/accounts/components/promptContainer";
import {useAccountsService} from "../../features/accounts/hooks/useAccountsService";
import {useAuthService} from "../../features/auth";
import {useBotService} from "../../features/bot/hooks/useBotService";
import {Narrator} from "../Narrator/Narrator";


export const drawerWidth = 240

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
  open?: boolean;
}>(({theme, open}) => ({
  paddingTop: 70,
  overflow: 'hidden',
  paddingBottom: 24,
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  palette: {
    primary: {
      main: '#860433',
      light: '#bb2d60',
    },
    secondary: {
      main: '#14892d',
      light: '#14892d',
    },
  },
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}))

const Layout = () => {
  const {auth: {me} } = useAuthService()
  const [open, setOpen] = React.useState(window.innerWidth > 900)
  const [openMobileNav, setMobileOpenNav] = React.useState(false)
  const {accounts: {changeNav}} = useAccountsService()
  const {common: {isMobile}} = useCommonService()
  const { getBots } = useBotService()

  const {common: {alert, error}, showMessage, setMobileView} = useCommonService()
  setMobileView(window.innerWidth <= 900)

  useEffect(() => {
    getBots()
  }, [getBots])

  useEffect(() => {
    if (isMobile) {
      setMobileOpenNav(false)
      setOpen(false)
    }
  }, [isMobile, changeNav])

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMobileDrawerClose = (state: boolean) => {
    setMobileOpenNav(state)
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#860433',
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        contrastText: '#ffcc00',
      },

      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  });

  const handleWindowSizeChange = () => {
    setOpen(window.innerWidth > 900);
    setMobileView(window.innerWidth <= 900)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <ThemeProvider  theme={theme}>
      <ConfirmProvider>
        <CssBaseline/>
        <Header open={open} handleDrawerOpen={handleMobileDrawerClose}/>
        <AppDrawer open={open} handleDrawerClose={handleDrawerClose}/>
        {!isMobile && me?.is_premium && <Narrator/>}
        <Main theme={theme} open={open}>
          <MobileDrawer open={openMobileNav} handleDrawerClose={handleMobileDrawerClose}/>
          <Outlet/>
          <AccountContainer/>
          <PrompContainer/>
          <AccountEditContainer/>
        </Main>
        <AlertSnackbar
          open={alert}
          message={error?.message || ''}
          duration={5000}
          type={'error'}
          vertical={'bottom'}
          callback={() => showMessage(false)}/>
      </ConfirmProvider>
    </ThemeProvider>
  )
}

export default Layout
