import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { pubActions } from 'features/public/store/pub.slice'

import {ApiError} from "../../common/types";
import {jobsActions} from "../../jobs/store";
import {Job, JobResponse} from "../../jobs/types";
import {getJobById} from "../api";


function* onGetJob({
  payload,
}: {
  type: typeof pubActions.getJob
  payload: string
},
): SagaIterator {
  try {
    yield put(pubActions.getJobStart())
    const job: JobResponse = yield call(getJobById, payload)
    yield put(pubActions.getJobSuccess(job.feed))
    yield put(jobsActions.getHistorySuccess({history: job.history}))
  } catch (e) {
    const err = e as ApiError
    yield put(pubActions.getJobError(err))
  }
}

// Watcher Saga
export function* pubWatcherSaga(): SagaIterator {
  yield takeEvery(pubActions.getJob.type, onGetJob)
}

export default pubWatcherSaga
