import { useCallback } from 'react'

import {actions, State, selectState} from 'features/narrator/store'
import {useAppDispatch, useAppSelector} from 'store/hooks'

export type ServiceOperators = {
  state: State,
  getBotMessage: (message: string, timezone: string) => void
  deleteMessage: () => void
}

export const useService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch()

  return {
    state: useAppSelector(selectState),
    getBotMessage: useCallback((message: string, timezone: string) =>
      dispatch(actions.getChatMessage(message, timezone)), [dispatch]),
    deleteMessage: useCallback(() =>
      dispatch(actions.deleteMessage()), [dispatch]),
  }
}

export default useService
