// DUCKS pattern
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'


import type { RootState } from 'store/store'

import {Plan} from "../types";


export interface PaymentState {
  plans: Plan[]
  plan: Plan | null
  openModal: boolean
  loading: boolean
  error: unknown | undefined
}

const initialState: PaymentState = {
  plans: [],
  plan: null,
  openModal: false,
  loading: false,
  error: undefined,
}

// slice
export const paymentSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    getPlansSuccess(state, action: PayloadAction<Plan[]>) {
      state.plans = action.payload
      state.loading = false
      state.error = undefined
    },
    setPlansSuccess(state, action: PayloadAction<Plan>) {
      state.plan = action.payload
      state.openModal = true
    },
    cleanPlansSuccess(state) {
      state.plan = null
      state.openModal = false
    },
  },
})

// Actions
export const paymentActions = {
  getPlansSuccess: paymentSlice.actions.getPlansSuccess,
  setPlan: paymentSlice.actions.setPlansSuccess,
  cleanPlan: paymentSlice.actions.cleanPlansSuccess,
  getPlans: createAction(`${paymentSlice.name}/getPlans`, () => ({
    payload: {}
  }))
}

// Selectors
export const selectPayment = (state: RootState) => state.payment

// Reducer
export default paymentSlice.reducer
