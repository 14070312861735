import {OnApproveData} from "@paypal/paypal-js/types/components/buttons";

import { api } from 'features/api'

import {Order} from "../types";

export const getPlans = () =>
  api.get(`/api/plans/`);


export const getPlanOrder = (planId: number): Promise<Order> =>
  api.get(`/api/plans/${planId}/order`);

export const completeOrder = (planId: number, data: OnApproveData): Promise<Order> =>
  api.post(`/api/plans/${planId}/complete_order/`, data);

export const setFreePlan = (planId: number): Promise<Order> =>
  api.post(`/api/plans/${planId}/set_free_plan/`);