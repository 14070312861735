import styled from "@emotion/styled";
import {Divider, Grid, Typography} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";

import TitleImg from "../../../assets/images/logo.png";
import {useAuthService} from "../hooks/useAuthService";

const Container = styled.div(() => ({
  width: '100vw',
  height: '100vh',
  background: '#155bd5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const InfoCard = styled.div(() => ({
  width: '500px',
  minWidth: '500px',
  minHeight: '180px',
  background: '#7FDCA4',
  borderRadius: 20,
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 900px)': {
    width: '90vw',
    minWidth: '90vw',
    padding: '20px',
  },
}))
export const ActivateContainer = () => {
  const navigate = useNavigate()
  const {uid, token} = useParams();
  const {activateAccount, auth: {activate}} = useAuthService()
  useEffect(() => {
    if (!uid || !token) {
      return
    }
    activateAccount({uid, token})
  }, [activateAccount, token, uid])

  useEffect(() => {
    if (activate === 1) {
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    }
  }, [activate, navigate])
  const color = (activity: number) => {
    if (activity === 0) {
      return '#ffffff'
    }
    if (activity === 1) {
      return '#7FDCA4'
    }
    return '#ff8c8c'
  }
  return (
    <Container>
      <InfoCard style={{background: color(activate)}}>
        <Grid
          pb={2}
          sx={{
            flexDirection: {
              xs: 'row',
              md: 'column',
            },
          }} display={'flex'} justifyContent={'center'} container>
          <Grid item md={12} display={'flex'} flexDirection={'column'} justifyContent="center" alignItems="start">
            <Typography variant="h5" component="h5" sx={{fontWeight: '700'}}>
              {activate === 0 && 'Checking ...'}
              {activate === 1 && 'Congratulations !'}
              {activate === 2 && 'Something went wrong !'}
            </Typography>
            <Typography component="div">
              { activate === 0 && 'Please wait until active your account.'}
              { activate === 1 && 'You’re account has been successfully activated.\n' +
              'You’re now ready to start finding more global opportunities from within lagfast.\n' +
              'If you’re not redirected automatically, please click here.'}
              {activate === 2 && 'Our team is reviewing and verifying your account.\n' +
              'We will get back to you within 8 hours with your account verification and next steps.\n' +
              'We apologize for any inconvenience.'}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{background: 'black'}} />
        <Grid pt={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} container>
          <Grid item xs={8} display={'flex'} justifyItems={'left'} justifyContent={'left'}>
            <Typography sx={{fontSize: '14px'}} component="div">
              Need help? support@lagfast.com
            </Typography>
          </Grid>
          <Grid item xs={2} display={'flex'} justifyContent={'start'}>
            <img className="icon-size" height={'20px'} src={TitleImg} alt=""/>
          </Grid>
        </Grid>
      </InfoCard>
    </Container>
  );
}
