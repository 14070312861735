import {Box, CssBaseline, styled, ThemeProvider} from '@mui/material'
import {createTheme} from '@mui/material/styles'
import React, {useEffect, useMemo} from 'react'
import {Navigate, Outlet} from 'react-router-dom'

import {useAuthService} from "../../features/auth";
import {useGptService} from "../../features/chatGPT/hooks";
import {storage} from "../../libs/core/storage";
import {Loader} from "../../libs/ui/components/Loader";
import LandingHeader from "../Header/LandingHeader";

import Layout from "./index";


export const drawerWidth = 240

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
  open?: boolean;
}>(({theme, open}) => ({
  paddingTop: 70,
  overflow: 'hidden',
  paddingBottom: 24,
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  palette: {
    primary: {
      main: '#860433',
      light: '#bb2d60',
    },
    secondary: {
      main: '#14892d',
      light: '#14892d',
    },
  },
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}))
const Container = styled(Box)({
  height: 'calc(100vh - 125px)', // Using calc in CSS
  overflowY: 'auto',
  width: '100%',
})


const LandingLayout = () => {
  const { auth, getMe } = useAuthService()
  const canAutoLogin = !!storage.getAccessToken()
  const isAuthenticated = useMemo<boolean>(() => !!auth.me, [auth.me])

  useEffect(() => {
    if (canAutoLogin) {
      getMe();
    }
  },[canAutoLogin, getMe])

  
  const theme = createTheme({
    palette: {
      primary: {
        main: '#860433',
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        contrastText: '#ffcc00',
      },

      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  });
  if (isAuthenticated) {
    return (
      <ThemeProvider  theme={theme}>
        <CssBaseline/>
        <LandingHeader/>
        <Main theme={theme}>
          <Container>
            <Outlet/>
          </Container>
        </Main>
      </ThemeProvider>
    )
  }

  if (canAutoLogin) {
    return <Loader />;
  }

  return <Navigate to="/login" replace />;
}

export default LandingLayout
