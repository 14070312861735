import { useCallback } from 'react'

import {accountsActions, AccountsState, selectAccounts} from 'features/accounts/store'
import {useAppDispatch, useAppSelector} from 'store/hooks'

import {CreateAccountInput, FilterInput, PromptInput, UpdateAccountInput} from "../types";


export type AccountsServiceOperators = {
  accounts: AccountsState
  addPromptAction: (data: boolean) => void
  addAccountAction: (data: boolean) => void
  editAccountAction: (data: boolean) => void
  getAccounts: () => void
  clickNav: () => void
  cleanFilteredJobSuccess: () => void
  getCountries: () => void
  getAccount: (id: number) => void
  getFeeds: (id: number, lastCreated: string | null, type: string) => void
  getAccountReport: (id: number) => void
  resetReport: () => void
  getFilterJobCount: (id: number) => void
  createAccount: (data: CreateAccountInput) => void
  updateAccount: (data: UpdateAccountInput) => void
  deleteAccount: (id?: number) => void
  createFilter: (accountId: number, data: FilterInput) => void
  updateFilter: (accountId: number, data: FilterInput) => void
  deleteFilter: (accountId: number, id: number) => void
  createPrompt: (accountId: number, data: PromptInput) => void
  updatePrompt: (accountId: number, data: PromptInput) => void
}

export const useAccountsService = (): Readonly<AccountsServiceOperators> => {
  const dispatch = useAppDispatch()

  return {
    accounts: useAppSelector(selectAccounts),
    addPromptAction: useCallback((data) => dispatch(accountsActions.addPromptAction(data)), [dispatch]),
    addAccountAction: useCallback((data) => dispatch(accountsActions.addAccount(data)), [dispatch]),
    editAccountAction: useCallback((data) => dispatch(accountsActions.editAccount(data)), [dispatch]),
    clickNav: useCallback(() => dispatch(accountsActions.clickNav()), [dispatch]),
    cleanFilteredJobSuccess: useCallback(() => dispatch(accountsActions.cleanFilteredJobSuccess()), [dispatch]),
    getCountries: useCallback(() =>
      dispatch(accountsActions.getCountries()), [dispatch]),
    getAccountReport: useCallback((id) =>
      dispatch(accountsActions.getAccountReport(id)), [dispatch]),
    getAccounts: useCallback(() =>
      dispatch(accountsActions.getAccounts()), [dispatch]),
    getAccount: useCallback((id) =>
      dispatch(accountsActions.getAccount(id)), [dispatch]),
    getFilterJobCount: useCallback((id) =>
      dispatch(accountsActions.getFilterJobCount(id)), [dispatch]),
    getFeeds: useCallback((id, lastCreated, type) =>
      dispatch(accountsActions.getFeeds(id, lastCreated, type)), [dispatch]),
    createAccount: useCallback((data: CreateAccountInput) =>
      dispatch(accountsActions.createAccount(data)), [dispatch]),
    updateAccount: useCallback((data: UpdateAccountInput) =>
      dispatch(accountsActions.updateAccount(data)), [dispatch]),
    deleteAccount: useCallback((id?: number) =>
      dispatch(accountsActions.deleteAccount(id)), [dispatch]),
    createFilter: useCallback((accountId: number, data: FilterInput) =>
      dispatch(accountsActions.createFilter(accountId, data)), [dispatch]),
    updateFilter: useCallback((accountId: number, data: FilterInput) =>
      dispatch(accountsActions.updateFilter(accountId, data)), [dispatch]),
    deleteFilter: useCallback((accountId: number, id?: number) =>
      dispatch(accountsActions.deleteFilter(accountId, id)), [dispatch]),
    createPrompt: useCallback((accountId: number, data: PromptInput) =>
      dispatch(accountsActions.createPrompt(accountId, data)), [dispatch]),
    updatePrompt: useCallback((accountId: number, data: PromptInput) =>
      dispatch(accountsActions.updatePrompt(accountId, data)), [dispatch]),
    resetReport: useCallback(() =>
      dispatch(accountsActions.getAccountReportStart()), [dispatch]),
  }
}

export default useAccountsService
