import {InputLabel} from "@mui/material";
import Chip from '@mui/material/Chip';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import {Control, Controller} from 'react-hook-form';

const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  '& .MuiChip-root': {
    margin: theme.spacing(0.5),
  },
  '& .MuiChip-deleteIcon': {
    marginRight: '-4px',
  },
}));

const InputWithBadge = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [badges, setBadges] = useState<string[]>(value ? value.split(',') : []);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!inputValue) {
      return
    }
    const items = inputValue.trim().split(',')
    const itemList = []
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      if (item.trim()) {
        itemList.push(item.trim())
      }
    }
    setBadges([...badges, ...itemList])
    setInputValue('')
    onChange([...badges, itemList].join(','));
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === 'Enter') && inputValue.trim() !== '') {
      event.preventDefault();
      setBadges([...badges, inputValue.trim()]);
      setInputValue('');
      onChange([...badges, inputValue.trim()].join(','));
    }
  };

  const handleDelete = (val: string) => {
    setBadges(badges.filter((badge) => badge !== val));
    onChange(badges.filter((badge) => badge !== val).join(','));
  };

  return (
    <InputContainer>
      <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
        {badges.map((badge, index) => (
          <Chip
            key={index}
            label={badge}
            size={'small'}
            color={'primary'}
            onDelete={() => handleDelete(badge)}
          />
        ))}
        <InputBase
          placeholder="Hit 'enter' key to add"
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          fullWidth
        />
      </Stack>
    </InputContainer>
  );
};
export interface FormChipInputFieldProps {
  name: string
  control: Control<any> // eslint-disable-line
  label: string,
  size?: 'small' | 'medium',
}

export const FormChipInputField = ({ name, control, label, size='medium' }: FormChipInputFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange } }) => (
      <>
        <InputLabel sx={{fontWeight: 'bold'}}>{label}</InputLabel>
        <InputWithBadge value={value} onChange={onChange} />
      </>
    )}
  />
)

export default FormChipInputField;