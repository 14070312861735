import { useEffect, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import Layout from 'components/Layout/index'
import { useAuthService } from 'features/auth'
import { storage } from 'libs/core/storage'
import { Loader } from 'libs/ui/components/Loader'

import {useGptService} from "../../features/chatGPT/hooks";



const AuthLayout = () => {
  const { auth, getMe } = useAuthService()
  const canAutoLogin = !!storage.getAccessToken()
  const canGPTLogin = !!storage.getGPTToken()
  const isAuthenticated = useMemo<boolean>(() => !!auth.me, [auth.me])
  const {setGPTState} = useGptService()
  useEffect(() => {
    if (canAutoLogin) {
      getMe();
    }
    setGPTState(canGPTLogin)
  },[canAutoLogin, canGPTLogin, getMe, setGPTState])


  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.source === window && event.data.type === 'toLagFastAuth') {
        if (event.data.auth) {
          storage.setGPTToken(event.data.token)
        } else {
          storage.clearGPTToken()
        }
        setGPTState(event.data.auth)
      }
    });

    // Don't forget to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', () => {});
    };
  }, [setGPTState]);

  if (isAuthenticated) {
    return <Layout />;
  }

  if (canAutoLogin) {
    return <Loader />;
  }

  return <Navigate to="/login" replace />;
}

export default AuthLayout