import styled from '@emotion/styled'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import {
  Button,
  List,
  ListItem, Chip, Grid, Tooltip,
} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import FrIcon from 'assets/images/fr_icon.png'
import GrIcon from 'assets/images/gr_icon.png'
import PphIcon from 'assets/images/pph_icon.png'
import UpIcon from 'assets/images/up_icon.png'
import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {useAuthService} from 'features/auth'

import {AccountListItem} from "../../features/accounts/types";

export const NavIcon = styled.img(() => ({
  marginRight: '10px',
  borderRadius: '3px',
  width: '18px'
}))

const getNavButton = (active: boolean) => styled(Button)({
  paddingBottom: '5px',
  marginBottom: '5px',
  fontSize: '14px',
  height: '25px',
  width: '100%',
  background: active ? '#b9275f' : '#860433',
  borderColor: '#860433',
  justifyContent: 'flex-start',
  color: 'white',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#b9275f',
    borderColor: '#b9275f',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#b9275f',
    borderColor: '#b9275f',
  },
  '&:focus': {
    backgroundColor: '#b9275f',
    borderColor: '#b9275f',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
})


export type AppDrawerProps = {
  open: boolean
  handleDrawerClose: (state: boolean) => void
}

export const Navigation = ({open, handleDrawerClose}: AppDrawerProps) => {
  const {accountId, type} = useParams<{ accountId: string, type: string }>()
  const [initial, setInitial] = useState(true)
  const {addAccountAction, accounts: {accounts, account}, getFeeds, getAccounts, clickNav} = useAccountsService()


  useEffect(() => {
    if (initial)
      getFeeds(0, null, (type || ""))
    setInitial(false)
  }, [getFeeds, initial, type])

  useEffect(() => {
    const timer = setInterval(() => {
      getFeeds(0, null, "")
    }, 60000);
    return () => clearTimeout(timer);
  }, [getFeeds]);


  const navigate = useNavigate()
  const {auth: {me}} = useAuthService()
  const addAccount = () => {
    addAccountAction(true)
  }
  const clickItem = useCallback((item: AccountListItem) => {
    clickNav()
    navigate(`accounts/${item.id}/feed`)
  }, [clickNav, navigate])
  let NavButton = getNavButton(false)
  return (
    <List>
      <ListItem key={-1} disablePadding>
        <NavButton onClick={addAccount} size="small">
          <LocalHospitalIcon sx={{paddingRight: '10px'}}/>
          <div>Add feed</div>
        </NavButton>
      </ListItem>
      {accounts.map((item, index) => {
        NavButton = getNavButton(accountId === item.id.toString())
        return (
          <ListItem key={index} sx={{position: 'relative'}} disablePadding>
            <Tooltip placement="right" title={`${item.name} success ${item.submit} bids in 24hr`}>
              <NavButton className={'active'} onClick={() => clickItem(item)} size={'small'}>
                {item.platform === 'upwork' && <NavIcon src={UpIcon}/>}
                {item.platform === 'freelancer' && <NavIcon src={FrIcon}/>}
                {item.platform === 'guru' && <NavIcon src={GrIcon}/>}
                {item.platform === 'people_per_hour' && <NavIcon src={PphIcon}/>}
                <div>{item.name.substring(0, 17)} {item.name.length > 17 && '...'}</div>
              </NavButton>
            </Tooltip>
            <Grid sx={{position: 'absolute', right: '2px', top: '0'}}>
              {
                item.auto_bid &&
                  <Chip
                    sx={{
                      fontSize: '10px',
                      background: '#f6e35e',
                      color: '#860433',
                      fontWeight: 'bold',
                      height: '13px'
                    }}
                    size={'small'} label={'AUTO'}/>
              }
              {
                item.feed_count > 0 &&
                <Chip
                  sx={{
                    background: 'white',
                    color: '#860433',
                    fontWeight: 'bold',
                    height: '16px',
                    marginLeft: '3px'
                  }}
                  size={'small'} label={item.feed_count}/>
              }
            </Grid>
          </ListItem>
        )
      })}
    </List>
  )
}