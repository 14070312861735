import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu';
import {Button, IconButton, styled} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, {useCallback, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import { drawerWidth } from 'components/Layout'
import { useAuthService } from 'features/auth'

import {useAccountsService} from "../../features/accounts/hooks";
import {useCommonService} from "../../features/common/hooks/useCommonService";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export type HeaderProps = {
  open: boolean
  handleDrawerOpen: (state: boolean) => void
}

const Header = ({ open, handleDrawerOpen }: HeaderProps) => {
  const [state, setState] = React.useState(false);
  const { logout, auth: {me} } = useAuthService()
  const navigate = useNavigate()
  const {accounts: {changeNav}} = useAccountsService()

  useEffect(() => {
    setState(false)
  }, [changeNav])

  useEffect(() => {
    setState(open)
  }, [open])

  const onLogout = useCallback(() => {
    logout()
    navigate('/login')
  }, [logout, navigate])

  const clickNav = () => {
    handleDrawerOpen(!state)
    setState(!state)
  }
  return (
    <AppBar
      position="fixed"
      color='transparent'
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: '64px',
        background: 'white',
        borderBottom: '1px #e5e5e5 solid' }}
    >
      <Toolbar sx={{ flexWrap: 'wrap', height: '64px' }}>
        <Typography variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
          <label style={{fontWeight: 'bold', fontSize: '20px', color: '#860433'}}>LagFast</label>
        </Typography>
        {
          me?.subscription && <Button variant="outlined" size="medium" sx={{marginRight: '10px', textTransform: 'none'}}>
            {(me?.subscription?.plan.proposal_limit || 0) - (me?.subscription?.used_ai_bid_count || 0)} Available AI BID
          </Button>
        }
        {
          me?.subscription ? <Button onClick={() => navigate('/membership')} variant="contained" size="medium" sx={{marginRight: '10px', textTransform: 'none'}}>
            {me?.subscription?.plan.name} Member
          </Button>
            :
            <Button variant="contained" size="medium" onClick={() => navigate('/membership')} sx={{marginRight: '10px', textTransform: 'none'}}>
              No Subscription Selected
            </Button>
        }
        <nav>
          {
            open ?
              <IconButton color='inherit' onClick={onLogout}>
                <Logout fontSize='small' />
              </IconButton>
              :
              <IconButton color='inherit' onClick={clickNav}>
                <MenuIcon fontSize='small' />
              </IconButton>
          }
        </nav>
      </Toolbar>
    </AppBar>
  )
}

export default Header
