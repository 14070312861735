import {Button, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useMemo} from 'react'

import {GptText, Job} from "features/jobs/types";

import {Account} from "../../../../accounts/types";
import {useGptService} from "../../../../chatGPT/hooks/useGptService";
import {useJobsService} from "../../../hooks/useJobsService";

import {DescriptionPanel} from "./jobPannel/descriptionPannel";
import {FreelancerFeatures} from "./jobPannel/freelancerFeatures";
import {GuruFeatures} from "./jobPannel/guruFeatures";
import {PphFeatures} from "./jobPannel/pphFeatures";
import {UpworkFeatures} from "./jobPannel/upworkFeatures";

export type JobPanelProps = {
  job: Job | undefined
}

export const JobPanel = ({job}: JobPanelProps) => {
  const created = useMemo(() => {
    const now = new Date();
    const targetTime = new Date(job?.created || "");
    const timeDiff = now.getTime() - targetTime.getTime();

    const secondsDiff = Math.floor(timeDiff / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    const daysDiff = Math.floor(hoursDiff / 24);
    return (
      <Typography sx={{fontSize: '12px'}} variant={'caption'} component="div">
        Posted {daysDiff > 0 ? `${daysDiff}d ` : ''}
        {hoursDiff > 0 ? `${hoursDiff % 24}h ` : ''}
        {minutesDiff > 0 ? `${minutesDiff % 60}m ` : ''} ago
      </Typography>
    );
  }, [job])
  return <Grid sx={{height: 'fit-content', padding: '0!important'}} item xs={12} sm={12} container>
    {job?.platform === 'upwork' && <UpworkFeatures job={job}/>}
    {job?.platform === 'freelancer' && <FreelancerFeatures job={job}/>}
    {job?.platform === 'guru' && <GuruFeatures job={job}/>}
    {job?.platform === 'people_per_hour' && <PphFeatures job={job}/>}
    <DescriptionPanel job={job}/>
    <Grid item container direction={'column'} xs={12}>
      {
        '0' in (job?.questions || {}) && <Grid item>
          <Typography
            sx={{fontSize: '14px', fontWeight: 'bold', color: "#3b3b3b"}}
            variant={'caption'}
            gutterBottom
            component="div"
          >
                  Questions
          </Typography>
        </Grid>
      }
      {
        Object.entries(job?.questions || []).map((item) => (
          <Grid key={item[0]} item>
            <Typography sx={{fontSize: '14px', paddingBottom: '5px'}} variant={'body1'} component="div">
              - {item[1]}
            </Typography>
          </Grid>
        ))
      }
    </Grid>
    <Grid item sx={{textAlign: 'right', width: '100%'}}>{created}</Grid>
  </Grid>
}
