import styled from "@emotion/styled";
import {Divider, Grid, Typography} from "@mui/material";
import * as React from "react";

import TitleImg from "../../../assets/images/logo.png";
import SuccessImg from "../../../assets/images/Success.png";

const Container = styled.div(() => ({
  width: '100vw',
  height: '100vh',
  background: '#155bd5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const InfoCard = styled.div(() => ({
  width: '706px',
  height: 350,
  minWidth: '706px',
  background: '#ffffff',
  borderRadius: 20,
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 900px)': {
    width: '90vw',
    minWidth: '90vw',
    padding: '20px',
  },
}))

export const AfterSIgnUpContainer = () => (
  <Container>
    <InfoCard>
      <Grid 
        sx={{
          flexDirection: {
            xs: 'row',
            md: 'column',
          },
        }} display={'flex'} justifyContent={'center'} container>
        <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'} justifyItems={'center'}>
          <img className="icon-size" height={'140px'} src={SuccessImg} alt=""/>
        </Grid>
        <Grid item xs={12} md={8} display={'flex'} flexDirection={'column'} justifyContent="center" alignItems="start">
          <Typography variant="h5" component="h5" sx={{fontWeight: '700'}}>
            Sign Up Complete!
          </Typography>
          <Typography component="div">
            {'Please check your email to activate your account and start exploring freelance opportunities. Don\'t forget to peek in your spam folder just in case. We\'re excited to have you on board!'}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{background: 'black'}} />
      <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'} container>
        <Grid item>
          <Typography sx={{fontSize: '14px'}} component="div">
            Need help? support@lagfast.com
          </Typography>
        </Grid>
        <Grid item>
          <img className="icon-size" height={'20px'} src={TitleImg} alt=""/>
        </Grid>
      </Grid>

    </InfoCard>
  </Container>
)
