import {Chip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import React from 'react'

import {Job} from "features/jobs/types";

import {useJobsService} from "../../../../hooks";
import {FeatureItem} from "../featureItem";


export type FreelancerFeaturesProps = {
  job: Job | undefined
}

export const FreelancerFeatures = ({job,}: FreelancerFeaturesProps) => {
  const {openHistory} = useJobsService()
  return <>
    <Grid item xs={12} container>
      {
        job?.skills.split(',').map((skill, index) =>
          <Chip sx={{marginRight: '3px', marginBottom: '3px'}} key={index} size={'small'} label={skill} variant="outlined" />
        )
      }
    </Grid>
    <Grid item xs={12} container>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Price'} value={`${job?.price}`}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Country'} value={`${job?.country}`}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid item container direction="row" spacing={0}>
          <Typography
            sx={{fontSize: '14px', fontWeight: 'bold', color: "#3b3b3b"}}
            variant={'caption'}
            gutterBottom
            component="div"
          >
            Payment:&nbsp;
          </Typography>
          <Typography sx={{fontSize: '14px',color: "#3b3b3b"}} gutterBottom component="div">
            {job?.payment_verified ?
              <Chip sx={{marginRight: '3px', marginBottom: '3px'}} color={'success'} size={'small'} label={'Verified'} variant="outlined"/> :
              <Chip sx={{marginRight: '3px', marginBottom: '3px'}} size={'small'} label={'Unverified'} variant="outlined"/>
            }
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FeatureItem label={'Since'} value={`${job?.member_since.replace("Member since", "")}`}/>
      </Grid>
      {
        job?.review !== undefined && <Grid item xs={12} sm={4} container direction={'row'} alignItems="center">
          <Grid>
            <FeatureItem label={'Review'} value={`${job?.review}`}/>
          </Grid>
          <Grid>
            <Rating
              name="review-rating"
              value={job?.review}
              precision={0.5}
              max={5}
              readOnly
            />
          </Grid>
        </Grid>
      }
      {
        (job?.review_count !== undefined && job?.review_count !== null) && <Grid item xs={12} sm={4}>
          <FeatureItem label={'Review Counts'} value={`${job?.review_count} reviews`}/>
        </Grid>
      }
      {
        job?.banners && <Grid item xs={12} container>
          {
            job?.banners.split(',').map((banner, index) =>
              <Chip key={index} size={'small'} variant="outlined" label={banner} sx={{marginRight: '3px'}} color="primary" />)
          }
        </Grid>
      }


    </Grid>
  </>
}

