import { all, fork } from 'redux-saga/effects'

import { accountsWatcherSaga } from 'features/accounts/store/accounts.sagas'
import { authWatcherSaga } from 'features/auth/store/auth.sagas'
import { botWatcherSaga } from 'features/bot/store';
import { gptWatcherSaga } from 'features/chatGPT/store';
import { dashboardWatcherSaga } from 'features/dashboard/store';
import { jobsWatcherSaga } from 'features/jobs/store/jobs.sagas'
import { narratorWatcherSaga } from 'features/narrator/store';
import { paymentWatcherSaga } from 'features/payment/store';
import { pubWatcherSaga } from 'features/public/store';


export function* rootSaga() {
  yield all([
    fork(authWatcherSaga),
    fork(jobsWatcherSaga),
    fork(accountsWatcherSaga),
    fork(botWatcherSaga),
    fork(dashboardWatcherSaga),
    fork(gptWatcherSaga),
    fork(pubWatcherSaga),
    fork(narratorWatcherSaga),
    fork(paymentWatcherSaga)
  ])
}

export default rootSaga
