import {Box, styled} from '@mui/material'
import React from 'react'

import {ResetPasswordConfirmContainer} from "../features/auth/components/ResetPasswordConfirmContainer";


const PageContainer = styled(Box)({
  height: 'calc(100vh - 125px)', // Using calc in CSS
  width: '100%',
})

const ResetPasswordConfirmPage = () => (
  <PageContainer>
    <ResetPasswordConfirmContainer/>
  </PageContainer>
)

export default ResetPasswordConfirmPage
