import {Grid} from "@mui/material";
import React from 'react'

import {useAccountsService} from "features/accounts/hooks/useAccountsService";

import {Spacer} from "../../../../../libs/ui/components/Spacer";
import {useAuthService} from "../../../../auth";

import {AccountCheckBoxField} from "./accountCheckBoxField";
import {AccountField} from "./accountField";
import {DeleteButtonField} from "./deleteButtonField";

export const AboutTabContainer = () => {
  const {accounts: {account}} = useAccountsService()
  const {auth: {me}} = useAuthService()

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid container item>
          <AccountField label={'Feed Name'} value={account?.name}/>
        </Grid>
        <Grid container item>
          <AccountField label={'Profile Hourly Rate (USD/hr)'} value={account?.hourly_rate}/>
        </Grid>
        <Grid container item>
          <AccountField label={'Description'} value={account?.description}/>
        </Grid>
      </Grid>
      <Spacer height={10} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Grid container item>
          <AccountCheckBoxField
            label={'Auto Bid'}
            checked={account?.auto_bid}
            keyValue={'auto_bid'}
            description={'This will auto generate proposal and send it'}/>
        </Grid>
        <Spacer height={10} />
        <Grid container item>
          <DeleteButtonField/>
        </Grid>
      </Grid>
    </>
  )
}
