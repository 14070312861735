import { useCallback } from 'react'

import {paymentActions, PaymentState, selectPayment} from 'features/payment/store'
import {useAppDispatch, useAppSelector} from 'store/hooks'

import {Plan} from "../types";


export type PaymentServiceOperators = {
  payment: PaymentState,
  getPlans: () => void
  setPlan: (plan: Plan) => void
  cleanPlan: () => void
}

export const useBotService = (): Readonly<PaymentServiceOperators> => {
  const dispatch = useAppDispatch()

  return {
    payment: useAppSelector(selectPayment),
    getPlans: useCallback(() =>
      dispatch(paymentActions.getPlans()), [dispatch]),
    setPlan: useCallback((plan: Plan) =>
      dispatch(paymentActions.setPlan(plan)), [dispatch]),
    cleanPlan: useCallback(() =>
      dispatch(paymentActions.cleanPlan()), [dispatch]),
  }
}

export default useBotService
