import {Box, styled} from '@mui/material'
import React from 'react'

import {UnsubscribeContainer} from "../features/auth/components/UnsubscribeContainer";


const AfterSignUpPageContainer = styled(Box)({
  height: 'calc(100vh - 125px)', // Using calc in CSS
  width: '100%',
})

const UnsubscribePage = () => (
  <AfterSignUpPageContainer>
    <UnsubscribeContainer/>
  </AfterSignUpPageContainer>
)

export default UnsubscribePage
