import { api } from 'features/api'

import {
  Feed, FeedInput, FeedRequestParam, FeedResponse, HistoryResponse, Proposal, ProposalInput
} from "../types";

export const getFeeds = (param: FeedRequestParam, orderBy: string): Promise<FeedResponse> =>
  api.get(`/api/feeds/?account=${param.accountId}&status=${param.type}&last_item=${param.lastItem}&page_size=${param.page_size}&order_by=${orderBy}`);

export const updateFeed = (id: number, data: FeedInput): Promise<Feed> =>
  api.patch(`/api/feeds/${id}/`, data);

export const deleteFeed = (id: number): Promise<Feed> =>
  api.delete(`/api/feeds/${id}/`);

export const updateProposal = (id: number, data: ProposalInput): Promise<Proposal> =>
  api.patch(`/api/proposals/${id}/`, data);

export const updateQuestion = (id: number, data: {data: string, index: number}): Promise<Proposal> =>
  api.put(`/api/proposals/${id}/question/`, data);

export const createProposal = (feedId: number, data: ProposalInput): Promise<Proposal> =>
  api.post(`/api/feeds/${feedId}/create_proposal/`, data);

export const generateProposal = (feedId: number): Promise<Proposal> =>
  api.post(`/api/feeds/${feedId}/proposal/`);

export const getHistory = (jobId: number): Promise<HistoryResponse> =>
  api.get(`/api/jobs/${jobId}/get_work_history/`);

export const emptyFeeds = (accountId: number) =>
  api.post(`/api/accounts/${accountId}/empty_feeds/`);

export const emptyTrash = (accountId: number) =>
  api.post(`/api/accounts/${accountId}/empty_trash/`);