import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import React, {useCallback, useEffect, useState} from 'react'

import {useAccountsService} from "features/accounts/hooks/useAccountsService";

import {PromptInput} from "../types";

import {PromptForm} from "./edit/proposalSettingTab/promptForm";


export const PrompContainer = () => {
  const {accounts: {setPrompt, account}, addPromptAction, createPrompt} = useAccountsService()

  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    setOpen(setPrompt)
  }, [setPrompt])

  const closeDialog = useCallback(() => {
    setOpen(false)
    addPromptAction(false)
  }, [addPromptAction])

  const onSubmitPrompt = useCallback((data: PromptInput) => {
    if (!account?.id) return
    data.account = account?.id
    createPrompt(account.id, data)
    setOpen(false)
    addPromptAction(false)
  }, [account?.id, createPrompt, addPromptAction])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={closeDialog}>
      <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
        { 'Configure your prompt' }
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PromptForm onSubmit={onSubmitPrompt}/>
      </DialogContent>
    </Dialog>
  )
}
