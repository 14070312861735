import {OnApproveActions, OnApproveData} from '@paypal/paypal-js/types/components/buttons'
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js'
import React, {useCallback, useEffect} from 'react'

import { Env } from 'config/Env'

import {useAuthService} from "../../../auth";
import {completeOrder, getPlanOrder} from "../../api";
import usePaymentService from "../../hooks/usePaymentService";
import {Order} from "../../types";


export const PaypalButton = () => {
  const {payment: {plan, openModal}, cleanPlan} = usePaymentService()
  const { getMe } = useAuthService()

  const initialOptions = {
    clientId: Env.PAYPAL_CLIENT_ID || '',
    currency: "USD",
    intent: "capture",
  };

  const createOrder = useCallback(async (): Promise<string> => {
    if (plan?.id) {
      const order: Order = await getPlanOrder(plan?.id)
      return order.orderId
    }
    return ''
  }, [plan?.id])

  const onApprove = useCallback(async (data: OnApproveData, actions: OnApproveActions) => {
    cleanPlan()
    if (plan?.id) {
      await completeOrder(plan?.id, data)
      getMe()
    }
  }, [cleanPlan, plan?.id])

  const onCancel = useCallback(() => {
  }, [])

  const onError = useCallback(() => {
  }, [])

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        style={{
          height: 54,
          color: 'blue',
          shape: 'pill',
          label: 'pay'
        }}
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={onCancel}
        onError={onError}
      />
    </PayPalScriptProvider>
  )
}
