import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from '@mui/material'
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup'

import { useAccountsService } from "features/accounts/hooks/useAccountsService";
import {CreateAccountInput, IncreaseDurationModes, IncreasePercentageModes, PriceModes} from "features/accounts/types";
import { FormSelectField } from "libs/ui/components/FormSelectField";
import { FormTextField } from 'libs/ui/components/FormTextField'
import { Spacer } from 'libs/ui/components/Spacer'

import {useBotService} from "../../../../bot/hooks";



export type AccountFormProps = {
  label?: string
  onSubmit: (data: CreateAccountInput) => void
}

export const EditAboutForm = ({ label, onSubmit }: AccountFormProps) => {
  const {accounts: {account}} = useAccountsService()
  const {bot: {bots}} = useBotService()
  const defaultValues = useMemo(() => (
    {
      id: account?.id,
      name: account?.name,
      hourly_rate: account?.hourly_rate || 0,
      description: account?.description,
      price_mode: account?.price_mode,
      increase_duration: account?.increase_duration,
      increase_percentage: account?.increase_percentage,
      platform: account?.platform,
      slack_webhook_url: account?.slack_webhook_url || '',
      bot: account?.bot?.id || '',
    }), [account])


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    description: Yup.string(),
    hourly_rate: Yup.number(),
    platform: Yup.string().required('This field is required'),
    increase_duration: Yup.number(),
    increase_percentage: Yup.number(),
    slack_webhook_url: Yup.string(),
    bot: Yup.string(),
  })

  const methods = useForm<CreateAccountInput>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, control } = methods
  return (
    <Stack sx={{ pt: 1 }} direction='column' spacing={1} justifyContent='center'>
      <Spacer height={8} />
      {
        label === 'Feed Name' &&
        <>
          <FormTextField
            name='name'
            label={'Name'}
            control={control}
            placeholder={'e.g: Alexandra'}
          />
          <Spacer height={15} />
        </>
      }
      {
        label === 'Description' &&
        <>
          <FormTextField
            name='description'
            label={'Description'} control={control} type={'string'} />
          <Spacer height={15} />
        </>
      }
      {
        label === 'Profile Hourly Rate (USD/hr)' &&
        <>
          <FormTextField
            name='hourly_rate'
            label={'Hourly rate(USD/hr)'} control={control} type={'number'} />
          <Spacer height={15} />
        </>
      }
      {
        label === 'Slack Webhook Url' &&
        <>
          <FormTextField
            name='slack_webhook_url'
            label={'Slack Webhook Url'} control={control} type={'text'} />
          <Spacer height={15} />
        </>
      }
      {
        label === 'Bot' &&
        <>
          <FormSelectField
            name='bot'
            label={'bot'}
            control={control}
            items={bots.map((bot) => ({ text:bot.name, value: bot.id }))}
            addNull={true}
          />
          <Spacer height={15} />
        </>
      }
      {
        label === 'Price Mode' &&
        <>
          <FormSelectField
            name='price_mode'
            label={'price_mode'}
            control={control}
            items={Object.entries(PriceModes).map((item) => ({ text:item[1], value: item[0] }))}
          />
          <Spacer height={15} />
        </>
      }
      {
        label === 'Increase Duration' &&
        <>
          <FormSelectField
            name='increase_duration'
            label={'Increase Duration'}
            control={control}
            items={Object.entries(IncreaseDurationModes).map((item) => ({ text:item[1], value: item[0] }))}
          />
          <Spacer height={15} />
        </>
      }
      {
        label === 'Increase Percentage' &&
        <>
          <FormSelectField
            name='increase_percentage'
            label={'Increase Percentage'}
            control={control}
            items={Object.entries(IncreasePercentageModes).map((item) => ({ text:item[1], value: item[0] }))}
          />
          <Spacer height={15} />
        </>
      }
      <Stack direction='row' spacing={2} alignItems='center' justifyContent={'end'}>
        <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>{'Update'}</Button>
      </Stack>
    </Stack>
  )
}
