import styled from "@emotion/styled";
import {yupResolver} from "@hookform/resolvers/yup";
import ReplyIcon from '@mui/icons-material/Reply';
import {Button, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

import {FormTextField} from "../../../libs/ui/components/FormTextField";
import {Spacer} from "../../../libs/ui/components/Spacer";
import {useAuthService} from "../hooks/useAuthService";
import {ResetPasswordInput} from "../types";

const Container = styled.div(() => ({
  width: '100vw',
  height: '100vh',
  background: '#155bd5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const InfoCard = styled.div(() => ({
  width: '400px',
  height: 200,
  background: '#ffffff',
  borderRadius: 20,
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 900px)': {
    width: '90vw',
    minWidth: '90vw',
    padding: '20px',
  },
}))
export const ResetPasswordContainer = () => {
  const navigate = useNavigate()
  const [sent, setSent] = useState(false)
  const {restPassword} = useAuthService()

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required')
  })

  const methods = useForm<ResetPasswordInput>({
    defaultValues: { email: '' },
    resolver: yupResolver(validationSchema),
  })
  const onSubmit = async (data: ResetPasswordInput) => {
    await restPassword(data)
    setSent(true)
  }
  const { handleSubmit, control } = methods
  return (
    <Container>
      <InfoCard>
        <ReplyIcon sx={{cursor: 'pointer'}} onClick={() => navigate('/login')}></ReplyIcon>
        {
          sent ? <Typography textAlign={'center'} sx={{margin: 'auto'}} component={'p'}>We sent email, please check inbox</Typography> :
            <><Typography component={'p'}>
          We will send url to reset password to your email, please add  your email address here.
            </Typography>
            <Stack sx={{ pt: 0 }} direction='column' spacing={1} justifyContent='center'>
              <FormTextField name='email' rows={1} label={'Email Address'} control={control} />
              <Spacer height={8} />
              <Button sx={{background: '#155bd5'}} onClick={handleSubmit(onSubmit)} variant={'contained'}>{'Send Email'}</Button>
            </Stack></>
        }
      </InfoCard>
    </Container>
  );
}
