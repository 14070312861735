import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {Box} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, {useCallback, useEffect, useState} from "react";

import usePaymentService from "../hooks/usePaymentService";

import {PaypalButton} from "./paypalPay/buttonPay";

export const PayModal = () => {
  const {payment: {plan, openModal}, cleanPlan} = usePaymentService()

  const closeDialog = useCallback(() => {
    cleanPlan()
  }, [cleanPlan])
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={openModal}
      onClose={closeDialog}>
      <DialogTitle sx={{fontSize: '24px', fontWeight: 'bold'}}>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Box sx={{width: '100%', padding: '30px'}}>
        <Typography color='primary' gutterBottom variant="h5" component="div" style={{fontWeight: 'bold', textTransform: 'capitalize', textAlign: 'center'}}>
          {plan?.name} Monthly Membership
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{paddingBottom: '10px', fontSize: '25px', textAlign: 'center'}}>
          <span style={{fontSize: '20px', fontWeight: 'bold', color: 'black'}}>Total: ${plan?.price}</span>
        </Typography>
        <div style={{paddingBottom: '25px'}}>
          {plan?.features.split('\n').map((line, index) => (
            <div key={index} style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
              <CheckIcon color={'primary'} style={{fontSize: '15px', marginTop: '3px', marginRight: '3px'}}/>
              <div dangerouslySetInnerHTML={{__html: `${line}`}}/>
            </div>
          ))}
        </div>
        <PaypalButton/>
      </Box>
    </Dialog>
  )
}
