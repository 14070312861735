import React, {useCallback, useEffect, useState} from 'react'

import {useAccountsService} from "features/accounts/hooks/useAccountsService";

import {PromptInput} from "../../../types";

import {PromptForm} from "./promptForm";

export const ProposalSettingTabContainer = () => {
  const {accounts: {account}, updatePrompt, createPrompt} = useAccountsService()

  const onSubmit = useCallback((data: PromptInput) => {
    if (!account?.id) return
    if (data?.id) {
      updatePrompt(account?.id, data)
    } else {
      data.account = account?.id
      createPrompt(account?.id, data)
    }
  }, [])

  return (
    <PromptForm data={account?.prompt} onSubmit={onSubmit}/>
  )
}
