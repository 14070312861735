import {Box, styled} from '@mui/material'
import React from 'react'

import {ActivateContainer} from "features/auth/components/ActivateContainer";


const HelpPageContainer = styled(Box)({
  height: 'calc(100vh - 125px)', // Using calc in CSS
  width: '100%',
})

const ActivatePage = () => (
  <HelpPageContainer>
    <ActivateContainer/>
  </HelpPageContainer>
)

export default ActivatePage
