import {Feed, NarratorFeed, ReportData, ReportResponse} from "../../jobs/types";

export type Country = {
  id: number
  name: string
}

export type FilteredJobCount = {
  date: string
  count: number
}

export type Filter = {
  id: number
  search_key: string
  exclude_key: string
  fixed_min: string
  fixed_max: string
  hour_min: string
  hour_max: string
  h_fixed_min: string
  h_fixed_max: string
  h_hour_min: string
  h_hour_max: string
  ave_rate_min: string
  ave_rate_max: string
  hire_rate_min: string
  hire_rate_max: string
  payment_verified: boolean
  fixed_job_filter: boolean
  hourly_job_filter: boolean
  h_fixed_job_filter: boolean
  h_hourly_job_filter: boolean
  ave_rate_filter: boolean
  hire_rate_filter: boolean
  total_spend: string | number
  in_progress: boolean
  exclude_countries: Country[]
}

export type FilterInput = {
  id?: number
  search_key?: string,
  exclude_key?: string,
  fixed_min?: string,
  fixed_max?: string,
  hour_min?: string,
  hour_max?: string,
  h_fixed_min?: string,
  h_fixed_max?: string,
  h_hour_min?: string,
  h_hour_max?: string,
  ave_rate_min?: string,
  ave_rate_max?: string,
  hire_rate_min?: string,
  hire_rate_max?: string,
  payment_verified?: boolean,
  fixed_job_filter?: boolean,
  hourly_job_filter?: boolean,
  h_fixed_job_filter?: boolean,
  h_hourly_job_filter?: boolean,
  hire_rate_filter?: boolean
  ave_rate_filter?: boolean,
  total_spend?: string,
  exclude_countries?: number[]
}

export type Bot = {
  id: string
  name: string
  x_diff: number
  y_diff: number
  extension: boolean
  bot: boolean
  log: string,
  pending_feed: number,
  open_email: boolean
  correction_position: boolean
  active: boolean
  accounts: AccountListItem[]
}

export type Account = {
  id: number
  name: string
  slack_webhook_url: string | null
  note: string
  description?: string
  platform: string
  hourly_rate: number
  price_mode: number
  increase_duration: number
  increase_percentage: number
  add_summary: boolean
  add_proposal: boolean
  add_history: boolean
  auto_bid: boolean
  send_notification: boolean
  filters: Filter[]
  prompt: Prompt
  bot: Bot
}

export type AccountListItem = {
  id: number
  name: string
  platform: string
  auto_bid: boolean
  feed_count: number
  submit: number
}

export type FeedResponse = {
  accounts: AccountListItem[],
  feeds: NarratorFeed[]
  messages: number
}


export type AccountReportResponse = {
  data: ReportResponse
}


export type CreateAccountInput = {
  id?: number
  name: string
  description: string
  hourly_rate: number
  platform: string
  slack_webhook_url: string
  bot?: string | undefined
}

export type UpdateAccountInput = {
  id?: number
  name?: string
  note?: string
  description?: string
  add_summary?: boolean
  add_proposal?: boolean
  add_history?: boolean
  auto_bid?: boolean
  send_notification?: boolean
}

export const PriceModes: {[key: string]: string} = {
  'as_it': 'Don\'t update price',
  'profile': 'Set price with profile rate',
  'max': 'Set price with max value'
}

export const IncreaseDurationModes: {[key: string]: string} = {
  0: 'Never',
  1: '3 months',
  2: '6 months',
  3: '12 months',
}

export const IncreasePercentageModes: {[key: string]: string} = {
  0: '5%',
  1: '10%',
  2: '15%',
}


export const TotalSpend: {[key: string]: string} = {
  '0': 'Unselect',
  '100': '$100',
  '500': '$500',
  '1000': '$1k',
  '3000': '$3k',
  '5000': '$5k',
  '7000': '$7k',
  '10000': '$10k',
  '20000': '$20k',
  '50000': '$50k',
  '100000': '$100k',
}

export const WordLength: {[key: number]: string} = {
  50: '50 words',
  100: '100 words',
  150: '150 words',
  200: '200 words',
  250: '250 words',
  300: '300 words',
  350: '350 words',
  400: '400 words',
}

export type Prompt = {
  id?: number
  full_name?: string,
  profile_url?: string,
  start_cover?: string,
  end_cover?: string,
  word_length?: number,
  instruction?: string,
  prev_working_urls?: string,
  github?: string,
  frameworks?: string,
  qa_test?: string,
  account?: number,
  prompt?: string
  qa_prompt?: string
  summary_prompt?: string
}

export type PromptInput = {
  id?: number
  full_name?: string,
  profile_url?: string,
  start_cover?: string,
  end_cover?: string,
  word_length?: number,
  instruction?: string,
  account?: number,
  prompt?: string
  qa_prompt?: string
  summary_prompt?: string
}

export const Platform: {[key: string]: string} = {
  upwork: 'Upwork',
  freelancer: 'Freelancer',
  guru: 'Guru',
  people_per_hour: 'People Per Hour',
}