// DUCKS pattern
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import type { RootState } from 'store/store'


export interface GPTState {
  connected: boolean
}

const initialState: GPTState = {
  connected: false,
}

// slice
export const gptSlice = createSlice({
  name: 'gpt',
  initialState,
  reducers: {
    setGptState(state, action: PayloadAction<boolean>) {
      state.connected = action.payload
    },
  },
})

// Actions
export const gptActions = {
  setGptState: gptSlice.actions.setGptState,
}

// Selectors
export const selectGPT = (state: RootState) => state.gpt

// Reducer
export default gptSlice.reducer
