import React, {Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'

import AuthLayout from 'components/Layout/AuthLayout'
import {useAuthService} from 'features/auth'
import {Loader} from 'libs/ui/components/Loader'

import LandingLayout from "../components/Layout/LandingLayout";
import ActivatePage from "../pages/ActivatePage";
import AfterSignUpPage from "../pages/AfterSIgnupPage";
import ChatGPTPage from "../pages/chatGPT";
import PaymentPage from "../pages/PaymentPage";
import ResetPasswordPage from "../pages/ResetPassword";
import ResetPasswordConfirmPage from "../pages/ResetPasswordConfirm";
import UnsubscribePage from "../pages/UnsubscribePage";


const LoginPage = React.lazy(() => import('pages/LoginPage'))
const SignUpPage = React.lazy(() => import('pages/SIgnUpPage'))
const AccountPage = React.lazy(() => import('pages/AccountPage'))
const ProfilePage = React.lazy(() => import('pages/ProfilePage'))
const BotsPage = React.lazy(() => import('pages/BotsPage'))
const MessagePage = React.lazy(() => import('pages/MessagePage'))
const NewAccountPage = React.lazy(() => import('pages/NewAccountPage'))
const AccessDeniedPage = React.lazy(() => import('pages/AccessDeniedPage'))
const HelpPage = React.lazy(() => import('pages/HelpPage'))
const LearnPage = React.lazy(() => import('pages/LearnPage'))
const DashboardPage = React.lazy(() => import('pages/DashboardPage'))
const PubJobPage = React.lazy(() => import('pages/PubJobPage'))
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'))

type RoleRouteProps = {
  element: JSX.Element
}

const RoleRoute = ({ element }: RoleRouteProps) => {
  const { auth: { me } } = useAuthService()
}


const AppRoutes = () => (
  <Suspense fallback={<Loader />}>
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route path='/signup' element={<SignUpPage />} />
      <Route path='/activate/:uid/:token' element={<ActivatePage />} />
      <Route path='/reset-password/:uid/:token' element={<ResetPasswordConfirmPage />} />
      <Route path='/reset-password' element={<ResetPasswordPage />} />
      <Route path='/confirm-email' element={<AfterSignUpPage />} />
      <Route path='/unsubscribe' element={<UnsubscribePage />} />
      <Route path='/pub/*' element={<LandingLayout />}>
        <Route path='job/:uid' element={<PubJobPage />} />
      </Route>
      <Route path='/' element={<AuthLayout />}>
        <Route index={true} element={<Navigate to='/accounts' replace />} />

        <Route
          path='/accounts/:accountId/:type'
          element={<AccountPage />}
        />
        <Route
          path='/dashboard/'
          element={<DashboardPage />}
        />
        <Route
          path='/accounts/'
          element={<NewAccountPage />}
        />
        <Route
          path='/ai-search/'
          element={<NewAccountPage />}
        />
        <Route
          path='/bots/'
          element={<BotsPage />}
        />
        <Route
          path='/messages/'
          element={<MessagePage />}
        />
        <Route
          path='/membership/'
          element={<PaymentPage />}
        />
        <Route
          path='/profile/'
          element={<ProfilePage />}
        />
        <Route
          path='/Learn'
          element={<LearnPage />}
        />
        <Route
          path='/help'
          element={<HelpPage />}
        />
        {/* <Route */}
        {/*  path='/chat-gpt' */}
        {/*  element={ */}
        {/*    <RoleRoute */}
        {/*      element={<ChatGPTPage />} */}
        {/*      roles={[Role.ADMIN, Role.USER]} */}
        {/*    /> */}
        {/*  } */}
        {/* /> */}
      </Route>
    </Routes>
  </Suspense>
)

export default AppRoutes
