import styled from '@emotion/styled'
import MoneyIcon from '@mui/icons-material/AttachMoney';
import BookIcon from '@mui/icons-material/Book';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ForumIcon from '@mui/icons-material/Forum';
import HelpIcon from '@mui/icons-material/Help';
import Logout from '@mui/icons-material/Logout'
import ProfileIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button,
  List,
  ListItem
} from '@mui/material'
import React, {useCallback} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'

import {useAccountsService} from "features/accounts/hooks/useAccountsService";
import {useAuthService} from 'features/auth'

import {useBotService} from "../../features/bot/hooks";

export const NavIcon = styled.img(() => ({
  marginRight: '10px',
  borderRadius: '3px',
  border: '1px white solid'
}))

const getNavButton = (active: boolean) => styled(Button)({
  paddingBottom: '5px',
  marginBottom: '5px',
  fontSize: '14px',
  height: '25px',
  width: '100%',
  background: active ? '#b9275f' : '#860433',
  borderColor: '#860433',
  justifyContent: 'flex-start',
  color: 'white',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#b9275f',
    borderColor: '#b9275f',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#b9275f',
    borderColor: '#b9275f',
  },
  '&:focus': {
    backgroundColor: '#b9275f',
    borderColor: '#b9275f',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
})


export type AppDrawerProps = {
  open: boolean
  handleDrawerClose: (state: boolean) => void
}

export const SettingNav = ({open, handleDrawerClose}: AppDrawerProps) => {
  const {clickNav} = useAccountsService()
  const {bot: {messageCount}} = useBotService()

  const navigate = useNavigate()
  const location = useLocation();
  const path = location.pathname;
  const {logout} = useAuthService()

  const selectPath = useCallback((url: string) => {
    clickNav()
    navigate(url)
  }, [clickNav, navigate])

  const onLogout = useCallback(() => {
    logout()
    navigate('/login')
  }, [logout, navigate])

  const DashboardButton = getNavButton(path === '/dashboard/')
  const SettingButton = getNavButton(path === '/bots/')
  const LearnButton = getNavButton(path === '/learn/')
  const HelpButton = getNavButton(path === '/help/')
  const ProfileButton = getNavButton(path === '/profile/')
  const LogoutButton = getNavButton(path === '/logout/')
  const PaymentButton = getNavButton(path === '/payment/')
  const ChatGPTButton = getNavButton(path === '/chat-gpt/')
  return (
    <List>
      <ListItem disablePadding>
        <DashboardButton onClick={() => selectPath(`dashboard/`)} size="small">
          <DashboardIcon sx={{paddingRight: '10px'}}/>
          <div>Dashboard</div>
        </DashboardButton>
      </ListItem>
      <ListItem disablePadding>
        <SettingButton onClick={() => selectPath(`bots/`)} size="small">
          <SettingsIcon sx={{paddingRight: '10px'}}/>
          <div>Bots</div>
        </SettingButton>
      </ListItem>
      <ListItem disablePadding>
        <PaymentButton onClick={() => selectPath(`membership`)} size="small">
          <MoneyIcon sx={{paddingRight: '10px'}}/>
          <div>Membership</div>
        </PaymentButton>
      </ListItem>
      {/* <ListItem disablePadding> */}
      {/*  <LearnButton onClick={() => selectPath(`learn`)} size="small"> */}
      {/*    <BookIcon sx={{paddingRight: '10px'}}/> */}
      {/*    <div>Learn</div> */}
      {/*  </LearnButton> */}
      {/* </ListItem> */}
      {/* <ListItem disablePadding> */}
      {/*  <ChatGPTButton onClick={() => selectPath(`chat-gpt`)} size="small"> */}
      {/*    <ForumIcon sx={{paddingRight: '10px'}}/> */}
      {/*    <div>ChatGPT</div> */}
      {/*  </ChatGPTButton> */}
      {/* </ListItem> */}
      <ListItem disablePadding>
        <HelpButton onClick={() => selectPath(`help`)} size="small">
          <HelpIcon sx={{paddingRight: '10px'}}/>
          <div>Help</div>
        </HelpButton>
      </ListItem>
      <ListItem disablePadding>
        <ProfileButton onClick={() => selectPath(`profile`)} size="small">
          <ProfileIcon sx={{paddingRight: '10px'}}/>
          <div>Profile</div>
        </ProfileButton>
      </ListItem>
      <ListItem disablePadding>
        <LogoutButton onClick={onLogout} size="small">
          <Logout sx={{paddingRight: '10px'}}/>
          <div>Logout</div>
        </LogoutButton>
      </ListItem>
    </List>
  )
}