// DUCKS pattern
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {Bot, CreateBotInput, Message} from "features/bot/types";
import type { RootState } from 'store/store'

import {AccountListItem} from "../../accounts/types";
import {botSlice} from "../../bot/store";
import {FeedChartData, FeedChartResponse} from "../types";


export interface DashboardState {
  feedChartData: FeedChartData
  proposalChartData: FeedChartData
  connectChartData: FeedChartData
  messages: Message[]
  messageCount: number
  loading: boolean
  error: unknown | undefined
}

const initialState: DashboardState = {
  feedChartData: {},
  proposalChartData: {},
  connectChartData: {},
  messages: [],
  messageCount: 0,
  loading: false,
  error: undefined,
}

// slice
export const dashboard = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    getFeedChartSuccess(state, action: PayloadAction<FeedChartResponse>) {
      state.feedChartData = action.payload.feed
      state.proposalChartData = action.payload.proposal
      state.connectChartData = action.payload.connect
      state.loading = false
      state.error = undefined
    },
  },
})

// Actions
export const dashboardActions = {
  getFeedChartSuccess: dashboard.actions.getFeedChartSuccess,
  getFeedChart: createAction(`${dashboard.name}/getFeedChart`, () => ({
    payload: {}
  })),
}

// Selectors
export const selectDashboard = (state: RootState) => state.dashboard

// Reducer
export default dashboard.reducer
